<div class="moduleItemMenu">
    <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadOrders()" label="{{ 'base.refresh' | translate }}"></button>
    <button *ngIf="_auth.modules['sales'].authLevel >= 10  && orderType == 'N' && createRights"
            class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createOrder()" label="{{ 'base.create' | translate }}"></button>
</div>
<p-table #dt [value]="_orders" [rows]="_rows" [paginator]="true" [pageLinks]="5"
         [sortField]="'offerDate'" [sortOrder]="-1"
         [(contextMenuSelection)]="selectedOrder" [contextMenu]="cm" [loading]="_loading">
    <ng-template pTemplate="header">
        <tr>
            <th *ngIf="displayCustomer" [pSortableColumn]="'customer.name'">{{ 'addressbook.company' | translate }}
                <p-sortIcon [field]="'customer.name'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'champrixRef'">{{ 'sales.champrixRef' | translate }}
                <p-sortIcon [field]="'champrixRef'"></p-sortIcon>
            </th>
            <th *ngIf="1 == 2">{{ 'sales.version' | translate }}</th>
            <th [pSortableColumn]="'offerDate'">{{ 'sales.offerDate' | translate }}
                <p-sortIcon [field]="'offerDate'"></p-sortIcon>
            </th>
            <th>{{ 'sales.validTo' | translate }}</th>
            <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th *ngIf="displayCustomer"><input pInputText type="text" (input)="dt.filter($event.target.value, 'description', 'contains')"></th>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'champrixRef', 'contains')"></th>
            <th *ngIf="1 == 2"></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order>
        <tr [pContextMenuRow]="order" (dblclick)="onRowDblclickOrder($event, order)">
            <td *ngIf="displayCustomer">{{ order.company.name }}</td>
            <td>{{ order.champrixRef }}</td>
            <td *ngIf="1 == 2">{{ order.version }}</td>
            <td>
                <djc-date [timeInput]="order.offerDate" [format]="_auth.getUserSettings().dateFormat"></djc-date>
            </td>
            <td>
                <djc-date [timeInput]="order.validTo" [format]="_auth.getUserSettings().dateFormat"></djc-date>
            </td>
            <td>{{ order.modifier.fullName }}</td>
            <td>
                <djc-date [timeInput]="order.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="_contextMenu" appendTo="body"></p-contextMenu>
<div *ngIf="_showDetails">
    <order [_visible]="_showDetails" [selectedOrder]="selectedOrder"
           [displayCustomer]="displayCustomer" [orderType]="orderType" [selectedCompany]="selectedCompany" [newOrder]="_newOrder"
           (visibleChange)="cancel()" (orderChange)="loadOrders()" [type]="'orderDetails'"></order>
</div>
<div *ngIf="_showDialog">
    <order [_visible]="_showDialog" [selectedOrder]="selectedOrder"
           [displayCustomer]="displayCustomer" [orderType]="orderType" [selectedCompany]="selectedCompany" [newOrder]="_newOrder"
           (visibleChange)="cancel()" (orderChange)="loadOrders()" [type]="'orderDialog'"></order>
</div>
<div *ngIf="_showHistory">
    <p-dialog header="{{ 'sales.history' | translate}} {{ selectedOrder.champrixRef }}" [visible]="_showHistory" appendTo="body"
              [styleClass]="'ui-lg-4 ui-md-6 ui-g-8 pDialog'" (visibleChange)="cancelHistory()" [contentStyle]="{'height':'70% !important'}">
        <div class="moduleItemMenu">
            <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="showHistory()" label="{{ 'base.refresh' | translate }}"></button>
        </div>
        <p-table #oht [value]="_orderHistory" [rows]="_rows" [paginator]="true" [pageLinks]="5">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ 'sales.oldStatus' | translate }}</th>
                    <th>{{ 'sales.newStatus' | translate }}</th>
                    <th>{{ 'base.modified' | translate }}</th>
                    <th>{{ 'base.modifier' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-history>
                <tr>
                    <td>{{ 'sales.orderType_' + history.oldStatus | translate }}</td>
                    <td>{{ 'sales.orderType_' + history.newStatus | translate }}</td>
                    <td style="text-align: right">
                        <djc-date [timeInput]="history.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                    </td>
                    <td>{{ history.creator.fullName }}</td>
                </tr>
            </ng-template>
        </p-table>
    </p-dialog>
</div>
<div *ngIf="_showCopy">
    <p-dialog header="{{ 'sales.copy' | translate }}" [visible]="_showCopy" appendTo="body"
              [styleClass]="'ui-lg-4 ui-md-6 ui-g-8 pDialog'" (visibleChange)="cancelHistory()" [contentStyle]="{'height':'70% !important'}">

    </p-dialog>
</div>
