import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "../api.service";
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";
import {BroadcastService, MsalService} from "@azure/msal-angular";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    private _login: loginModel = new loginModel();
    private _forgotMailAddress: string;
    public _loginForm: boolean = true;
    public _userNameForm: boolean = false;
    private _mouseClicks: number = 0;

    constructor(private translate: TranslateService, private _api: ApiService, private _authService: AuthService,
                private _router: Router, private _msalService: MsalService, private _broadCast: BroadcastService) {
        this._authService.loginPending = true;
    }

    ngOnInit() {
        this._loginForm = false;
        this.loginSSO();
    }

    countMouseClick() {
        this._mouseClicks++;
        if (this._mouseClicks === 3) {
            this._loginForm = true;
        }
    }

    login() {
        this._api.setActionUrl('base/authentication');
        this._api.specialPostAction(JSON.stringify(this._login), 'login').subscribe(
            response => {
                if (response.success) {
                    if (response.readTranslations) {
                        this.translate.use(response.userSettings.language);
                    }
                    this._authService.doLogin(response);
                    this._authService.loginPending = false;
                    this._router.navigate(['/']);
                } else {
                    this._authService.addError(response.errorMsg);
                }
            }, error => {
                this._authService.addError(error.error.text);
            }
        );
    }


    loginSSO() {
        const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

        if (isIE) {
            this._msalService.loginRedirect({
                extraScopesToConsent: ["user.read", "openid", "profile"]
            });
        } else {
            this._msalService.loginPopup({
                extraScopesToConsent: ["user.read", "openid", "profile"]
            }).then(response => {
                this._api.setActionUrl('base/authentication');
                this._api.specialPostAction(JSON.stringify({userName: response.account.userName }), 'loginsso').subscribe(response => {
                    if (response.success) {
                        if (response.readTranslations) {
                            this.translate.use(response.userSettings.language);
                        }
                        this._authService.doLogin(response);
                        this._authService.loginPending = false;
                        this._router.navigate(['/']);
                    } else {
                        // this._loginForm = true;
                        this._authService.addError(response.errorMsg);
                    }
                }, error => {
                    this._authService.addError(error.error.text);
                });

            }, cancelled => {
                console.info('PopupCancelled Login');
                // this._loginForm = true;
            });
        }

    }

    forgotPassword() {
        if (this._login.userName === '') {
            // set alert with warning
        }
    }

    forgotUserName() {
        this._loginForm = false;
        // this._userNameForm = true;
    }
    userName() {
        this._api.setActionUrl('base/authentication');
        this._api.specialPostAction(JSON.stringify({mailAddress: this._forgotMailAddress}), 'forgotUserName').subscribe(
            response => {
                console.info(response.sendParams);
            }, error => {
                this._authService.addError(error.error.text);
            }
        );
        this._userNameForm = false;
        this._loginForm = true;
    }

}

export class loginModel {
    userName: string;
    password: string
}
