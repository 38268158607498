import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MenuItem} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../auth.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SupportTools} from "../support.tools";
import {DatePipe} from "@angular/common";
import {ApiService, responseModel} from "../api.service";
import {Currency} from "./finance.model";
import {Freight} from "../logistics/logistics.models";

@Component({
    selector: 'financeCurrencies',
    templateUrl: './currencies.component.html',
    styleUrls: ['./finance.component.css']
})
export class CurrenciesComponent implements OnInit {

    private _reload: boolean;

    @Input() set reload(value: boolean) {
        this._reload = value;
        if (this._reload) {
            this.loadCurrencies();
        }
    }
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get reload(): boolean {
        return this._reload;
    }

    @Input() set newItem(value: boolean) {
        this._newCurrency = value;
        if (this._newCurrency) {
            this.selectedCurrency = new Currency();
            this._currencyForm.reset();
            this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('finance.currencyType'));
            this._showDialog = true;
        }
    }
    @Output() newItemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get newItem(): boolean {
        return this._newCurrency;
    }

    public _loading: boolean;

    public _contextMenu: MenuItem[] = [];
    public _showDetails: boolean = false;
    public _showDialog: boolean = false;
    private _recordTitle: string;
    private _newCurrency: boolean = false;

    private _currencyForm: FormGroup;

    public _currencies: Currency[] = [];
    public selectedCurrency: Currency;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {
    }


    ngOnInit() {
        this._transService.get(['base.edit', 'base.delete', 'base.details']).subscribe(
            response => {
                this._contextMenu.push({label: response['base.details'], icon: 'fa fa-info-circle', command: (event) => this.detailsCurrency()});
                if (this._auth.modules['finance'].authLevel >= 20) {
                    this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editCurrency()});
                }
                if (this._auth.modules['finance'].authLevel >= 40) {
                    //this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteCurrency()});
                }
            }
        );
        this._currencyForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'type': new FormControl('', Validators.required),
            'notation': new FormControl('', Validators.required)
        });
        this.loadCurrencies();
    }

    loadCurrencies() {
        this._loading = true;
        this._apiService.setActionUrl('finance/currencies');
        this._apiService.setSort({field: 'type', direction: 'ASC'});
        this._apiService.getStore().subscribe(
            response => {
                this._loading = false;
                this._reload = false;
                this.reloadChange.emit(this._reload);
                if (response.success) {
                    this._currencies = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    detailsCurrency() {
        this._showDetails = true;
        this._recordTitle = this.selectedCurrency.type;
    }

    editCurrency() {
        this._showDialog = true;
        this._recordTitle = this.selectedCurrency.type;
        this._currencyForm.setValue(this.selectedCurrency as any);
    }

    cancel() {
        this.selectedCurrency = null;
        this._showDialog = false;
        this._showDetails = false;
        if (this._newCurrency) {
            this._newCurrency = false;
            this.newItemChange.emit(this._newCurrency);
        }
    }

    saveCurrency() {
        this.selectedCurrency = this._currencyForm.getRawValue();
        this._apiService.setActionUrl('finance/currencies');
        if (this._newCurrency) {
            this._apiService.createRecord(JSON.stringify(this.selectedCurrency)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedCurrency)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    private _afterSave(response: responseModel) {
        if (response.success) {
            this.cancel();
            this.selectedCurrency = response.data.records[0];
            this.detailsCurrency();
            this.loadCurrencies();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    onRowDblclick(event, currency) {
        this.selectedCurrency = currency;
        this.detailsCurrency();
    }

}
