import {Country, NameModel} from "../base/base.model";
import {User} from "../users/users.models";

export class Freight {
    id: string;
    oldId: number;
    countryId: string;
    country: Country;
    type: string;
    typeDescription: string;
    forwarderId: string;
    forwarder: NameModel;
    shippingLineId: string
    shippingLine: NameModel;
    departure: string;
    destination: string;
    containerSizeId: string;
    containerSize: NameModel;
    validUntil: number;
    price: number;
    activeUntil: number;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}

export class FreightCost {
    id: string;
    freightId: string;
    freight: Freight;
    time: number;
    sea: number;
    land: number;
    CAF: number;
    BAF: number;
    LSS: number;
    customs: number;
    THC: number;
    ISPS: number;
    IMO: number;
    validFrom: number;
    validFromDate: Date;
    validUntil: number;
    validUntilDate: Date;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}