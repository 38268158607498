import {HttpHeaders} from "@angular/common/http";
import {Injectable, Component, Input, NgModule, ElementRef, forwardRef} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormControl, FormGroup, ValidatorFn, ValidationErrors, FormsModule, Validators, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TableModule} from "primeng/table";
import {TranslateModule} from "@ngx-translate/core";
import {RadioButtonModule} from 'primeng/radiobutton';
import {Spinner} from "primeng/spinner";
import {isArray} from "rxjs/util/isArray";

export const contentHeaders = new HttpHeaders();
contentHeaders.append('Accept', 'application/json');
contentHeaders.append('Content-Type', 'application/json');

@Injectable()
export class SupportTools {
    public cloneRecord(oldRecord, newRecord) {
        for (let prop in oldRecord) {
            newRecord[prop] = oldRecord[prop];
        }
        return newRecord;
    }

    public ucFirst(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    public generateRandomString(length: number): string {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*()";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    public equalValueValidator(targetKey: string, toMatchKey: string): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            const target = group.controls[targetKey];
            const toMatch = group.controls[toMatchKey];
            if (target.touched && toMatch.touched) {
                const isMatch = target.value === toMatch.value;
                // set equal value error on dirty controls
                if (!isMatch && target.valid && toMatch.valid) {
                    toMatch.setErrors({equalValue: targetKey});
                    const message = targetKey + ' != ' + toMatchKey;
                    return {'equalValue': message};
                }
                if (isMatch && toMatch.hasError('equalValue')) {
                    toMatch.setErrors(null);
                }
            }

            return null;
        };
    }

    public hundredPercentValidator(fields: any): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            let total: number = 0;
            fields.forEach(field => {
                const calcField = group.controls[field];
                calcField.setErrors(null);
                total = total + calcField.value;
            });
            if (total != 100) {
                fields.forEach(field => {
                    const calcField = group.controls[field];
                    calcField.setErrors({hundredPercent: total});
                })
                return {'hundredPercent': total};
            }
            return null;
        }
    }

    public checkPassword(field: FormControl) {
        let passwordPattern = new RegExp("^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$");
        if (field.value == null || field.value.length === 0 || field.value.length >= 8 || passwordPattern.test(field.value)) {
            return null;
        } else {
            return {
                checkPassword: {
                    valid: false
                }
            }
        }
    }

    public secondMailfieldValidator(field: FormControl) {
        if (field.value == null || field.value === '') {
            return null;
        }
        return Validators.email(field);
    }

    public getFormValidationErrors(form: FormGroup) {
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    async sleep(milliSeconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliSeconds));
    }

    public getDateColor(date: number, red: number, orange: number = 0): string {
        let secondsRed = red * (24 * 60 * 60);
        let color = 'black';
        let nowSeconds = Math.floor(new Date().getTime() / 1000);
        if (orange > 0) {
            let secondsOrange = orange * (24 * 60 * 60);
            if (date + secondsOrange < nowSeconds) {
                color = 'orange';
            }
        }
        if (date + secondsRed < nowSeconds) {
            color = 'red';
        }
        return color;
    }

    public priceFormat(price: string) {
        let first = price.substr(0, 1);
        let last = price.substr(1);
        return first + ' ' + last;
    }

    public toDataUrl(url, callback, scope) {
        var canvas = <HTMLCanvasElement>document.createElement('CANVAS'),
            ctx = canvas.getContext('2d'),
            img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var dataURL;
            canvas.height = img.height;
            canvas.width = img.width;
            ctx.drawImage(img, 0, 0);
            dataURL = canvas.toDataURL();
            callback(scope, dataURL);
            canvas = null;
        };
        img.src = url;
    }

    public getDateValue() {
        let curDate = new Date();
        curDate.setUTCHours(0, 0, 0, 0);
        return curDate.getTime() / 1000;
    }

    public stringFormat(formatted: any, options: any[]) {
        if (!isArray(options)) {
            return formatted;
        }
        for (let i = 0; i < options.length; i++) {
            let regexp = new RegExp('\\{' + i + '\\}', 'gi');
            formatted = formatted.replace(regexp, options[i]);
        }
        return formatted;
    }
}

@Component({
    selector: 'djc-date',
    template: '{{ (timeInput * 1000) | date: format}}'
})
export class DateTemplate {
    @Input() timeInput: number = 0;

    @Input() format: string = 'dd-MM';

    constructor() {

    }

}

export const DJC_SPINNER_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DjcSpinner),
    multi: true
};

@Component({
    selector: 'djc-spinner',
    template: `
        <span class="ui-spinner ui-widget ui-corner-all">
            <input #inputfield type="ẗext" [attr.id]="inputId" [value]="formattedValue||null" [attr.name]="name"
                   [attr.size]="size" [attr.maxlength]="maxlength" [attr.tabindex]="tabindex" [attr.placeholder]="placeholder" [disabled]="disabled" [attr.readonly]="readonly"
                   [attr.required]="required"
                   (keydown)="onInputKeydown($event)" (keyup)="onInputKeyup($event)" (blur)="onInputBlur($event)" (change)="onInputChange($event)"
                   (focus)="onInputFocus($event)"
                   [ngStyle]="inputStyle" [class]="inputStyleClass" [ngClass]="'ui-spinner-input ui-inputtext ui-widget ui-state-default ui-corner-all'">
            <button type="button" [ngClass]="{'ui-spinner-button ui-spinner-up ui-corner-tr ui-button ui-widget ui-state-default':true,'ui-state-disabled':disabled}" [disabled]="disabled"
                    [attr.readonly]="readonly"
                    (mouseleave)="onUpButtonMouseleave($event)" (mousedown)="onUpButtonMousedown($event)" (mouseup)="onUpButtonMouseup($event)">
                <span class="ui-spinner-button-icon fa fa-caret-up ui-clickable"></span>
            </button>
            <button type="button" [ngClass]="{'ui-spinner-button ui-spinner-down ui-corner-br ui-button ui-widget ui-state-default':true,'ui-state-disabled':disabled}" [disabled]="disabled"
                    [attr.readonly]="readonly"
                    (mouseleave)="onDownButtonMouseleave($event)" (mousedown)="onDownButtonMousedown($event)" (mouseup)="onDownButtonMouseup($event)">
                <span class="ui-spinner-button-icon fa fa-caret-down ui-clickable"></span>
            </button>
        </span>
    `,
    host: {
        '[class.ui-inputwrapper-filled]': 'filled',
        '[class.ui-inputwrapper-focus]': 'focus'
    },
    providers: [DJC_SPINNER_VALUE_ACCESSOR]
})
export class DjcSpinner extends Spinner {

    onInputKeyup(event: KeyboardEvent) {
        let inputValue = (<HTMLInputElement>event.target).value;
        if (event.key !== this.decimalSeparator && event.key !== this.thousandSeparator && event.key !== this.negativeSeparator) {
            this.value = this.parseValue(inputValue);
            // this.formatValue();
        }

        this.onModelChange(this.value);
        this.updateFilledState();
    }

    onInputBlur(event) {
        this.focus = false;
        this.formatValue();
        this.onModelTouched();
        this.onBlur.emit(event);
    }


}

@NgModule({
    declarations: [
        DateTemplate,
        DjcSpinner
    ],
    imports: [
        TranslateModule,
        RadioButtonModule,
        FormsModule,
        CommonModule,
        TableModule
    ],
    exports: [
        DateTemplate,
        DjcSpinner
    ],
    providers: [
        SupportTools,
        DjcSpinner
    ]
})
export class ToolsModule {
}
