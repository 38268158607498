import {ModuleWithProviders, Type} from "@angular/core";
import {RouterModule} from "@angular/router";
import {LoginComponent} from "./app/login/login.component";
import {UsersComponent} from "./app/users/users.component";
import {AuthService} from "./app/auth.service";
import {ModulesComponent} from "./app/modules/modules.component";
import {GroupsComponent} from "./app/groups/groups.component";
import {BaseComponent} from "./app/base/base.component";
import {AddressbookComponent} from "./app/addressbook/addressbook.component";
import {ProductsModuleComponent} from "./app/products/products.component";
import {LogisticsComponent} from "./app/logistics/logistics.component";
import {FinanceComponent} from "./app/finance/finance.component";

export enum MenuType {
    LEFT,
    RIGHT,
    BRAND,
    INVISIBLE
}

interface RouteInfoMetaData {
    path: string;
    component: Type<any>;
    title: string;
    canActivate: any;
    acl: boolean;
    menuType: MenuType;
}

export declare class RouteInfo implements RouteInfoMetaData {
    path: string;
    component: Type<any>;
    title: string;
    canActivate: any;
    menuType: MenuType;
    acl: boolean;

    constructor({path, component, title}?: {
        path?: string;
        component?: Type<any>;
        title?: string;
        canActivate?: any;
        menuType?: MenuType;
        acl?: boolean;
    });
}

export const ROUTES: RouteInfo[] = [
    {path: '', component: BaseComponent, title: 'base.startpage', menuType: MenuType.LEFT, acl: false, canActivate: [AuthService]},
    {path: 'groups', component: GroupsComponent, title: 'groups.name', menuType: MenuType.LEFT, acl: true, canActivate: [AuthService]},
    {path: 'login', component: LoginComponent, title: 'login.login', menuType: MenuType.INVISIBLE, acl: true, canActivate: [AuthService]},
    {path: 'modules', component: ModulesComponent, title: 'modules.name', menuType: MenuType.LEFT, acl: true, canActivate: [AuthService]},
    {path: 'users', component: UsersComponent, title: 'users.name', menuType: MenuType.LEFT, acl: true, canActivate: [AuthService]},
    {path: 'addressbook', component: AddressbookComponent, title: 'addressbooks.name', menuType: MenuType.LEFT, acl: true, canActivate: [AuthService]},
    {path: 'products', component: ProductsModuleComponent, title: 'products.name', menuType: MenuType.LEFT, acl: true, canActivate: [AuthService]},
    {path: 'logistics', component: LogisticsComponent, title: 'logistics.name', menuType: MenuType.LEFT, acl: true, canActivate: [AuthService]},
    {path: 'finance', component: FinanceComponent, title: 'finance.name', menuType: MenuType.LEFT, acl: true, canActivate: [AuthService]}
];

export const APP_ROUTER_PROVIDERS: ModuleWithProviders<RouterModule> = RouterModule.forRoot(ROUTES, {
    enableTracing: false
});
