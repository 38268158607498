import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {Order, OrderProduct} from "./sales.models";
import {FormBuilder} from "@angular/forms";
import {AuthService} from "../auth.service";
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {ApiService} from "../api.service";
import {TranslateService} from "@ngx-translate/core";
import {SupportTools} from "../support.tools";
import {SalesService} from "./sales.service";
// @ts-ignore
import pdfMake from "pdfmake/build/pdfmake";
// @ts-ignore
import pdfFonts from "pdfmake/build/vfs_fonts";
import {TCreatedPdf} from "pdfmake/build/pdfmake";

//import * as html2Canvas from 'html2canvas/dist/html2canvas';

@Component({
    selector: 'isc',
    templateUrl: './isc.component.html',
    styleUrls: ['./sales.component.css']
})
export class IscComponent implements OnInit {

    private _faSquareO: string;
    private _faCheckSquareO: string;

    private PAGE_WIDTH: number = 832;

    @ViewChild('iscContent', {static: true}) content: ElementRef;

    @Input() selectedOrder: Order;
    @Input() _orderLines: OrderProduct[];
    @Input() _totalRecord: any;
    @Input() _visible: boolean;

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe, private _salesService: SalesService, private _cp: CurrencyPipe, private _dec: DecimalPipe) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    ngOnInit() {
        this._salesService.setOrder(this.selectedOrder);

        this._st.toDataUrl('/assets/images/fa-square-o.png', function (scope, dataURL) {
            scope._faSquareO = dataURL;
        }, this);
        this._st.toDataUrl('/assets/images/fa-check-square-o.png', function (scope, dataURL) {
            scope._faCheckSquareO = dataURL;
        }, this);
    }


    exportPDF() {
        let pdf = this.createPDF();
        pdf.download('ISC_' + this.selectedOrder.champrixRef + '.pdf');
    }

    createPDF() {
        let faCheckImage = {image: this._faCheckSquareO};
        let faUncheckImage = {image: this._faSquareO};
        let headerLeft = 7 / 12 * this.PAGE_WIDTH;
        let headerRight = 5 / 12 * this.PAGE_WIDTH;
        let headerBody = {
            table: {
                widths: [headerLeft, headerRight],
                body: [
                    [
                        [
                            {
                                table: {
                                    widths: ['15%', '25%', '15%', '45%'],
                                    body: [
                                        [{}, {
                                            text: this._transService.instant('base.general'),
                                            bold: true
                                        }, {}, {text: this._transService.instant('addressbook.C'), bold: true}],
                                        ['Order #', {
                                            text: this.selectedOrder.champrixRef + ' / ' + this.selectedOrder.orderVersion,
                                            fillColor: '#f0e68c'
                                        }, this._transService.instant('addressbook.C'), {
                                            text: this.selectedOrder.company.name + ' / ' + this.selectedOrder.company.debtorCode,
                                            fillColor: '#f0e68c'
                                        }],
                                        [this._transService.instant('sales.salesman'), {
                                            text: this.selectedOrder.user.fullName,
                                            fillColor: '#f0e68c'
                                        }, this._transService.instant('base.country'), {
                                            text: this.selectedOrder.country.country,
                                            fillColor: '#f0e68c'
                                        }],
                                        [this._transService.instant('base.status'), {
                                            text: this._transService.instant('sales.orderType_' + this.selectedOrder.status),
                                            fillColor: '#f0e68c'
                                        }, this._transService.instant('addressbook.city'), {
                                            text: this.selectedOrder.company.city,
                                            fillColor: '#f0e68c'
                                        }],
                                        [this._transService.instant('sales.offerDate'), {
                                            text: this._dp.transform(this.selectedOrder.offerDate * 1000, this._auth.getUserSettings().dateFormat),
                                            fillColor: '#f0e68c'
                                        }, this._transService.instant('addressbook.zipCode'), {
                                            text: this.selectedOrder.company.zipCode,
                                            fillColor: '#f0e68c'
                                        }],
                                        [this._transService.instant('sales.validTo'), {
                                            text: this._dp.transform(this.selectedOrder.validTo * 1000, this._auth.getUserSettings().dateFormat),
                                            fillColor: '#f0e68c'
                                        }, this._transService.instant('addressbook.street'), {
                                            text: this.selectedOrder.company.street,
                                            fillColor: '#f0e68c'
                                        }]
                                    ]
                                },
                                layout: 'noBorders',
                            },
                            {
                                table: {
                                    widths: ['15%', '10%', '15%', '15%', '15%', '30%'],
                                    body: [
                                        [{}, {
                                            text: this._transService.instant('sales.conditions'),
                                            bold: true
                                        }, {}, {}, {}, {
                                            text: this._transService.instant('sales.totals'),
                                            bold: true,
                                            alignment: 'right'
                                        }],
                                        [this._transService.instant('base.strDescription'), {
                                            text: this.selectedOrder.paymentMod.description,
                                            colSpan: 3,
                                            fillColor: '#f0eb8c'
                                        }, {}, {}, this._transService.instant('sales.costPrice'), {
                                            text: this._st.priceFormat(this._cp.transform(this._totalRecord.costPrice, this.selectedOrder.currency.notation, 'symbol', '1.2-2', 'nl')),
                                            fillColor: '#e9967a',
                                            alignment: 'right'
                                        }],
                                        [this._transService.instant('sales.incoTerms'), {
                                            text: this._transService.instant('logistics.inco_' + this.selectedOrder.incoTerms),
                                            colSpan: 3,
                                            fillColor: '#f0eb8c',
                                            alignment: 'right'
                                        }, {}, {}, this._transService.instant('sales.salesPrice'), {
                                            text: this._st.priceFormat(this._cp.transform(this._totalRecord.salesPrice, this.selectedOrder.currency.notation, 'symbol', '1.2-2', 'nl')),
                                            fillColor: '#e9967a',
                                            alignment: 'right'
                                        }],
                                        [this._transService.instant('addressbook.creditInsured'), this.selectedOrder.company.creditInsured ? faCheckImage : faUncheckImage,
                                            this._transService.instant('sales.commission') + ' %',
                                            {
                                                text: this._dec.transform(this.selectedOrder.commissionPercentage, '1.2-2', 'nl') + ' %',
                                                fillColor: '#f0eb8c',
                                                alignment: 'right'
                                            },
                                            this._transService.instant('sales.margin'),
                                            {
                                                text: this._st.priceFormat(this._cp.transform(this._totalRecord.salesPrice - this._totalRecord.costPrice, this.selectedOrder.currency.notation, 'symbol', '1.2-2', 'nl')),
                                                fillColor: '#e9967a',
                                                alignment: 'right'
                                            }],
                                        [this._transService.instant('finance.interestRate'),
                                            {
                                                text: this._dec.transform(this.selectedOrder.paymentMod.interestRate, '1.2-2', 'nl') + ' %',
                                                fillColor: '#f0eb8c',
                                                alignment: 'right'
                                            },
                                            this._transService.instant('sales.commission'),
                                            {
                                                text: this._st.priceFormat(this._cp.transform(this.selectedOrder.commissionSolid, 'EUR', 'symbol', '1.2-2', 'nl')),
                                                fillColor: '#f0eb8c',
                                                alignment: 'right'
                                            },
                                            this._transService.instant('sales.margin'),
                                            {
                                                text: this._dec.transform(((this._totalRecord.salesPrice - this._totalRecord.costPrice) / this._totalRecord.salesPrice) * 100, '1.2-2', 'nl') + ' %',
                                                fillColor: '#e9967a',
                                                alignment: 'right'
                                            }],
                                        [this._transService.instant('finance.freightInsurance'),
                                            {
                                                text: this._dec.transform(this.selectedOrder.paymentMod.freightInsurrance, '1.2-2', 'nl') + ' %',
                                                fillColor: '#f0eb8c',
                                                alignment: 'right'
                                            },
                                            this._transService.instant('sales.calcContainerVolume'),
                                            {
                                                text: this.selectedOrder.calcContainerVolume,
                                                fillColor: '#f0eb8c',
                                                alignment: 'right'
                                            },
                                            this._transService.instant('sales.quantity'),
                                            {
                                                text: this._totalRecord.quantity + ' Mtons',
                                                fillColor: '#e9967a',
                                                alignment: 'right'
                                            }],
                                        [{}, {}, this._transService.instant('sales.numberOfContainers'),
                                            {
                                                text: this._totalRecord.containers,
                                                fillColor: '#f0eb8c',
                                                alignment: 'right'
                                            },
                                            this._transService.instant('sales.purchasePrice'),
                                            {
                                                text: this._st.priceFormat(this._cp.transform(this._totalRecord.price, 'EUR', 'symbol', '1.2-2', 'nl')),
                                                fillColor: '#e9967a',
                                                alignment: 'right'
                                            }
                                        ]
                                    ]
                                },
                                layout: 'noBorders'
                            }
                        ]
                        ,
                        [
                            {
                                table: {
                                    widths: ['20%', '50%', '15%', '15%'],
                                    body: [
                                        [{}, {
                                            text: this._transService.instant('sales.shippingInformation'),
                                            bold: true
                                        }, {}, {text: this._transService.instant('finance.currencyType'), bold: true}],
                                        [
                                            this._transService.instant('logistics.departure'),
                                            {
                                                text: this.selectedOrder.freight.departure,
                                                fillColor: '#f0eb8c'
                                            },
                                            this._transService.instant('base.type'),
                                            {
                                                text: this.selectedOrder.currency.type,
                                                fillColor: '#b0c4de'
                                            }],
                                        [
                                            this._transService.instant('logistics.destination'),
                                            {
                                                text: this.selectedOrder.freight.destination,
                                                fillColor: '#f0eb8c'
                                            },
                                            this._transService.instant('finance.rate'),
                                            {
                                                text: this._dec.transform(this.selectedOrder.rate.rate, '1.3-3', 'nl'),
                                                fillColor: '#b0c4de'
                                            }],
                                        [
                                            this._transService.instant('logistics.containerSize'),
                                            {
                                                text: this.selectedOrder.containerSize.name,
                                                fillColor: '#f0eb8c'
                                            }, {}, {}],
                                        [
                                            this._transService.instant('logistics.sea'),
                                            {
                                                text: this.selectedOrder.freightCost.sea,
                                                fillColor: '#f0eb8c'
                                            }, {}, {}],
                                        [
                                            this._transService.instant('base.validFrom'),
                                            {
                                                text: this._dp.transform(this.selectedOrder.freightCost.validFrom * 1000, this._auth.getUserSettings().dateFormat),
                                                fillColor: '#f0eb8c'
                                            }, {}, {}],
                                        [
                                            {text: this._transService.instant('base.comment'), bold: true}, {},
                                            {
                                                text: this._transService.instant('sales.instructions'),
                                                bold: true
                                            }, {}
                                        ],
                                        [
                                            {text: this.selectedOrder.comment, colSpan: 2, fillColor: '#dde3e6'}, {},
                                            {
                                                colSpan: 2,
                                                stack: [
                                                    {
                                                        table: {
                                                            widths: ['100%'],
                                                            body: [
                                                                [
                                                                    {
                                                                        text: this.selectedOrder.bookDeparture ? this._transService.instant('sales.bookDeparture') : '',
                                                                        fillColor: '#f0eb8c'
                                                                    }
                                                                ], [
                                                                    {
                                                                        text: this.selectedOrder.purchase ? this._transService.instant('sales.purchase') : '',
                                                                        fillColor: '#f0eb8c'
                                                                    }
                                                                ], [
                                                                    {
                                                                        text: this.selectedOrder.reserveProductionCapacity ? this._transService.instant('sales.reserveProductionCapacity') : '',
                                                                        fillColor: '#f0eb8c'
                                                                    }
                                                                ], [
                                                                    {
                                                                        text: this.selectedOrder.sendConfirmation ? this._transService.instant('sales.sendConfirmation') : '',
                                                                        fillColor: '#f0eb8c'
                                                                    }
                                                                ]
                                                            ]
                                                        },
                                                        layout: 'noBorders',
                                                    }
                                                ]
                                            }, {}
                                        ]
                                    ]
                                },
                                layout: 'noBorders'
                            }
                        ]
                    ]
                ]
            },
            fontSize: 7,
            layout: 'noBorders'
        };

        let body = [];
        let bodyWidths = ['6%', '6%', '16%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%'];
        body.push([
            {text: this._transService.instant('sales.quantity'), alignment: 'right', bold: true, fillColor: '#dde3e6'},
            {text: this._transService.instant('products.code'), alignment: 'right', bold: true, fillColor: '#dde3e6'},
            {
                text: this._transService.instant('base.strDescription'),
                alignment: 'right',
                bold: true,
                fillColor: '#dde3e6'
            },
            {
                text: this._transService.instant('base.purchasePrice'),
                alignment: 'right',
                bold: true,
                fillColor: '#dde3e6'
            },
            {text: this._transService.instant('base.validUntil'), alignment: 'right', bold: true, fillColor: '#dde3e6'},
            {text: this._transService.instant('sales.costPrice'), alignment: 'right', bold: true, fillColor: '#dde3e6'},
            {
                text: this._transService.instant('sales.additionalCost'),
                alignment: 'right',
                bold: true,
                fillColor: '#dde3e6'
            },
            {text: 'in €', alignment: 'right', bold: true, fillColor: '#dde3e6'},
            {
                text: this._transService.instant('sales.salesPrice'),
                alignment: 'right',
                bold: true,
                fillColor: '#dde3e6'
            },
            {
                text: this._transService.instant('sales.margin') + ' %',
                alignment: 'right',
                bold: true,
                fillColor: '#dde3e6'
            },
            {text: this._transService.instant('sales.margin'), alignment: 'right', bold: true, fillColor: '#dde3e6'},
            {
                text: 'Σ ' + this._transService.instant('sales.costPrice'),
                alignment: 'right',
                bold: true,
                fillColor: '#dde3e6'
            },
            {
                text: 'Σ ' + this._transService.instant('sales.salesPrice'),
                alignment: 'right',
                bold: true,
                fillColor: '#dde3e6'
            },
            {
                text: 'Σ ' + this._transService.instant('sales.margin'),
                alignment: 'right',
                bold: true,
                fillColor: '#dde3e6'
            },
            {text: '#', alignment: 'right', bold: true, fillColor: '#dde3e6'}
        ]);
        this._orderLines.forEach(function (orderLine: OrderProduct) {
            body.push([
                {
                    text: this._dec.transform(orderLine.quantity, '1.3-3', 'nl') || '',
                    fillColor: '#a4c0f4'
                },
                {
                    text: orderLine.product.code || '',
                    fillColor: '#a4c0f4'
                },
                {
                    text: orderLine.product.description || '',
                    fillColor: '#a4c0f4'
                },
                {
                    text: this._st.priceFormat(this._cp.transform(orderLine.price.price, 'EUR', 'symbol', '1.2-2', 'nl')) || '',
                    alignment: 'right'
                },
                {
                    text: this._dp.transform(orderLine.price.validUntil * 1000, this._auth.getUserSettings().dateFormat) || '',
                    alignment: 'right'
                },
                {
                    text: this._st.priceFormat(this._cp.transform(this._salesService.getCostPrice(orderLine, this._totalRecord), this.selectedOrder.currency.notation, 'symbol', '1.2-2', 'nl')) || '',
                    alignment: 'right'
                }, {}, {},
                {
                    text: this._st.priceFormat(this._cp.transform(orderLine.salesPrice, this.selectedOrder.currency.notation, 'symbol', '1.2-2', 'nl')) || '',
                    fillColor: '#a4c0f4',
                    alignment: 'right'
                },
                {
                    text: this._dec.transform(this._salesService.getMarginPercentage(orderLine, this._totalRecord) * 100, '1.2-2', 'nl') + ' %' || '',
                    alignment: 'right'
                },
                {
                    text: this._st.priceFormat(this._cp.transform(this._salesService.getMargin(orderLine, this._totalRecord), this.selectedOrder.currency.notation, 'symbol', '1.2-2', 'nl')) || '',
                    alignment: 'right'
                },
                {
                    text: this._st.priceFormat(this._cp.transform(orderLine.quantity * this._salesService.getCostPrice(orderLine, this._totalRecord), this.selectedOrder.currency.notation, 'symbol', '1.2-2', 'nl')) || '',
                    alignment: 'right'
                },
                {
                    text: this._st.priceFormat(this._cp.transform(orderLine.quantity * orderLine.salesPrice, this.selectedOrder.currency.notation, 'symbol', '1.2-2', 'nl')) || '',
                    alignment: 'right'
                },
                {
                    text: this._st.priceFormat(this._cp.transform(orderLine.quantity * this._salesService.getMargin(orderLine, this._totalRecord), this.selectedOrder.currency.notation, 'symbol', '1.2-2', 'nl')) || '',
                    alignment: 'right'
                },
                orderLine.lineNumber
            ]);
            body.push([
                {
                    text: this._transService.instant('base.strDescription'),
                    colSpan: 2
                }, {},
                {
                    text: orderLine.packaging.description || '',
                    colSpan: 2,
                    fillColor: '#f0eb8c'
                }, {},
                {
                    text: orderLine.newProduct ? this._transService.instant('sales.newProduct') : orderLine.productChange ? this._transService.instant('sales.productChange') : '',
                    colSpan: 2,
                    bold: true,
                    fillColor: (orderLine.newProduct || orderLine.productChange) ? '#7cfc00' : '#f0eb8c'
                }, {},
                this._transService.instant('products.package') + ' ' + this._transService.instant('base.price'),
                {
                    text: this._st.priceFormat(this._cp.transform(orderLine.packaging.price, 'EUR', 'symbol', '1.2-2', 'nl')) || '',
                    fillColor: '#f0eb8c',
                    alignment: 'right'
                },
                {
                    text: this._transService.instant('addressbook.S'),
                    colSpan: 2
                }, {},
                {
                    text: orderLine.supplier ? orderLine.supplier.name : 'Geen leverancier',
                    colSpan: 2,
                    fillColor: '#f0eb8c'
                }, {},
                this._transService.instant('base.comment'),
                {
                    text: 'IMO',
                    fillColor: orderLine.product.IMO ? 'red' : '#f0eb8c'
                }, {
                    text: this._transService.instant('products.EHF'),
                    fillColor: orderLine.product.EHF ? '#ff8c00' : '#f0eb8c'
                }
            ]);
            body.push([
                {
                    text: this._transService.instant('products.packageRemark'),
                    colSpan: 2
                }, {},
                {
                    text: orderLine.packageChange ? orderLine.packageChangeDescription : '',
                    colSpan: 2,
                    fillColor: '#f0eb8c'
                }, {},
                {
                    text: orderLine.productChange ? orderLine.productChangeDescription : '',
                    colSpan: 2,
                    fillColor: orderLine.productChange ? '#7cfc00' : '#f0eb8c'
                }, {},
                this._transService.instant('sales.additionalCost'),
                {
                    text: this._st.priceFormat(this._cp.transform(orderLine.additionalCost, 'EUR', 'symbol', '1.2-2', 'nl')) || '',
                    fillColor: '#f0eb8c',
                    alignment: 'right'
                },
                {
                    text: this._transService.instant('products.supplierCode'),
                    colSpan: 2
                }, {},
                {
                    text: orderLine.price.supplierCode || '',
                    colSpan: 2,
                    fillColor: '#f0eb8c'
                }, {},
                {
                    text: orderLine.price.comment || '',
                    colSpan: 3,
                    rowSpan: 2
                }, {}, {}
            ]);
            body.push([
                {
                    text: this._transService.instant('products.labelRemark'),
                    colSpan: 2
                }, {},
                {
                    text: orderLine.labelChange ? orderLine.labelChangeDescription : '',
                    colSpan: 2,
                    fillColor: '#f0eb8c'
                }, {},
                {
                    text: orderLine.registeredProduct ? this._transService.instant('sales.registeredProduct') : '',
                    colSpan: 2,
                    fillColor: '#f0eb8c'
                }, {},
                this._transService.instant('sales.freightCost'),
                {
                    text: this._st.priceFormat(this._cp.transform(this._salesService.getFreightCost(this._totalRecord), 'EUR', 'symbol', '1.2-2', 'nl')) || '',
                    fillColor: '#f0eb8c',
                    alignment: 'right'
                },
                {
                    text: this._transService.instant('products.minimumQuantity'),
                    colSpan: 2
                }, {},
                {
                    text: orderLine.price.minimumQuantity || '',
                    fillColor: '#f0eb8c'
                },
                'Mtons', {}, {}, {}
            ]);
        }, this);

        var docDefinition = {
            content: [{
                stack: [headerBody],
                margins: [0, 0, 25, 0]
            }, {
                table: {
                    widths: bodyWidths,
                    body: body,
                    headerRows: 1
                },
                //layout: 'noBorders',
                fontSize: 7
            }],
            pageOrientation: 'landscape',
            // margins [left, top, right, bottom]
            pageMargins: [15, 15, 15, 15],
            footer: function (currentPage, pageCount) {
                return {
                    text: currentPage.toString() + ' van ' + pageCount,
                    alignment: 'right',
                    margin: [0, 0, 15, 15],
                    fontSize: 8
                };
            }
        };

        return pdfMake.createPdf(docDefinition as any);

    }

    sendPDF(mailTo: string) {
        let pdf: TCreatedPdf = this.createPDF();
        let me = this;
        pdf.getBase64(function (doc) {
            me._apiService.setActionUrl('sales/mail');
            me._apiService.specialPostAction(JSON.stringify({
                pdfBase64: doc,
                orderNr: me.selectedOrder.champrixRef,
                mailTo: mailTo
            }), 'send').subscribe(
                response => {
                    if (response.success) {
                        me._auth.addInfo('Mail is verzonden');
                    } else {
                        me._auth.addError(response.errorMsg);
                    }
                }
            );
        });
    }

    cancel() {
        this._visible = false;
        this.visibleChange.emit(false);
    }

    getBoolClass(checked: boolean) {
        return checked ? 'iscTrue' : 'iscFalse';
    }

    getEHFClass(checked: boolean) {
        return checked ? 'iscEHF' : 'iscBackground';
    }

    getNewProductClass(checked: boolean) {
        return checked ? 'iscNewProduct' : 'iscBackground';
    }
}
