import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NameModel} from "../base/base.model";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MenuItem} from "primeng/api";
import {DatePipe} from "@angular/common";
import {ApiService, responseModel} from "../api.service";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";

@Component({
    selector: 'logisticsItems',
    templateUrl: './items.component.html',
    styleUrls: ['./logistics.component.css']
})
export class ItemsComponent implements OnInit {

    private _reload: boolean;

    private _baseUrl: string;

    @Input() set reload(value: boolean) {
        this._reload = value;
        if (this._reload) {
            this.loadItems();
        }
    }
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get reload(): boolean {
        return this._reload;
    }

    @Input() set newItem(value: boolean) {
        this._newItem = value;
        if (this._newItem) {
            this.selectedItem = new NameModel();
            this._itemForm.reset();
            this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('logistics.shippingLine'));
            this._showDialog = true;
        }
    }
    @Output() newItemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get newItem(): boolean {
        return this._newItem;
    }

    @Input() url: string;

    public _contextMenu: MenuItem[] = [];
    public _loading: boolean = false;
    public _showDetails: boolean = false;
    public _showDialog: boolean = false;
    private _recordTitle: string;
    private _newItem: boolean = false;

    private _itemForm: FormGroup;

    public _items: NameModel[];
    public selectedItem: NameModel;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {
    }

    ngOnInit() {
        this._transService.get(['base.edit', 'base.delete', 'base.details']).subscribe(
            response => {
                this._contextMenu.push({label: response['base.details'], icon: 'fa fa-info-circle', command: (event) => this.detailsItem()});
                if (this._auth.modules['logistics'].authLevel >= 20) {
                    this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editItem()});
                }
                if (this._auth.modules['logistics'].authLevel >= 40) {
                    this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteItem()});
                }
            }
        );

        this._itemForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'name': new FormControl('', Validators.required),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });

        this.loadItems();

    }

    loadItems() {
        this._loading = true;
        this._apiService.setActionUrl(this.url);
        this._apiService.setSort({field: 'name', direction: 'ASC'});
        this._apiService.getStore().subscribe(
            response => {
                this._loading = false;
                this._reload = false;
                this.reloadChange.emit(this._reload);
                if (response.success) {
                    this._items = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    detailsItem() {
        this._showDetails = true;
        this._recordTitle = this.selectedItem.name;
    }

    editItem() {
        this._itemForm.setValue(this.selectedItem);
        this._showDialog = true;
        this._recordTitle = this.selectedItem.name;
    }

    deleteItem() {
        this._apiService.setActionUrl(this.url);
        this._apiService.deleteRecord(this.selectedItem.id).subscribe(
            response => {
                if (response.success) {
                    this.loadItems();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                console.info(error);
                this._auth.addError(error.error.text);
            }
        );
    }

    saveItem() {
        this.selectedItem = this._itemForm.getRawValue();
        this._apiService.setActionUrl(this.url);
        if (this._newItem) {
            this._apiService.createRecord(JSON.stringify(this.selectedItem)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedItem)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    cancel() {
        this._showDialog = false;
        this._showDetails = false;
        this.selectedItem = null;
        if (this._newItem) {
            this._newItem = false;
            this.newItemChange.emit(this._newItem);
        }
        this._itemForm.reset();
    }

    private _afterSave(response: responseModel) {
        this.cancel();
        this.selectedItem = response.data.records[0];
        this.detailsItem();
        this.loadItems();
    }

    onRowDblclick(event, item) {
        this.selectedItem = item;
        this.detailsItem();
    }
}
