import {NgModule} from "@angular/core";
import {SalesComponent} from "./sales.component";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule, CurrencyPipe} from "@angular/common";
import {ToolsModule} from "../support.tools";
import {CardModule} from "primeng/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SalesService} from "./sales.service";
import {IscComponent} from "./isc.component";
import {DropdownModule} from "primeng/dropdown";
import {ContextMenuModule} from "primeng/contextmenu";
import {SpinnerModule} from "primeng/spinner";
import {CalendarModule} from "primeng/calendar";
import {CheckboxModule} from "primeng/checkbox";
import {InputTextareaModule} from "primeng/inputtextarea";
import {InputTextModule} from "primeng/inputtext";
import {OrderComponent} from "./order.component";

@NgModule({
    imports: [
        SharedModule,
        TableModule,
        ButtonModule,
        ContextMenuModule,
        DropdownModule,
        SpinnerModule,
        DialogModule,
        CalendarModule,
        CheckboxModule,
        TranslateModule,
        CommonModule,
        ToolsModule,
        CardModule,
        InputTextareaModule,
        InputTextModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        SalesComponent,
        IscComponent,
        OrderComponent
    ],
    exports: [
        SalesComponent,
        IscComponent,
        OrderComponent
    ],
    providers: [
        CurrencyPipe,
        SalesService
    ]
})
export class SalesModule {}
