import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MenuItem, SelectItem, SortMeta} from "primeng/api";
import {Addressbook, Company} from "./addressbook.model";
import {ApiService} from "../api.service";
import {SupportTools} from "../support.tools";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AclService} from "../acl.service";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../auth.service";
import {Group} from "../groups/groups.models";
import {Order, OrderProductHistory} from "../sales/sales.models";

@Component({
    selector: 'company',
    templateUrl: './company.component.html',
    styleUrls: ['./addressbook.component.css']
})
export class CompanyComponent implements OnInit {
    private _reload: boolean = false;
    public loading: boolean = false;
    public _companies: Company[];
    private _countriesDropDown: SelectItem[] = [];
    private _addressBooksDropDown: SelectItem[] = [];
    private _typeDropDown: SelectItem[] = [];
    private _paymentModDropDown: SelectItem[] = [];
    private _newCompany: boolean = false;
    private _phantomCompany: Company;
    private _recordTitle: string;
    private _companyForm: FormGroup;
    private _financeForm: FormGroup;
    public _contextMenu: MenuItem[] = [];

    private _reloadN: boolean = false;
    private _reloadQ: boolean = false;
    private _reloadO: boolean = false;
    private _reloadP: boolean = false;
    private _reloadL: boolean = false;
    private _create: boolean = false;

    public _showCompanyTable: boolean = true;
    public _showCompanyDetails: boolean = false;
    public _showCompanyDialog: boolean = false;

    public _showProductHistory: boolean = false;
    private _historyLoading: boolean = false;
    private _productHistory: OrderProductHistory[] = [];
    private _orderHistory: Order[] = [];
    private _historyTitle: string = '';
    private _multiSortMeta: SortMeta[];

    public selectedAddressBookDialog: Addressbook;
    public selectedCompany: Company;

    @Input() selectedAddressBook: Addressbook;

    @Input() set reload(value: boolean) {
        this._reload = value;
        if (this._reload) {
            this.loadCompanies();
        }
    }
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get reload(): boolean {
        return this._reload;
    }

    @Input() set createNew(value: boolean) {
        this._create = value;
        if (this._create) {
            this.createCompany();
        }
    }
    @Output() createNewChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get createNew(): boolean {
        return this._create;
    }

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() set companySelected(company: Company) {
        this.selectedCompany = company;
        this._showCompanyTable = false;
        this._showCompanyDetails = true;
    }

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _aclService: AclService) { }


    ngOnInit() {
        this.loadCountries();
        this.loadPaymentMods();
        this.loadAddressBooks();
        this._typeDropDown.push({label: this._transService.instant('base.useSelectValue'), value: ''});
        this._typeDropDown.push({label: this._transService.instant('addressbook.C'), value: 'C'});
        this._typeDropDown.push({label: this._transService.instant('addressbook.S'), value: 'S'});
        this._companyForm = this._fb.group( {
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'name': new FormControl('', Validators.required),
            'prefPaymentModalityId': new FormControl({value: '', disabled: true}),
            'prefPaymentMod': new FormControl({value: '', disabled: true}),
            'addressBookId': new FormControl('', Validators.required),
            'addressbook': new FormControl({value: '', disabled: true}),
            'creditInsured': new FormControl(''),
            'type': new FormControl('', Validators.required),
            'typeDescription': new FormControl({value: '', disabled: true}),
            'street': new FormControl(''),
            'zipCode': new FormControl(''),
            'city': new FormControl(''),
            'countryId': new FormControl('', Validators.required),
            'country': new FormControl({value: '', disabled: true}),
            'debtorCode': new FormControl(''),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });
        this._financeForm = this._fb.group({
            'creditInsured': new FormControl(''),
            'prefPaymentModalityId': new FormControl('', Validators.required),
            'debtorCode': new FormControl(''),
        });

    }

    loadAddressBooks() {
        this._apiService.setActionUrl('addressbook/addressbooks');
        this._apiService.getDropDown(JSON.stringify(['name'])).subscribe(
            response => {
                if (response.success) {
                    this._addressBooksDropDown = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        )
    }

    loadCompanies() {
        this._contextMenu = [];
        if (this.selectedAddressBook.aclLevel >= 10) {
            this._contextMenu.push({label: this._transService.instant('base.details'), icon: 'fa fa-info-circle', command: (event) => this.detailsCompany()});
            this._contextMenu.push({label: this._transService.instant('base.history'), icon: 'fa fa-history', command: (event) => this.showProductHistory()});
        }
        if (this.selectedAddressBook.aclLevel >= 20) {
            this._contextMenu.push({label: this._transService.instant('base.edit'), icon: 'fa fa-pencil-square-o', command: (event) => this.editCompany()});
        }
        if (this.selectedAddressBook.aclLevel >= 40) {
            this._contextMenu.push({label: this._transService.instant('base.delete'), icon: 'fa fa-minus-square-o', command: (event) => this.deleteCompany()});
        }
        this.loading = true;
        this._showCompanyTable = true;
        this._apiService.setActionUrl('addressbook/companies');
        this._apiService.setFilter({field: 'addressBookId', operator: 'eq', value: this.selectedAddressBook.id});
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    this.loading = false;
                    this._reload = false;
                    this.reloadChange.emit(this._reload);
                    this._companies = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        )
    }

    detailsCompany() {
        this._showCompanyDetails = true;
        this._recordTitle = this.selectedCompany.name;
    }

    cancelCompany() {
        this._showCompanyDetails = false;
        this._showCompanyDialog = false;
        this.selectedCompany = null;
        this._phantomCompany = null;
        this.visibleChange.emit(false);
    }

    loadCountries() {
        this._apiService.setActionUrl('base/countries');
        this._apiService.setSort({field: 'country', direction: 'ASC'});
        this._apiService.getDropDown(JSON.stringify(['country'])).subscribe(
            response => {
                if (response.success) {
                    this._countriesDropDown = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        )
    }

    loadPaymentMods() {
        this._apiService.setActionUrl('finance/paymentmodalities');
        this._apiService.getDropDown(JSON.stringify(['description'])).subscribe(
            response => {
                if (response.success) {
                    this._paymentModDropDown = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        )
    }

    editCompany() {
        this._showCompanyDialog = true;
        this._recordTitle = this.selectedCompany.name;
        let importCompany = this.selectedCompany;
        this._companyForm.setValue(importCompany);
        this._financeForm.setValue({
            'creditInsured': this.selectedCompany.creditInsured,
            'prefPaymentModalityId': this.selectedCompany.prefPaymentModalityId,
            'debtorCode': this.selectedCompany.debtorCode
        });
    }

    createCompany() {
        this._showCompanyDialog = true;
        this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('addressbook.company'));
        this._newCompany = true;
        this.selectedCompany = new Company();
        this._companyForm.reset();
        this._financeForm.reset();
        this._create = false;
        this.createNewChange.emit(this._create);
    }

    selectCompany(event) {
        this.selectedCompany = this._st.cloneRecord(event.data, new Group());
    }

    saveCompany() {
        this._apiService.setActionUrl('addressbook/companies');
        this.selectedCompany = this._companyForm.getRawValue();
        this.selectedCompany.prefPaymentModalityId = this._financeForm.controls.prefPaymentModalityId.value;
        this.selectedCompany.creditInsured = this._financeForm.controls.creditInsured.value;
        this.selectedCompany.debtorCode = this._financeForm.controls.debtorCode.value;
        if (this._newCompany) {
            this._apiService.createRecord(JSON.stringify(this.selectedCompany)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            )
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedCompany)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            )
        }
    }

    private _afterSave(response) {
        if (response.success) {
            this.selectedCompany = response.data.records[0];
            this._phantomCompany = null;
            this._showCompanyDialog = false;
            this._newCompany = false;
            this.detailsCompany();
            this.loadCompanies();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    deleteCompany() {
        this._apiService.setActionUrl('addressbook/companies');
        this._apiService.deleteRecord(this.selectedCompany.id).subscribe(
            response => {
                if (response.success) {
                    this.selectedCompany = null;
                    this._phantomCompany = null;
                    this.loadCompanies();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        )
    }

    onRowDblclick($event, company) {
        this.selectedCompany = company;
        this.detailsCompany();
    }

    changedTabView(event) {
        switch (event.index) {
            case 0:
                this._reloadN = true;
                break;
            case 1:
                this._reloadQ = true;
                break;
            case 2:
                this._reloadP = true;
                break;
            case 3:
                this._reloadO = true;
                break;
            case 4:
                this._reloadL = true;
                break;
            default:
                console.info('No ordered tab');
                break;
        }
    }

    showProductHistory() {
        console.info('ProductHistory');
        this._apiService.setActionUrl('sales/orders');
        this._historyTitle = this._transService.instant('base.history') + ' ' + this.selectedCompany.name;
        this._historyLoading = true;
        this._showProductHistory = true;
        this._apiService.setFilter({field: 'companyId', operator: 'eq', value: this.selectedCompany.id});
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    this._orderHistory = response.data.records;
                    let orderIds = [];
                    this._orderHistory.forEach(record => {
                        orderIds.push(record.id);
                    })
                    this._apiService.setActionUrl('sales/orderproducts');
                    let params = {
                        listFields: JSON.stringify(['id', 'orderId', 'salesPrice', 'quantity', 'product', 'supplier']),
                        orderIds: orderIds
                    };
                    this._apiService.specialPostAction(JSON.stringify(params), 'productHistory').subscribe(
                        response => {
                            this._historyLoading = false;
                            this._productHistory = response.data.records;
                            this._productHistory.forEach(function (product) {
                                product.order = this._orderHistory.find(order => order.id == product.orderId);
                                if (product.supplier) {
                                    product.extraString = product.product.description + ' - ' + product.supplier.name;
                                } else {
                                    product.extraString = product.product.description + ' - Geen leverancier';
                                }
                            }, this)
                        }
                    )
                }
            }
        );

        this._multiSortMeta = [];
        this._multiSortMeta.push({
            field: 'order.offerDate',
            order: -1
        });
        this._multiSortMeta.push({
            field: 'order.champrixRef',
            order: 1
        });

    }

    cancelHistory() {
        this._showProductHistory = false;
        this._productHistory = [];
    }

}
