import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApiService, responseModel} from "../api.service";
import {DatePipe} from "@angular/common";
import {AuthService} from "../auth.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PaymentModality} from "./finance.model";
import {MenuItem} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {SupportTools} from "../support.tools";

@Component({
    selector: 'financePaymentMod',
    templateUrl: './paymentmod.component.html',
    styleUrls: ['./finance.component.css']
})
export class PaymentmodComponent implements OnInit {
    private _reload: boolean;

    @Input() set reload(value: boolean) {
        this._reload = value;
        if (this._reload) {
            this.loadPaymentMods();
        }
    }
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get reload(): boolean {
        return this._reload;
    }

    @Input() set newItem(value: boolean) {
        this._newPaymentMod = value;
        if (this._newPaymentMod) {
            this.selectedPaymentMod = new PaymentModality();
            this._paymentModForm.reset();
            this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('finance.currencyType'));
            this._showDialog = true;
        }
    }
    @Output() newItemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get newItem(): boolean {
        return this._newPaymentMod;
    }

    public _loading: boolean;

    public _contextMenu: MenuItem[] = [];
    public _showDetails: boolean = false;
    public _showDialog: boolean = false;
    private _recordTitle: string;
    private _newPaymentMod: boolean = false;

    private _paymentModForm: FormGroup;

    public _paymentMods: PaymentModality[] = [];
    public selectedPaymentMod: PaymentModality;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {
    }


    ngOnInit() {
        this._transService.get(['base.edit', 'base.delete', 'base.details']).subscribe(
            response => {
                this._contextMenu.push({label: response['base.details'], icon: 'fa fa-info-circle', command: (event) => this.detailsPaymentMod()});
                if (this._auth.modules['finance'].authLevel >= 20) {
                    this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editPaymentMod()});
                }
                if (this._auth.modules['finance'].authLevel >= 40) {
                    this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deletePaymentMod()});
                }
            }
        );

        this._paymentModForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'description': new FormControl('', Validators.required),
            'percentageOne': new FormControl('', Validators.required),
            'DoCOne': new FormControl('', Validators.required),
            'percentageTwo': new FormControl('', Validators.required),
            'DoCTwo': new FormControl('', Validators.required),
            'percentageThree': new FormControl('', Validators.required),
            'DoCThree': new FormControl('', Validators.required),
            'interestRate': new FormControl('', Validators.required),
            'freightInsurrance': new FormControl('', Validators.required),
            'LCCost': new FormControl('', Validators.required),
            'administrationCost': new FormControl('', Validators.required),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        }, {validator: this._st.hundredPercentValidator(['percentageOne', 'percentageTwo', 'percentageThree'])});

        this.loadPaymentMods();

    }

    loadPaymentMods() {
        this._apiService.setActionUrl('finance/paymentmodalities');
        this._apiService.setSort({field: 'description', direction: 'ASC'});
        this._apiService.getStore().subscribe(
            response => {
                this._loading = false;
                this._reload = false;
                this.reloadChange.emit(this._reload);
                if (response.success) {
                    this._paymentMods = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    detailsPaymentMod() {
        this._showDetails = true;
        this._recordTitle = this.selectedPaymentMod.description;
    }

    editPaymentMod() {
        this._paymentModForm.setValue(this.selectedPaymentMod);
        this._showDialog = true;
    }

    deletePaymentMod() {
        this._apiService.setActionUrl('finance/paymentmodalities');
        this._apiService.deleteRecord(this.selectedPaymentMod.id).subscribe(
            response => {
                if (response.success) {
                    this.loadPaymentMods();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    savePaymentMod() {
        this.selectedPaymentMod = this._paymentModForm.getRawValue();
        this._apiService.setActionUrl('finance/paymentmodalities');
        if (this._newPaymentMod) {
            this._apiService.createRecord(JSON.stringify(this.selectedPaymentMod)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedPaymentMod)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    cancel() {
        this._showDialog = false;
        this._showDetails = false;
        this.selectedPaymentMod = null;
        if (this._newPaymentMod) {
            this._newPaymentMod = false;
            this.newItemChange.emit(this._newPaymentMod);
        }
    }

    private _afterSave(response: responseModel) {
        if (response.success) {
            this.cancel();
            this.loadPaymentMods();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    onRowDblclick(event, mod) {
        this.selectedPaymentMod = mod;
        this.detailsPaymentMod();
    }
}
