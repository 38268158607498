import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {User, UserSettings} from "./users/users.models";
import {Modules} from "./app.models";
import {TranslateService} from "@ngx-translate/core";
import {Message} from "primeng/api";
import {DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";
import {Keepalive} from "@ng-idle/keepalive";
import {responseModel} from "./api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import 'rxjs/add/observable/interval';

@Injectable()
export class AuthService implements CanActivate {

    public authenticated: boolean = false;
    public loginPending: boolean = false;
    public ssoLoginPending: boolean = false;
    public menuBuild: boolean = false;
    public modules: Modules[] = [];
    public defaultRows: number = 25;
    public messages: Message[] = [];
    public timeOutStarted: boolean = false;
    public timeOutCounter: number = 0;
    public highestModObject: any = {};
    private _token: string;
    private _user: User;
    private _userSettings: UserSettings;
    private _headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'});
    private _highestMod: any;
    constructor(private _router: Router, private _translate: TranslateService, private _http: HttpClient,
                public idle: Idle, public keepAlive: Keepalive) {
        // set KeepAlive things, make every 5 minutes a call to the backend to prevent sessionloss before automatic logoff
        keepAlive.onPing.subscribe(() => {
            localStorage.setItem('lastAction', JSON.stringify({lastAction: Date.now()}));
            console.info('KAL ping Called');
        });
        keepAlive.request('/api/base/authentication/keepalive');
        keepAlive.interval(300);
        keepAlive.start();

        this._highestMod = Observable.interval(5000).subscribe(() => {
            Object.keys(this.highestModObject).forEach(function(mod) {
                // @ts-ignore
                this._http.get<responseModel>('api/' + this.highestModObject[mod].url + '/highmod', {observe: 'body', headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        Authorization: 'Bearer ' + this.getToken()
                    })}).subscribe(response => {
                    if (this.highestModObject[mod].modSeq < response.data.highModSeq) {
                        let me = this.highestModObject[mod].functionScope;
                        me[this.highestModObject[mod].function]();
                    }
                });
            }, this);
        });

    }

    addError(message) {
        this.messages.push({severity: 'error', summary: 'Foutmelding', detail: message});
        console.error(message);
    }

    addInfo(message) {
        this.messages.push({severity: 'info', summary: 'Informatie', detail: message});
        console.info(message);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let lastAction = 0;
        if (localStorage.getItem('lastAction')) {
            lastAction = JSON.parse(localStorage.getItem('lastAction')).lastAction;
        }
        let auth = localStorage.getItem('authenticated');
        // last action less then 20 minutes ago
        if (auth == '1' && (Date.now() - lastAction) < 20 * 60 * 1000) {
            this.authenticated = true;
            this.doLogin(JSON.parse(localStorage.getItem('authentication')));
        } else {
            this.authenticated = false;
        }
        if (!this.authenticated) {
            if (route.routeConfig.path === 'login') {
                return true;
            }
            this._router.navigate(['/login']);
        } else {
            let path = route.routeConfig.path;
            if (path === '') {
                return true;
            }
            if (path === 'login') {
                this._router.navigate(['/']);
            }
            if (path in this.modules) {
                return this.modules[path]['authLevel'] > 0;
            } else {
                switch (path) {
                    default:
                        return false;
                }
            }
        }
        return true;
    }

    public isAuthenticated(): boolean {
        return this.authenticated;
    }

    public doLogin(response) {
        localStorage.setItem('authentication', JSON.stringify(response));
        // set IdleThings
        this.idle.onIdleStart.subscribe(() => {
            this.timeOutStarted = true;
        });
        this.idle.onIdleEnd.subscribe(() => {
            this.timeOutStarted = false;
        });
        this.idle.onTimeoutWarning.subscribe((countdown: number) => {
            this.timeOutCounter = countdown;
        });
        let idleTime = parseInt(response.userSettings.expiresSeconds, 10);
        if (idleTime === 0) {
            idleTime = 24 * 60 * 60;    // idle for 24 hours instead of 0 seconds
        }
        this.idle.setIdle(idleTime);
        this.idle.setTimeout(30);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onTimeout.subscribe(() => {
            this.timeOutStarted = false;
            this.doLogout();
        });
        this.idle.watch();

        this._user = response.user;
        this._user.password = '';
        this.modules = response.data.records;
        this._token = response.authToken;
        this._userSettings = response.userSettings;
        this.defaultRows = this._userSettings.defaultRows;
        if (this._token) {
            this.authenticated = true;
            localStorage.setItem('authenticated', '1');
            localStorage.setItem('lastAction', JSON.stringify({lastAction: Date.now()}));
            this.menuBuild = false;
            this._translate.use(this._userSettings.language);
        }
    }

    public doLogout() {
        let body = 0;
        this._http.post<responseModel>('api/base/authentication/logout', JSON.stringify([]), {headers: this._headers})
            .subscribe(
                response => {
                    var data = response;
                    if (data.success) {
                        this.authenticated = false;
                        this._highestMod.unsubscribe();
                        this._token = null;
                        localStorage.removeItem('authentication');
                        localStorage.removeItem('authenticated');
                        this._router.navigate(['/login']);
                    } else {
                        this.addError(data.errorMsg);
                    }
                },
                error => {
                    this.addError(error.error.text);
                }
            );
    }

    public setUser(user: User) {
        this._user = user;
    }

    public getUser(): User {
        return this._user;
    }

    public setUserSettings(userSettings: UserSettings) {
        this._userSettings = userSettings;
    }

    public getUserSettings(): UserSettings {
        return this._userSettings;
    }

    public setModules(modules) {
        this.modules = modules;
    }

    public setMenuBuild() {
        this.menuBuild = true;
    }

    public getMenuBuild(): boolean {
        return this.menuBuild;
    }

    public getToken() {
        return this._token;
    }

}
