<div id="moduleMenu">
    <span *ngIf="selectedSubItem" class="itemName floatLeft">{{ selectedSubItem }}</span>
    <span id="moduleMenuTitle" class="floatRight">{{ 'finance.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button *ngIf="selectedSubItem" class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadItems()" label="{{ 'base.refresh' | translate }}"></button>
        <button *ngIf="_auth.modules['finance'].authLevel >= 20 && selectedSubItem"
                class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createItem()" label="{{ 'base.create' | translate }}"></button>
    </div>
    <div class="ui-g module">
        <div class="ui-g-2 ui-md-2">
            <div *ngIf="_showListBox">
                <p-listbox [options]="_options" [optionLabel]="'label'" (onChange)="selectItem($event)">
                    <p-header>
                        <i class="fa fa-filter"></i>
                        {{ 'finance.types' | translate }}
                    </p-header>
                </p-listbox>
            </div>
        </div>
        <div class="ui-g-10 ui-md-10">
            <div *ngIf="_showCurrencies" style="display: flex">
                <financeCurrencies [(reload)]="_loadCurrencies" [(newItem)]="_newCurrency"></financeCurrencies>
            </div>
            <div *ngIf="_showPaymentModalities" style="display: flex">
                <financePaymentMod [(reload)]="_loadPaymentModalities" [(newItem)]="_newPaymentModality"></financePaymentMod>
            </div>
        </div>
    </div>
</div>
