import {Company} from "../addressbook/addressbook.model";
import {Currency, CurrencyRate, PaymentModality} from "../finance/finance.model";
import {Freight, FreightCost} from "../logistics/logistics.models";
import {User} from "../users/users.models";
import {Package, Product, ProductPrice} from "../products/products.models";
import {Country, NameModel} from "../base/base.model";

export class Order {
    id: string;
    companyId: string;
    company: Company;
    containerSize: NameModel;
    country: Country;
    currency: Currency;
    currencyRateId: string;
    rate: CurrencyRate;
    freightCostId: string;
    freightCost: FreightCost;
    freight: Freight;
    parentOrderId: string;
    orderVersion: number;
    paymentModalityId: string;
    paymentMod: PaymentModality;
    userId: string;
    user: User;
    offerDate: number;
    offerDateDate: Date;
    closeDate: number;
    closeDateDate: Date;
    validTo: number;
    validToDate: Date;
    champrixRef: string;
    salesMan: string;
    status: string;
    statusDescription: string;
    calcContainerVolume: number;
    commissionSolid: number;
    commissionPercentage: number;
    incoTerms: string;
    comment: string;
    calculatedMargin: number;
    bookDeparture: boolean;
    purchase: boolean;
    reserveProductionCapacity: boolean;
    sendConfirmation: boolean;
    newComment: string;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}

export class OrderProduct {
    id: string;
    orderId: string;
    order: Order;
    product: Product;
    supplier: Company;
    packagingId: string;
    packaging: Package;
    priceId: string;
    price: ProductPrice;
    lineNumber: number;
    additionalCost: number;
    quantity: number;
    salesPrice: number;
    newProduct: boolean;
    productChange: boolean;
    productChangeDescription: string;
    packageChange: boolean;
    packageChangeDescription: string;
    labelChange: boolean;
    labelChangeDescription: string;
    registeredProduct: boolean;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}

export class OrderProductHistory extends OrderProduct {
    extraString: string;
}

export class OrderHistory {
    id: string;
    orderId: string;
    order: Order;
    newStatus: string;
    oldStatus: string;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}
