import {Component, OnInit} from "@angular/core";
import {SelectItem} from "primeng/api";
import {SupportTools} from "../support.tools";
import {ApiService, responseModel} from "../api.service";
import {AuthService} from "../auth.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'logistics',
    templateUrl: './logistics.component.html',
    styleUrls: ['./logistics.component.css']
})
export class LogisticsComponent implements OnInit {

    public _showFreightsTable: boolean = false;
    public _showForwardersTable: boolean = false;
    public _showShippingLinesTable: boolean = false;
    public _showContainersTable: boolean = false;
    public _showListBox: boolean = false;
    public _showImport: boolean = false;
    private _showFeedback: boolean = false;
    public _showActive: boolean = true;

    private _loadFreights: boolean = false;
    private _loadForwarders: boolean = false;
    private _loadShippingLines: boolean = false;
    private _loadContainers: boolean = false;
    private _newFreight: boolean = false;
    private _newForwarder: boolean = false;
    private _newShippingLine: boolean = false;
    private _newContainer: boolean = false;

    private _options: any[] = [];
    public _selectedOption: any;

    public selectedSubItem: string;
    public import: any = {validFromDate: new Date(), headerLine: false, file: '', daysValid: 30};
    public feedback: any = {lines: 0, message: ''};

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService) {
    }

    ngOnInit() {
        this._transService.get(['logistics.freight', 'logistics.forwarder', 'logistics.shippingLine', 'logistics.containerSize']).subscribe(
            response => {
                this._options.push({label: response['logistics.freight'], value: 'FR'});
                this._options.push({label: response['logistics.forwarder'], value: 'FO'});
                this._options.push({label: response['logistics.shippingLine'], value: 'SL'});
                this._options.push({label: response['logistics.containerSize'], value: 'CS'});
                this._showListBox = true;
            }
        )
    }

    selectItem(event) {
        this._showFreightsTable = false;
        this._showForwardersTable = false;
        this._showShippingLinesTable = false;
        this._showContainersTable = false;
        this._selectedOption = event.value;
        switch (this._selectedOption) {
            case 'FR':
                this.selectedSubItem = this._transService.instant('logistics.freight');
                this._showFreightsTable = true;
                break;
            case 'FO':
                this.selectedSubItem = this._transService.instant('logistics.forwarder');
                this._showForwardersTable = true;
                break;
            case 'SL':
                this.selectedSubItem = this._transService.instant('logistics.shippingLine');
                this._showShippingLinesTable = true;
                break;
            case 'CS':
                this.selectedSubItem = this._transService.instant('logistics.containerSize');
                this._showContainersTable = true;
                break;
        }
        //this.loadItems();
    }

    showInactive() {
        this._showActive = false;
    }


    showActive() {
        this._showActive = true;
    }

    loadItems() {
        switch (this._selectedOption) {
            case 'FR':
                this._loadFreights = true;
                break;
            case 'FO':
                this._loadForwarders = true;
                break;
            case 'SL':
                this._loadShippingLines = true;
                break;
            case 'CS':
                this._loadContainers = true;
                break;
        }
    }

    createNewItem() {
        switch (this._selectedOption) {
            case 'FR':
                this._newFreight = true;
                break;
            case 'FO':
                this._newForwarder = true;
                break;
            case 'SL':
                this._newShippingLine = true;
                break;
            case 'CS':
                this._newContainer = true;
                break;
        }
    }

    showImport() {
        this._showImport = true;
    }

    exportCSV() {
        this._apiService.setActionUrl('logistics/costs');
        this._apiService.specialPostAction('', 'exportCSV').subscribe(response => {
            if (response.success) {
                var blob = this.b64toBlob(response.data.records[0], 'text/csv');
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob);
                a.download = response.data.fileName;
                document.body.appendChild(a);
                a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
                document.body.removeChild(a);
            } else {
                this._auth.addError(response.errorMsg);
            }
        });
    }

    b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    };

    cancelImport() {
        this.import = {validFromDate: new Date(), headerLine: false, file: ''};
        this.feedback = {lines: 0, message: ''};
        this._showFeedback = false;
        this._showImport = false;
    }

    importCSV(event) {
        this.import.validFromDate.setHours(0, 0,0, 0);
        event.formData.append('validFrom', Math.floor(this.import.validFromDate.getTime() / 1000));
        event.formData.append('headerLine', this.import.headerLine ? 1 : 0);
        event.formData.append('daysValid', this.import.daysValid);
    }

    importReady(event) {
        let response:responseModel = JSON.parse(event.xhr.response);
        if (response.success) {
            this._showFeedback = true;
            this.feedback.lines = response.data.recordCount;
            this.feedback.message = response.errorMsg;
        } else {
            this._auth.addError(response.errorMsg);
        }
    }
}
