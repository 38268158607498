import {Component, Input, OnInit} from "@angular/core";
import {SupportTools} from "../../support.tools";
import {ApiService} from "../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../auth.service";
import {Product, ProductPrice} from "../products.models";
import {MenuItem, SelectItem} from "primeng/api";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {isNull} from "util";

@Component({
    selector: 'productprice',
    templateUrl: './prices.component.html',
    styleUrls: ['../products.component.css']
})
export class ProductPriceComponent implements OnInit {

    @Input() product: Product;

    public _prices: ProductPrice[] = [];
    private _suppliers: SelectItem[];

    public _contextMenu: MenuItem[] = [];
    private _priceForm: FormGroup;
    private _commentForm: FormGroup;

    public _showPriceDetails: boolean = false;
    public _showPriceDialog: boolean = false;
    private _recordTitle: string;
    private _commentTime: string;
    private _newPrice: boolean = false;
    private _dateFormat: string = 'dd-mm-yy';
    private _lastPrice: ProductPrice = new ProductPrice();

    public selectedPrice: ProductPrice;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {}

    ngOnInit() {
        this.loadPrices();
        this.loadSuppliers();
        if (this._auth.modules['products'].authLevel >= 10) {
            this._contextMenu.push({label: this._transService.instant('base.details'), icon: 'fa fa-info-circle', command: (event) => this.detailsPrice()});
        }
        if (this._auth.modules['products'].authLevel >= 20) {
            this._contextMenu.push({label: this._transService.instant('base.edit'), icon: 'fa fa-pencil-square-o', command: (event) => this.editPrice()});
        }
        this._priceForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'productId': new FormControl({value: '', disabled: true}),
            'product': new FormControl({value: '', disabled: true}),
            'companyId': new FormControl('', Validators.required),
            'company': new FormControl({value: '', disabled: true}),
            'price': new FormControl('', Validators.required),
            'minimumQuantity': new FormControl('', Validators.required).setValue(1),
            'comment': new FormControl(''),
            'supplierCode': new FormControl(''),
            'validFrom': new FormControl({value: '', disabled: true}),
            'validFromDate': new FormControl('', Validators.required),
            'validUntil': new FormControl({value: '', disabled: true}),
            'validUntilDate': new FormControl('', Validators.required),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });
        this._commentForm = this._fb.group({
            'comment': new FormControl('')
        });

    }

    loadPrices() {
        this._apiService.setActionUrl('products/prices');
        this._apiService.setFilter({field: 'productId', value: this.product.id, operator: 'eq'});
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    this._prices = response.data.records;
                    let lastPriceTime = 0;
                    this._prices.forEach(function (price) {
                        if (price.validFrom > lastPriceTime) {
                            this._lastPrice = price;
                        }
                    }, this);
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    loadSuppliers() {
        this._apiService.setActionUrl('addressbook/companies');
        this._apiService.setFilter({field: 'type', value: 'S', operator: 'eq'});
        this._apiService.setSort({field: 'name', direction: 'ASC'});
        this._apiService.getDropDown(JSON.stringify(['name'])).subscribe(
            response => {
                if (response.success) {
                    this._suppliers = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    createPrice() {
        this.selectedPrice = new ProductPrice();
        this.selectedPrice.product = this.product;
        this.selectedPrice.validFromDate = new Date();
        this._showPriceDialog = true;
        this._newPrice = true;
        this._recordTitle = this.selectedPrice.product.code + ' - ' + this.selectedPrice.product.description;
        this._priceForm.reset();
        this._priceForm.controls['validFromDate'].setValue(new Date());
        this._priceForm.controls['companyId'].setValue(this._lastPrice.companyId);
        this._priceForm.controls['supplierCode'].setValue(this._lastPrice.supplierCode);
        this._priceForm.controls['minimumQuantity'].setValue(this._lastPrice.minimumQuantity)
        this._commentForm.reset();
        this._commentTime = this._dp.transform(new Date(), this._auth.getUserSettings().dateFormat);

    }

    editPrice() {
        this.selectedPrice.validFromDate = new Date(this.selectedPrice.validFrom * 1000);
        this.selectedPrice.validUntilDate = new Date(this.selectedPrice.validUntil * 1000);
        this._showPriceDialog = true;
        this._recordTitle = this.selectedPrice.product.code + ' - ' + this.selectedPrice.product.description;
        this._priceForm.setValue(this.selectedPrice as any);
        this._commentForm.reset();
        this._commentTime = this._dp.transform(new Date(), this._auth.getUserSettings().dateFormat);

    }

    detailsPrice() {
        this._showPriceDetails = true;
        this._recordTitle = this.selectedPrice.product.code + ' - ' + this.selectedPrice.product.description;
    }

    cancel() {
        this.selectedPrice = null;
        this._showPriceDetails = false;
        this._showPriceDialog = false;
        this._priceForm.reset();
        this._commentForm.reset();
    }

    savePrice() {
        this.selectedPrice = this._priceForm.getRawValue();
        this.selectedPrice.productId = this.product.id;
        this.selectedPrice.validFromDate.setHours(0,0,0,0);
        this.selectedPrice.validUntilDate.setHours(23,59,59,999);
        this.selectedPrice.validFrom = Math.floor(this.selectedPrice.validFromDate.getTime() / 1000);
        this.selectedPrice.validUntil = Math.floor(this.selectedPrice.validUntilDate.getTime() / 1000);

        let newComment:string = this._commentForm.get('comment').value || '';
        if (newComment.length > 0) {
            let oldComment = this.selectedPrice.comment;
            if (isNull(oldComment)) {
                this.selectedPrice.comment = this._auth.getUser().userName + ' ' + this._commentTime + "\n" + newComment + "\n";
            } else {
                this.selectedPrice.comment = this._auth.getUser().userName + ' ' + this._commentTime + "\n" + newComment + "\n" + oldComment;
            }
        }
        this._apiService.setActionUrl('products/prices');

        if (this._newPrice) {
            this._apiService.createRecord(JSON.stringify(this.selectedPrice)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedPrice)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }

    }

    private _afterSave(response) {
        if (response.success) {
            this._newPrice = false;
            this.loadPrices();
            this.selectedPrice = response.data.records[0];
            this._showPriceDialog = false;
            //this.detailsPrice();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    onRowDblclick(event, price) {
        this.selectedPrice = price;
        this.detailsPrice();
    }
}
