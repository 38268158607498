<p-table *ngIf="showTable" [value]="aclService.aclUsers" [rows]="10"
         [paginator]="true" selectionMode="single" [(selection)]="selectedUserAcl" (onRowSelect)="onSelect($event, 'user')">
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'user.fullName'">{{ 'base.userName' | translate }}
                <p-sortIcon [field]="'user.fullName'"></p-sortIcon>
            </th>
            <th>{{ 'base.aclLevel' | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-aclUser>
        <tr [pSelectableRow]="aclUser">
            <td>{{ aclUser.user.fullName}}</td>
            <td>{{ aclUser.aclLevelName }}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="ui-helper-clearfix" style="width:100%">
            <button class="pButton floatLeft" type="button" pButton icon="fa fa-plus"
                    (click)="showDialogToAdd('user')" label="{{ 'base.add' | translate }}"></button>
            <button *ngIf="selectedUserAcl" class="pButton floatLeft" type="button" pButton icon="fa fa-pencil"
                    (click)="showEditDialog('user')" label="{{ 'base.edit' | translate }}"></button>
        </div>
    </ng-template>
</p-table>
<p-table *ngIf="showTable" [value]="aclService.aclGroups" [rows]="10"
         [paginator]="true" selectionMode="single" [(selection)]="selectedGroupAcl" (onRowSelect)="onSelect($event, 'group')">
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'group.name'">{{ 'groups.groupName' | translate }}
                <p-sortIcon [field]="'group.name'"></p-sortIcon>
            </th>
            <th>{{ 'base.aclLevel' | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-aclGroup>
        <tr [pSelectableRow]="aclGroup">
            <td>{{ aclGroup.group.name}}</td>
            <td>{{ aclGroup.aclLevelName }}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="ui-helper-clearfix" style="width:100%">
            <button class="pButton floatLeft" type="button" pButton icon="fa fa-plus"
                    (click)="showDialogToAdd('group')" label="{{ 'base.add' | translate }}"></button>
            <button *ngIf="selectedGroupAcl" class="pButton floatLeft" type="button" pButton icon="fa fa-pencil"
                    (click)="showEditDialog('group')" label="{{ 'base.edit' | translate }}"></button>
        </div>
    </ng-template>
</p-table>
<p-dialog header="{{ 'base.aclUser' | translate }}" [visible]="showAclUser" [ngStyle]="{'width':'700'}"
          [resizable]="true" [responsive]="true" [closable]="false" modal="true" appendTo="body">
    <div class="ui-g">
        <button class="pButton" type="button" pButton icon="fa fa-trash" (click)="delete('user')" label="{{ 'base.delete' | translate }}"></button>
        <button class="pButton" type="button" pButton icon="fa fa-check" (click)="save('user')" label="{{ 'base.save' | translate }}"></button>
        <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()" label="{{ 'base.cancel' | translate }}"></button>
    </div>
    <div class="ui-g">
        <div class="ui-g-4"><label>{{ 'base.strName' | translate }}</label></div>
        <div class="ui-g-8">
            <p-dropdown [options]="_usersService.usersDropDown" [(ngModel)]="aclUser.userId" [style]="{'width':'300px', 'position':'fixed', 'z-index':'901'}"></p-dropdown>
        </div>
    </div>
    <div class="ui-g">
        <div class="ui-g-4"><label>{{ 'base.aclLevel' | translate }}</label></div>
        <div class="ui-g-8">
            <p-dropdown [options]="crudOptions" [(ngModel)]="aclUser.aclLevel" [style]="{'width':'300px', 'position':'fixed', 'z-index':'900'}"></p-dropdown>
        </div>
    </div>
</p-dialog>
<p-dialog header="{{ 'base.aclGroup' | translate }}" [visible]="showAclGroup"  [ngStyle]="{'width':'700'}"
          [resizable]="true" [responsive]="true" [closable]="false" modal="true" appendTo="body">
    <div class="ui-g">
        <button class="pButton" type="button" pButton icon="fa fa-trash" (click)="delete('group')" label="{{ 'base.delete' | translate }}"></button>
        <button class="pButton" type="button" pButton icon="fa fa-check" (click)="save('group')" label="{{ 'base.save' | translate }}"></button>
        <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()" label="{{ 'base.cancel' | translate }}"></button>
    </div>
    <div class="ui-g">
        <div class="ui-g-4"><label>{{ 'groups.strGroup' | translate }}</label></div>
        <div class="ui-g-8">
            <p-dropdown [options]="_groupsService.groupsDropDown" [(ngModel)]="aclGroup.groupId" [style]="{'width':'300px', 'position':'fixed', 'z-index':'901'}"></p-dropdown>
        </div>
    </div>
    <div class="ui-g">
        <div class="ui-g-4"><label>{{ 'base.aclLevel' | translate }}</label></div>
        <div class="ui-g-8">
            <p-dropdown [options]="crudOptions" [(ngModel)]="aclGroup.aclLevel" [style]="{'width':'300px', 'position':'fixed', 'z-index':'900'}"></p-dropdown>
        </div>
    </div>
</p-dialog>
