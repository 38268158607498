import {User} from "../users/users.models";

export class PaymentModality {
    id: string;
    description: string;
    percentageOne: number;
    percentageTwo: number;
    percentageThree: number;
    DoCOne: number;
    DoCTwo: number;
    DoCThree: number;
    interestRate: number;
    freightInsurrance: number;
    LCCost: number;
    administrationCost: number;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}

export class Currency {
    id: string;
    oldId: number;
    type: string;
    notation: string;
}

export class CurrencyRate {
    id: string;
    oldId: number;
    currencyId: string;
    currency: Currency;
    rate: number;
    validFrom: number;
    validFromDate: Date;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}