import {Component, OnInit} from "@angular/core";
import {SupportTools} from "../support.tools";
import {AuthService} from "../auth.service";
import {TranslateService} from "@ngx-translate/core";
import {ApiService, responseModel} from "../api.service";
import {SelectItem} from "primeng/api";

@Component({
    selector: 'products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsModuleComponent implements OnInit {

    public _showProductsTable: boolean = false;
    public _showPackageTable: boolean = false;
    public _showListBox: boolean = false;
    public _showImport: boolean = false;
    private _showFeedback: boolean = false;
    public _showActive: boolean = true;

    private _loadProducts: boolean = false;
    private _loadPackages: boolean = false;
    private _newProduct: boolean = false;
    private _newPackage: boolean = false;

    private _options: SelectItem[] = [];
    private _suppliers: SelectItem[] = [];
    public _selectedOption: string;
    private _optionSelected: string;

    public selectedSubItem: string;
    public import: any = {companyId: '', validFromDate: new Date(), headerLine: false, file: '', daysValid: 30};
    public feedback: any = {lines: 0, message: ''};

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService) {
    }

    ngOnInit() {
        this._transService.get(['products.product', 'products.label', 'products.package']).subscribe(
            response => {
                this._options.push({label: response['products.product'], value: 'PR'});
                this._options.push({label: response['products.package'], value: 'PA'});
                this._showListBox = true;
                this.selectItem({value: 'PR', label: response['products.product']});
                this._optionSelected = 'PR';
            }
        );
        this.loadSuppliers();
        this.showActive();
    }

    selectItem(event) {
        this._showProductsTable = false;
        this._showPackageTable = false;
        this._selectedOption = event.value;
        switch (this._selectedOption) {
            case 'PR':
                this.selectedSubItem = this._transService.instant('products.product');
                this._showProductsTable = true;
                break;
            case 'PA':
                this.selectedSubItem = this._transService.instant('products.package');
                this._showPackageTable = true;
                break;
        }
        this.loadItems();
    }

    loadItems() {
        switch (this._selectedOption) {
            case 'PR':
                this._loadProducts = true;
                break;
            case 'PA':
                this._loadPackages = true;
                break;
        }
    }

    showInactive() {
        this._showActive = false;
    }


    showActive() {
        this._showActive = true;
    }

    createNewItem() {
        switch (this._selectedOption) {
            case 'PR':
                this._newProduct = true;
                break;
            case 'PA':
                this._newPackage = true;
                break;
        }
    }

    showImport() {
        this._showImport = true;
    }

    cancelImport() {
        this.import = {companyId: '', validFromDate: new Date(), headerLine: false, file: ''};
        this.feedback = {lines: 0, message: ''};
        this._showFeedback = false;
        this._showImport = false;
    }

    loadSuppliers() {
        this._apiService.setActionUrl('addressbook/companies');
        this._apiService.setFilter({field: 'type', value: 'S', operator: 'eq'});
        this._apiService.getDropDown(JSON.stringify(['name'])).subscribe(
            response => {
                if (response.success) {
                    this._suppliers = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    importCSV(event) {
        this.import.validFromDate.setHours(0, 0,0, 0);
        event.formData.append('companyId', this.import.companyId);
        event.formData.append('validFrom', Math.floor(this.import.validFromDate.getTime() / 1000));
        event.formData.append('headerLine', this.import.headerLine ? 1 : 0);
        event.formData.append('daysValid', this.import.daysValid);
    }

    importReady(event) {
        let response:responseModel = JSON.parse(event.xhr.response);
        if (response.success) {
            this._showFeedback = true;
            this.feedback.lines = response.data.recordCount;
            this.feedback.message = response.errorMsg;
        } else {
            this._auth.addError(response.errorMsg);
        }
    }
}
