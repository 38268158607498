<p-table #dt [value]="_packages" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
         [sortField]="'description'"
         [scrollable]="true" [scrollHeight]="'flex'" [styleClass]="'table'"
         [(contextMenuSelection)]="selectedPackage" [contextMenu]="cm" [loading]="loading">
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'description'">{{ 'base.strDescription' | translate }}
                <p-sortIcon [field]="'description'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'size'">{{ 'products.size' | translate }}
                <p-sortIcon [field]="'size'"></p-sortIcon>
            </th>
            <th>{{ 'base.price' | translate }}</th>
            <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'description', 'contains')"></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-package>
        <tr [pContextMenuRow]="package" (dblclick)="onRowDblclick($event, package)">
            <td>{{ package.description }}</td>
            <td>{{ package.size }}</td>
            <td class="price">{{ package.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td>{{ package.modifier.fullName }}</td>
            <td>
                <djc-date [timeInput]="package.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="_contextMenu" appendTo="body"></p-contextMenu>
<div *ngIf="_showDetails">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDetails" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade" [styleClass]="'pTabview'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.strDescription' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedPackage.description }}</div>
                        <div class="ui-g-4"><b>{{ 'base.price' | translate }}</b></div>
                        <div class="ui-g-8 priceDetail">{{ selectedPackage.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedPackage.creator.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedPackage.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedPackage.modifier.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedPackage.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showDialog">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade" [styleClass]="'pTabview'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_packageForm" novalidate (ngSubmit)="savePackage()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="description">{{ 'base.strDescription' | translate}}</label></div>
                        <div class="ui-g-8"><input pInputText id="description" formControlName="description"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="price">{{ 'base.price'| translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="price" formControlName="price" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="savePackage()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_packageForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
