<div class="moduleItemMenu">
    <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadContacts()" label="{{ 'base.refresh' | translate }}"></button>
    <button *ngIf="aclLevel >= 20"
            class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createContact()" label="{{ 'base.create' | translate }}"></button>
    <!--button *ngIf="selectedContact"
            class="pButton" type="button" pButton icon="fa fa-info-circle" (click)="detailsContact()" label="{{ 'base.details' | translate }}"></button>
    <button *ngIf="selectedContact && aclLevel >= 20 && !_newContact"
            class="pButton" type="button" pButton icon="fa fa-pencil-square-o" (click)="editContact()" label="{{ 'base.edit' | translate }}"></button>
    <button *ngIf="selectedContact && aclLevel >= 40 && !_newContact"
            class="pButton" type="button" pButton icon="fa fa-minus-square-o" (click)="deleteContact()" label="{{ 'base.delete' | translate }}"></button-->
</div>
<p-table #dt [value]="_contacts" [rows]="10" [paginator]="true" [pageLinks]="5"
         [sortField]="'lastName'" [(contextMenuSelection)]="selectedContact" [contextMenu]="contactcm">
    <ng-template pTemplate="header">
        <tr>
            <th>{{ 'base.strName' | translate }}</th>
            <th>{{ 'addressbook.telephone' | translate }}</th>
            <th>{{ 'addressbook.mobilePhone' | translate }}</th>
            <th>{{ 'addressbook.email' | translate }}</th>
            <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-contact>
        <tr [pContextMenuRow]="contact" (dblclick)="onRowDblclick($event, contact)">
            <td>{{ contact.firstName}} {{ contact.lastName }}</td>
            <td>{{ contact.telephone }}</td>
            <td>{{ contact.mobilePhone }}</td>
            <td>{{ contact.email }}</td>
            <td>{{ contact.modifier.fullName }}</td>
            <td>
                <djc-date [timeInput]="contact.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #contactcm [model]="_contextMenu" appendTo="body"></p-contextMenu>
<div *ngIf="_showContactDetails">
    <p-dialog [header]="_recordTitle" [visible]="_showContactDetails" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancelContact()" appendTo="body">
        <p-tabView effect="fade" [styleClass]="'pTabView'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.strName' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedContact.firstName}} {{ selectedContact.lastName }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'addressbook.telephone' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedContact.telephone}}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'addressbook.mobilePhone' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedContact.mobilePhone}}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'addressbook.email' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedContact.email}}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'addressbook.position' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedContact.position}}</div>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-12">&nbsp;</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-12">{{ selectedContact.telephoneSecond }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-12">&nbsp;</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-12">{{ selectedContact.emailSecond }}</div>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedContact.creator.fullName }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                            <div class="ui-g-8">
                                <djc-date [timeInput]="selectedContact.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                            </div>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedContact.modifier.fullName }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                            <div class="ui-g-8">
                                <djc-date [timeInput]="selectedContact.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{ 'base.comment' | translate }}">
                <div class="ui-g">
                    <textarea pInputTextarea readonly [ngModel]="selectedContact.comment" cols="80" rows="10"></textarea>
                </div>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showContactDialog">
    <p-dialog [header]="_recordTitle" [visible]="_showContactDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancelContact()" appendTo="body">
        <p-tabView effect="fade" [styleClass]="'pTabView'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-g-4">{{ 'addressbook.company' | translate}}</div>
                    <div class="ui-g-8"><b>{{ selectedCompany.name }}</b></div>
                </div>
                <form [formGroup]="_contactForm" novalidate (ngSubmit)="saveContact()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="firstName">{{ 'addressbook.firstName' | translate}}</label></div>
                        <div class="ui-g-8"><input pInputText id="firstName" formControlName="firstName"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="lastName">{{ 'addressbook.lastName' | translate}}</label></div>
                        <div class="ui-g-8"><input pInputText id="lastName" formControlName="lastName"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="telephone">{{ 'addressbook.telephone' | translate}}</label></div>
                        <div class="ui-g-8"><input pInputText id="telephone" formControlName="telephone"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="telephoneSecond">{{ 'addressbook.telephone' | translate}} #2</label></div>
                        <div class="ui-g-8"><input pInputText id="telephoneSecond" formControlName="telephoneSecond"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="mobilePhone">{{ 'addressbook.mobilePhone' | translate}}</label></div>
                        <div class="ui-g-8"><input pInputText id="mobilePhone" formControlName="mobilePhone"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="email">{{ 'addressbook.email' | translate}}</label></div>
                        <div class="ui-g-8"><input pInputText id="email" formControlName="email"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="emailSecond">{{ 'addressbook.email' | translate}} #2</label></div>
                        <div class="ui-g-8"><input pInputText id="emailSecond" formControlName="emailSecond"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="position">{{ 'addressbook.position' | translate}}</label></div>
                        <div class="ui-g-8"><input pInputText id="position" formControlName="position"/></div>
                    </div>
                </form>
            </p-tabPanel>
            <p-tabPanel header="{{ 'base.comment' | translate }}">
                <form [formGroup]="_commentForm" novalidate (ngSubmit)="saveContact()">
                    <div class="ui-g">
                        {{ _auth.getUser().fullName }} {{ _commentTime }}
                    </div>
                    <div class="ui-g">
                        <textarea pInputTextarea rows="10" cols="80" id="comment" formControlName="comment"></textarea>
                    </div>
                    <div class="ui-g">
                        <textarea readonly pInputTextarea id="oldComment" rows="10" cols="80" [ngModel]="selectedContact.comment" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveContact()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_contactForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancelContact()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
