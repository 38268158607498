import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {ProductsModuleComponent} from "./products.component";
import {ProductsComponent} from "./products/products.component";
import {ProductPriceComponent} from "./products/prices.component";
import {PackagesComponents} from "./packages/packages.components";

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        ProductsModuleComponent,
        ProductsComponent,
        ProductPriceComponent,
        PackagesComponents
    ],
    exports: [
        ProductsComponent,
        ProductPriceComponent,
        PackagesComponents
    ]
})
export class ProductsModule {}