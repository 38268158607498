import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApiService} from "../api.service";
import {Company} from "../addressbook/addressbook.model";
import {AuthService} from "../auth.service";
import {Order} from "../sales/sales.models";
import {SupportTools} from "../support.tools";

@Component({
    selector: 'search',
    templateUrl: 'search.component.html',
    styleUrls: ['../sales/sales.component.css']
})
export class SearchComponent implements OnInit {

    public _visible: boolean = false;
    public company: string = '';
    public order: string = '';
    public companies: Company[] = [];
    public orders: Order[] = [];
    public showCompany: boolean = false;
    public showCompanies: boolean = false;
    public showOrder: boolean = false;
    public showOrders: boolean = false;
    public selectedCompany: Company;
    public selectedOrder: Order;

    @Input() get visible() {
        return this._visible;
    }
    set visible(value) {
        this._visible = value;
    }

    @Output() visibleChange:EventEmitter<any> = new EventEmitter();

    constructor(private _apiService: ApiService, private _auth: AuthService, private _st: SupportTools) {
    }

    ngOnInit() {

    }

    closeWindow(event) {
        if (event === false) {
            this.closeSearchWindow();
        }
    }

    closeSearchWindow() {
        this.visibleChange.emit(false);
    }

    startSearch() {
        if (this.company.length > 0 && this.order.length === 0) {
            // search for company
            this._apiService.setActionUrl('addressbook/companies');
            this._apiService.setFilter({field: 'name', value: this.company + '%', operator: 'LIKE'});
            this._apiService.getStore().subscribe(
                response => {
                    if (response.success) {
                        this.companies = response.data.records;
                        if (this.companies.length > 1) {
                            this.showCompanies = true;
                        } else if (this.companies.length === 1) {
                            this.showCompany = true;
                            this.selectedCompany = this.companies[0];
                        } else {
                            alert('Niets gevonden');
                        }
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                }
            )
        } else if (this.company.length === 0 && this.order.length > 0) {
            // search for order at company
            this._apiService.setActionUrl('sales/orders');
            this._apiService.setFilter({field: 'champrixRef', value: this.order + '%', operator: 'LIKE'});
            this._apiService.getStore().subscribe(
                response => {
                    if (response.success) {
                        this.orders = response.data.records;
                        if (this.orders.length > 1) {
                            this.showOrders = true;
                        } else if (this.orders.length === 1) {
                            this.showOrder = true;
                            this.selectedOrder = this.orders[0];
                        } else {
                            alert("Niets gevonden");
                        }
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                }
            )
        } else {
            // search for order
            alert('Niets ingevuld');
        }
    }

    selectCompany(event, company: Company) {
        this.selectedCompany = company;
        this.showCompanies = false;
        this.showCompany = true;
    }

    selectOrder(event, order: Order) {
        this.selectedOrder = order;
        this.showOrders = false;
        this.showOrder = true;
    }

}
