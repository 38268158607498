<p-dialog appendTo="body" [visible]="_visible" header="{{ 'base.search' | translate }}" [closable]="true"
          [styleClass]="'ui-lg-8 ui-md-10 ui-g-12 pDialog'" [modal]="true" [responsive]="true" (visibleChange)="closeSearchWindow()">
    <form>
        <div class="ui-g">
            <div class="padDisplay ui-g-4">{{ 'addressbook.company' | translate }}</div>
            <div class="padDisplay ui-g-8"><input pInputText [(ngModel)]="company" [ngModelOptions]="{standalone: true}" [disabled]="order.length > 0"/></div>
        </div>
        <div class="ui-g">
            <div class="padDisplay ui-g-4">{{ 'sales.champrixRef' | translate }}</div>
            <div class="padDisplay ui-g-8"><input pInputText [(ngModel)]="order" [ngModelOptions]="{standalone: true}" [disabled]="company.length > 0"/></div>
        </div>
        <div class="ui-g">
            <div class="padDisplay ui-g-4"><button pButton type="text" class="pButton floatRight" icon="fa fa-search" [disabled]="order.length === 0 && company.length === 0" [style]="{'height': '100%'}" (click)="startSearch()" label=" {{'base.search' | translate}}"></button></div>
        </div>
    </form>
</p-dialog>
<div *ngIf="showCompany">
    <company [selectedAddressBook]="selectedCompany.addressbook" [companySelected]="selectedCompany" [reload]="false" (visibleChange)="closeWindow($event)"></company>
</div>
<div *ngIf="showCompanies">
    <p-dialog appendTo="body" [visible]="showCompanies" [closable]="true" [modal]="true" [responsive]="true" [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="closeSearchWindow()" [closeIcon]="'fa fa-times'">
        <p-table [value]="companies" [styleClass]="'table'" [scrollable]="true" [scrollHeight]="'flex'" [selection]="selectedCompany">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ 'base.strName' | translate}}</th>
                    <th>{{ 'addressbook.city' | translate }}</th>
                    <th>{{ 'addressbook.addressbook' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-company>
                <tr (dblclick)="selectCompany($event, company)">
                    <td>{{ company.name }}</td>
                    <td>{{ company.city }}</td>
                    <td>{{ company.addressbook.name }}</td>
                </tr>
            </ng-template>
        </p-table>
    </p-dialog>
</div>
<div *ngIf="showOrder">
    <order [selectedOrder]="selectedOrder" [selectedCompany]="selectedOrder.company" [newOrder]="false" [type]="'orderDetails'" (visibleChange)="closeWindow($event)"></order>
</div>
<div *ngIf="showOrders">
    <p-dialog appendTo="body" [visible]="showOrders" [closable]="true" [modal]="true" [responsive]="true" [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="closeSearchWindow()" [closeIcon]="'fa fa-times'">
        <p-table [value]="orders" [styleClass]="'table'" [scrollable]="true" [scrollHeight]="'flex'" [selection]="selectedOrder">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ 'sales.champrixRef' | translate }}</th>
                    <th>{{ 'base.strName' | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr (dblclick)="selectOrder($event, order)">
                    <td>{{ order.champrixRef }}</td>
                    <td>{{ order.company.name }}</td>
                </tr>
            </ng-template>
        </p-table>
    </p-dialog>
</div>
