<p-table #dt [loading]="_loading" [value]="_freights" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
         [scrollable]="true" [scrollHeight]="'flex'" [styleClass]="'table'"
         [sortField]="'oldId'" [sortOrder]="-1" [(contextMenuSelection)]="selectedFreight" [contextMenu]="cm">
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'oldId'">ID
                <p-sortIcon [field]="'oldId'"></p-sortIcon>
            </th>
            <!--th [pSortableColumn]="'forwarder'">{{ 'logistics.forwarder' | translate }}
                <p-sortIcon [field]="'forwarder'"></p-sortIcon>
            </th-->
            <!--th [pSortableColumn]="'shippingLine'">{{ 'logistics.shippingLine' | translate }}
                <p-sortIcon [field]="'shippingLine'"></p-sortIcon>
            </th-->
            <th [pSortableColumn]="'country.country'">{{ 'base.country' | translate }}
                <p-sortIcon [field]="'country.country'"></p-sortIcon>
            </th>
            <th>{{ 'logistics.containerSize' | translate }}</th>
            <th>{{ 'logistics.departure' | translate }}</th>
            <th>{{ 'logistics.destination' | translate }}</th>
            <th>{{ 'base.price' | translate }}</th>
            <th [pSortableColumn]="'validUntil'">{{ 'base.validUntil' | translate }}
                <p-sortIcon [field]="'validUntil'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'oldId', 'contains')"></th>
            <!--th><input pInputText type="text" (input)="dt.filter($event.target.value, 'forwarder.name', 'contains')"></th-->
            <!--th><input pInputText type="text" (input)="dt.filter($event.target.value, 'shippingLine.name', 'contains')"></th-->
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'country.country', 'contains')"></th>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'containerSize.name', 'contains')"></th>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'departure', 'contains')"></th>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'destination', 'contains')"></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-freight>
        <tr [pContextMenuRow]="freight" (dblclick)="onRowDblclick($event, freight)">
            <td>{{ freight.oldId }}</td>
            <!--td>{{ freight.forwarder.name }}</td-->
            <!--td>{{ freight.shippingLine.name }}</td-->
            <td>{{ freight.country.country }}</td>
            <td>{{ freight.containerSize.name }}</td>
            <td>{{ freight.departure }}</td>
            <td>{{ freight.destination }}</td>
            <td class="price">{{ freight.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td>
                <djc-date [timeInput]="freight.validUntil" [format]="_auth.getUserSettings().dateFormat"></djc-date>
            </td>
            <td>{{ freight.modifier.fullName }}</td>
            <td>
                <djc-date [timeInput]="freight.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="_contextMenu"></p-contextMenu>
<div *ngIf="_showDetails">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDetails" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <!--div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'logistics.forwarder' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedFreight.forwarder.name }}</div>
                        </div-->
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.country' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedFreight.country.country }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'logistics.departure' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedFreight.departure }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.type' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedFreight.typeDescription }}</div>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <!--div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'logistics.shippingLine' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedFreight.shippingLine.name }}</div>
                        </div-->
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'logistics.containerSize' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedFreight.containerSize.name }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'logistics.destination' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedFreight.destination }}</div>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedFreight.creator.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedFreight.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedFreight.modifier.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedFreight.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{ 'logistics.costs' | translate }}" [selected]="true">
                <freightCosts [selectedFreight]="selectedFreight"></freightCosts>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showDialog">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_freightForm" novalidate (ngSubmit)="saveFreight()">
                    <!--div class="ui-g">
                        <div class="ui-g-4"><label for="forwarder">{{ 'logistics.forwarder' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="forwarder" [options]="_forwarders" [scrollHeight]="'100px'"
                                        formControlName="forwarderId"></p-dropdown>
                        </div>
                    </div-->
                    <!--div class="ui-g">
                        <div class="ui-g-4"><label for="shippingLine">{{ 'logistics.shippingLine' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="shippingLine" [options]="_shippingLines" [scrollHeight]="'100px'"
                                        formControlName="shippingLineId"></p-dropdown>
                        </div>
                    </div-->
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="country">{{ 'base.country' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="country" [options]="_countries" filter="true" [scrollHeight]="'100px'"
                                        formControlName="countryId"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="containerSize">{{ 'logistics.containerSize' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="containerSize" [options]="_containers" filter="true" [scrollHeight]="'100px'"
                                        formControlName="containerSizeId"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="type">{{ 'base.type' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="type" [options]="_types" [scrollHeight]="'100px'"
                                        formControlName="type"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="departure">{{ 'logistics.departure' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="departure" formControlName="departure"></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="destination">{{ 'logistics.destination' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="destination" formControlName="destination"></div>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveFreight()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_freightForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
