<div class="moduleItemMenu">
    <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadPrices()" label="{{ 'base.refresh' | translate }}"></button>
    <button *ngIf="_auth.modules['products'].authLevel >= 30"
            class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createPrice()" label="{{ 'base.create' | translate }}"></button>
</div>
<p-table #dt [value]="_prices" [rows]="10" [paginator]="true" [pageLinks]="5"
         [sortField]="'validFrom'" [sortOrder]="-1"
         [(contextMenuSelection)]="selectedPrice" [contextMenu]="cm">
    <ng-template pTemplate="header">
        <tr>
            <th>{{ 'base.validFrom' | translate }}</th>
            <th>{{ 'base.validUntil' | translate }}</th>
            <th>{{ 'base.price' | translate }}</th>
            <th>{{ 'addressbook.company' | translate }}</th>
            <th>{{ 'products.minimumQuantity' | translate }}</th>
            <th>{{ 'base.modifier' | translate }}</th>
            <th>{{ 'base.modified' | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-price>
        <tr [pContextMenuRow]="price" (dblclick)="onRowDblclick($event, price)">
            <td>
                <djc-date [timeInput]="price.validFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date>
            </td>
            <td>
                <djc-date [timeInput]="price.validUntil" [format]="_auth.getUserSettings().dateFormat"></djc-date>
            </td>
            <td class="price">{{ price.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td>{{ price.company ? price.company.name : 'Geen leverancier' }}</td>
            <td style="text-align: right">{{ price.minimumQuantity | number:'1.2-2':'nl' }}</td>
            <td>{{ price.modifier.fullName }}</td>
            <td>
                <djc-date [timeInput]="price.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="_contextMenu" appendTo="body"></p-contextMenu>
<div *ngIf="_showPriceDetails">
    <p-dialog [header]="_recordTitle" [visible]="_showPriceDetails" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()" appendTo="body">
        <p-tabView effect="fade" [styleClass]="'pTabView'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.price' | translate }}</b></div>
                        <div class="ui-g-8 priceDetail"><span style="text-align: right">{{ selectedPrice.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</span></div>
                        <div class="ui-g-4"><b>{{ 'addressbook.company' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedPrice.company.name }}</div>
                        <div class="ui-g-4"><b>{{ 'base.validFrom' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedPrice.validFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'products.minimumQuantity' | translate }}</b></div>
                        <div class="ui-g-8"><span style="text-align: right">{{ selectedPrice.minimumQuantity | number:'1.2-2':'nl' }}</span></div>
                        <div class="ui-g-4"><b>{{ 'products.supplierCode' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedPrice.supplierCode}}</div>
                        <div class="ui-g-4"><b>{{ 'base.validUntil' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedPrice.validUntil" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <textarea pInputTextarea readonly [ngModel]="selectedPrice.comment" cols="80" rows="10"></textarea>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedPrice.creator.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedPrice.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedPrice.modifier.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedPrice.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showPriceDialog">
    <p-dialog [header]="_recordTitle" [visible]="_showPriceDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()" appendTo="body">
        <p-tabView effect="fade" [styleClass]="'pTabView'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_priceForm" novalidate (ngSubmit)="savePrice()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="price">{{ 'base.price' | translate}}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="price" formControlName="price" thousandSeparator="." decimalSeparator=","
                                       [ngModel]="selectedPrice.price" min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="supplier">{{ 'addressbook.S'| translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="supplier" [options]="_suppliers" [filter]="true"
                                        formControlName="companyId"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="supplierCode">{{ 'products.supplierCode'| translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="supplierCode" formControlName="supplierCode"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="minimumQuantity">{{ 'products.minimumQuantity' | translate}}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="minimumQuantity" formControlName="minimumQuantity" thousandSeparator="." decimalSeparator=","
                                       [ngModel]="selectedPrice.minimumQuantity" min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="validFrom">{{ 'base.validFrom' | translate}}</label></div>
                        <div class="ui-g-8">
                            <p-calendar [ngClass]="'pCalendar'" id="validFrom" formControlName="validFromDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'" [maxDate]="_priceForm.get('validUntilDate').value"></p-calendar>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="validUntil">{{ 'base.validUntil' | translate}}</label></div>
                        <div class="ui-g-8">
                            <p-calendar [ngClass]="'pCalendar'" id="validUntil" formControlName="validUntilDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'" [minDate]="_priceForm.get('validFromDate').value"></p-calendar>
                        </div>
                    </div>
                </form>
            </p-tabPanel>
            <p-tabPanel header="{{ 'base.comment' | translate }}">
                <form [formGroup]="_commentForm" novalidate (ngSubmit)="savePrice()">
                    <div class="ui-g">
                        {{ _auth.getUser().fullName }} {{ _commentTime }}
                    </div>
                    <div class="ui-g">
                        <textarea pInputTextarea rows="10" cols="80" id="comment" formControlName="comment"></textarea>
                    </div>
                    <div class="ui-g">
                        <textarea readonly pInputTextarea id="oldComment" rows="10" cols="80" [ngModel]="selectedPrice.comment" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="savePrice()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_priceForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
