<div id="moduleMenu">
    <span *ngIf="selectedAddressBook" class="itemName floatLeft">{{ 'addressbook.addressbook' | translate }}: {{ selectedAddressBook.name }}</span>
    <span id="moduleMenuTitle" class="floatRight">{{ 'addressbook.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadCompanies()" label="{{ 'base.refresh' | translate }}"></button>
        <button *ngIf="selectedAddressBook.aclLevel >= 20"
                class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createCompany()" label="{{ 'base.create' | translate }}"></button>
        <button *ngIf="_auth.modules['addressbook'].authLevel == 100"
                class="pButton" type="button" pButton icon="fa fa-cog" (click)="manageAddressBooks()" label="{{ 'base.manage' | translate }}"></button>
    </div>
    <div class="ui-g module">
        <div class="ui-g-2 ui-md-2">
            <p-listbox [options]="_addressBooks" [(ngModel)]="selectedAddressBook" [optionLabel]="'name'" [dataKey]="'id'" (onChange)="loadCompanies()">
                <p-header>
                    <i class="fa fa-address-book"></i>
                    {{ 'addressbook.addressbook' | translate }}
                </p-header>
            </p-listbox>
        </div>
        <div class="ui-g-10 ui-md-10 module" style="">
            <company [selectedAddressBook]="selectedAddressBook" [(reload)]="_loadCompanies" [(createNew)]="_createCompany"></company>
        </div>
    </div>
    <div *ngIf="_showManagementDialog">
        <p-dialog header="{{ 'base.manage' | translate }}" [visible]="_showManagementDialog" [modal]="true" [responsive]="true"
                  [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancelManagement()" appendTo="body">
            <div class="moduleItemMenu">
                <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadAddressBooks()" label="{{ 'base.refresh' | translate }}"></button>
                <button class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createAddressBook()" label="{{ 'base.create' | translate }}"></button>
            </div>
            <p-table #abt [value]="_addressBooks" [rows]="15" [paginator]="true" [pageLinks]="5"
                     [(contextMenuSelection)]="selectedAddressBookDialog" [contextMenu]="abcm">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'base.strName' | translate }}</th>
                        <th>{{ 'base.modifier' | translate }}</th>
                        <th>{{ 'base.modified' | translate }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-addressbook>
                    <tr [pContextMenuRow]="addressbook" (dblclick)="onRowDblclickAB($event, addressbook)">
                        <td>{{ addressbook.name }}</td>
                        <td>{{ addressbook.modifier.fullName }}</td>
                        <td>
                            <djc-date [timeInput]="addressbook.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-contextMenu #abcm [model]="_abContextMenu" appendTo="body"></p-contextMenu>
        </p-dialog>
    </div>
    <div *ngIf="_showAddressBookDialog">
        <p-dialog [header]="_recordTitleAB" [visible]="_showAddressBookDialog" [modal]="true" [responsive]="true"
                  [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancelAddressBook()" appendTo="body">
            <p-tabView>
                <p-tabPanel header="{{ 'base.data' | translate }}">
                    <form [formGroup]="_addressBookForm" novalidate (ngSubmit)="saveAddressBook()">
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="nameAB">{{ 'base.strName' | translate }}</label></div>
                            <div class="ui-g-8"><input pInputText type="text" id="nameAB" formControlName="name"/></div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel header="{{ 'base.security' | translate }}" [disabled]="_newAddressBook">
                    <acl *ngIf="!_newAddressBook"></acl>
                </p-tabPanel>
            </p-tabView>
            <p-footer>
                <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveAddressBook()"
                        label="{{ 'base.save' | translate }}" [disabled]="!(_addressBookForm.valid)"></button>
                <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancelAddressBook()"
                        label="{{ 'base.cancel' | translate }}"></button>
            </p-footer>
        </p-dialog>
    </div>
</div>
