<div id="moduleMenu">
    <span *ngIf="selectedGroup" class="itemName floatLeft">{{ 'groups.group' | translate }}: {{ selectedGroup.name }}</span>
    <span id="moduleMenuTitle" class="floatRight">{{ 'groups.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadGroups()" label="{{ 'base.refresh' | translate }}"></button>
        <button *ngIf="_auth.modules['groups'].authLevel >= 30"
                class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createGroup()" label="{{ 'base.create' | translate }}"></button>
        <!--button *ngIf="selectedGroup && _auth.modules.groups.authLevel >= 20 && !newGroup"
                class="pButton" type="button" pButton icon="fa fa-pencil-square-o" (click)="editGroup()" label="{{ 'base.edit' | translate }}"></button>
        <button *ngIf="selectedGroup && _auth.modules.groups.authLevel >= 40 && !newGroup"
                class="pButton" type="button" pButton icon="fa fa-minus-square-o" (click)="deleteGroup()" label="{{ 'base.delete' | translate }}"></button-->
    </div>
    <p-table #dt [value]="groups" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
             [(contextMenuSelection)]="selectedGroup" [contextMenu]="cm"
             [scrollable]="true" [scrollHeight]="'flex'" [styleClass]="'table'"
             [sortField]="'userName'">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'name'">{{ 'base.strName' | translate }}
                    <p-sortIcon [field]="'name'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'owner.fullName'">{{ 'groups.owner' | translate }}
                    <p-sortIcon [field]="'owner.fullName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                    <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                    <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'name', 'contains')"></th>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'owner.fullName', 'contains')"></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-group>
            <tr [pContextMenuRow]="group" (dblclick)="onRowDblclickGroup($event, group)">
                <td>{{ group.name }}</td>
                <td>{{ group.owner.fullName }}</td>
                <td>{{ group.modifier.fullName }}</td>
                <td>
                    <djc-date [timeInput]="group.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-contextMenu #cm [model]="_contextMenu"></p-contextMenu>
    <div *ngIf="displayDialog">
        <p-dialog header="{{ _recordTitle }}" [(visible)]="displayDialog" [modal]="true" [responsive]="true"
            [styleClass]="'ui-g-6 pDialog'" (visibleChange)="cancel()">
            <p-tabView effect="fade">
                <p-tabPanel header="{{ 'base.data' | translate}}">
                    <form [formGroup]="_groupForm" novalidate (ngSubmit)="saveGroup()">
                        <div class="ui-g-6">
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="name">{{ 'base.strName' | translate}}</label></div>
                                <div class="ui-g-8"><input pInputText id="name" formControlName="name" /></div>
                            </div>
                            <div class="ui-g">
                                <div class="ui-g-4"><label for="owner">{{ 'groups.owner' | translate}}</label></div>
                                <div class="ui-g-8">
                                    <p-dropdown id="owner" [options]="_users"
                                                formControlName="ownerId"></p-dropdown>
                                </div>
                            </div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel header="{{ 'modules.name' | translate }}" [disabled]="newGroup || _auth.modules.groups.authLevel < 100">
                    <p-table #mt [value]="aclModules" [sortField]="'name'">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{ 'base.strName' | translate }}</th>
                                <th width="15%">{{ 'base.none' | translate }}</th>
                                <th width="15%">{{ 'base.use' | translate }}</th>
                                <th width="15%">{{ 'base.manage' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-module>
                            <tr>
                                <td>{{ module.name }}</td>
                                <td style="text-align: center">
                                    <p-radioButton name="{{module.id}}" value="0" [(ngModel)]="module.authLevel"></p-radioButton>
                                </td>
                                <td style="text-align: center">
                                    <p-radioButton name="{{module.id}}" value="10" [(ngModel)]="module.authLevel"></p-radioButton>
                                </td>
                                <td style="text-align: center">
                                    <p-radioButton name="{{module.id}}" value="100" [(ngModel)]="module.authLevel"></p-radioButton>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
                <p-tabPanel header="{{ 'base.security' | translate}}" [disabled]="_auth.modules.groups.authLevel < 100">
                    <acl *ngIf="_auth.modules.groups.authLevel == 100"></acl>
                </p-tabPanel>
            </p-tabView>
            <p-footer>
                <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveGroup()"
                        label="{{ 'base.save' | translate }}" [disabled]="!_groupForm.valid"></button>
                <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                        label="{{ 'base.cancel' | translate }}"></button>
            </p-footer>
        </p-dialog>
    </div>
</div>
