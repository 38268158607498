import {Company} from "../addressbook/addressbook.model";
import {User} from "../users/users.models";

export class Product {
    id: string;
    prefPackagingId: string;
    prefPackaging: Package;
    code: string;
    description: string;
    comment: string;
    IMO: boolean;
    EHF: boolean;
    activeUntil: number;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}

export class ProductPrice {
    id: string;
    productId: string;
    product: Product;
    companyId: string;
    company: Company
    price: number;
    validFrom: number;
    validFromDate: Date;
    validUntil: number;
    validUntilDate: Date;
    supplierCode: string;
    minimumQuantity: number;
    comment: string;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}

export class Package {
    id: string;
    description: string;
    price: number;
    specification: string;
    activeUntil: number;
    softDeleted: boolean;
    createdAt: number;
    creatorId: string;
    creator: User;
    modifiedAt: number;
    modifierId: string;
    modifier: User;
}
