<div id="moduleMenu">
    <span *ngIf="selectedSubItem" class="itemName floatLeft">{{ selectedSubItem }}</span>
    <span id="moduleMenuTitle" class="floatRight">{{ 'products.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button class="pButton" pButton icon="fa fa-refresh" (click)="loadItems()" label="{{ 'base.refresh' | translate }}"></button>
        <button *ngIf="_auth.modules['products'].authLevel >= 20 && _selectedOption"
                class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createNewItem()" label="{{ 'base.create' | translate }}"></button>
        <button *ngIf="_auth.modules['products'].authLevel >= 10 && _showActive"
                class="pButton" type="button" pButton icon="fa fa-toggle-off" (click)="showInactive()" label="{{ 'base.inactive' | translate}}"></button>
        <button *ngIf="_auth.modules['products'].authLevel >= 10 && !_showActive"
                class="pButton" type="button" pButton icon="fa fa-toggle-on" (click)="showActive()" label="{{ 'base.active' | translate}}"></button>
        <button *ngIf="_auth.modules['products'].authLevel >= 30 && _selectedOption==='PR'"
                class="pButton" type="button" pButton icon="fa fa-cloud-upload" (click)="showImport()" label="{{ 'products.import' | translate}}"></button>
    </div>
    <div class="ui-g module">
        <div class="ui-g-2">
            <div *ngIf="_showListBox">
                <p-listbox #listBox [options]="_options" [optionLabel]="'label'" (onChange)="selectItem($event)" [(ngModel)]="_optionSelected">
                    <p-header>
                        <i class="fa fa-filter"></i>
                        {{ 'products.types' | translate }}
                    </p-header>
                </p-listbox>
            </div>
        </div>
        <div class="ui-g-10" style="display: flex">
            <div *ngIf="_showProductsTable" style="display: flex">
                <productsProducts [(reload)]="_loadProducts" [(newItem)]="_newProduct" [showActive]="_showActive"></productsProducts>
            </div>
            <div *ngIf="_showPackageTable" style="display: flex">
                <productPackages [(reload)]="_loadPackages" [(newItem)]="_newPackage" [showActive]="_showActive"></productPackages>
            </div>
        </div>
    </div>
    <div *ngIf="_showImport">
        <p-dialog header="{{ 'addressbook.S' | translate }} CSV Import" [visible]="_showImport" [modal]="true" [responsive]="true"
                   [styleClass]="'ui-lg-3 ui-md-4 ui-g-8 pDialog'" (visibleChange)="cancelImport()">
            <div class="ui-g">
                <div class="ui-g-4"><label for="supplier"></label>{{ 'addressbook.S' | translate }}</div>
                <div class="ui-g-8"><p-dropdown id="supplier" [options]="_suppliers" [filter]="true"
                                                [(ngModel)]="import.companyId"></p-dropdown></div>
            </div>
            <div *ngIf="import.companyId !== ''">
                <div class="ui-g">
                    <div class="ui-g-4"><label for="validFrom">{{ 'base.validFrom' | translate}}</label></div>
                    <div class="ui-g-8">
                        <p-calendar [ngClass]="'pCalendar'" id="validFrom" [(ngModel)]="import.validFromDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'"></p-calendar>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-g-4"><label for="daysValid">{{ 'products.daysValid'| translate }}</label></div>
                    <div class="ui-g-8">
                        <djc-spinner id="daysValid" [(ngModel)]="import.daysValid" [min]="0" [max]="180" [step]="10"></djc-spinner>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-g-4"><label for="header">{{ 'base.headerLine'| translate }}</label></div>
                    <div class="ui-g-8">
                        <p-inputSwitch id="header" [(ngModel)]="import.headerLine"></p-inputSwitch>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-g-4"><label for="file">CSV-file</label></div>
                    <div class="ui-g-8">
                        <p-fileUpload id="file" name="csvFile[]" accept=".csv" maxFileSize="100000" chooseLabel="Browse"
                                      [customUpload]="false" url="api/products/prices/import" (onBeforeUpload)="importCSV($event)"
                                      (onUpload)="importReady($event)" [disabled]="_showFeedback"></p-fileUpload>
                    </div>
                </div>
            </div>
            <div *ngIf="_showFeedback">
                <div class="ui-g">
                    <div class="ui-g-4"><label>{{ 'products.imported' | translate }}</label></div>
                    <div class="ui-g-8">{{ feedback.lines }}</div>
                </div>
                <div class="ui-g">
                    <div class="ui-g-4"><label for="message">{{ 'products.feedback' | translate }}</label></div>
                    <div class="ui-g-8">
                        <textarea readonly pInputTextarea id="message" rows="10" cols="80"
                                  [ngModel]="feedback.message" [ngModelOptions]="{standalone: true}">
                        </textarea>
                    </div>
                </div>
            </div>
        </p-dialog>
    </div>
</div>
