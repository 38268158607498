import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {FinanceComponent} from "./finance.component";
import {RatesComponent} from "./rates.component";
import {CurrenciesComponent} from "./currencies.component";
import {PaymentmodComponent} from "./paymentmod.component";

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        FinanceComponent,
        CurrenciesComponent,
        RatesComponent,
        PaymentmodComponent
    ],
    exports: [
        CurrenciesComponent,
        RatesComponent,
        PaymentmodComponent
    ]
})
export class FinanceModule {}