import {Component, Input, OnInit} from "@angular/core";
import {Company, Contact} from "./addressbook.model";
import {ApiService} from "../api.service";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MenuItem} from "primeng/api";
import {DatePipe} from "@angular/common";
import {isNull} from "util";

@Component({
    selector: 'contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
    @Input() selectedCompany: Company;
    @Input() aclLevel: number;

    public _contacts: Contact[];
    private _newContact: boolean = false;
    public _showContactDetails: boolean = false;
    public _showContactDialog: boolean = false;
    private _phantomContact: Contact;
    private _recordTitle: string;
    private _contactForm: FormGroup;
    private _commentForm: FormGroup;
    public _contextMenu: MenuItem[] = [];
    private _commentTime: string;

    public selectedContact: Contact;

    constructor(private _apiService: ApiService, private _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {

    }
    ngOnInit() {
        this.loadContacts();
        this._contactForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'companyId': new FormControl({value: '', disabled: true}),
            'company': new FormControl({value: '', disabled: true}),
            'firstName': new FormControl(''),
            'lastName': new FormControl('', Validators.required),
            'comment': new FormControl(''),
            'telephone': new FormControl(''),
            'telephoneSecond': new FormControl(''),
            'mobilePhone': new FormControl(''),
            'email': new FormControl('', Validators.email),
            'emailSecond': new FormControl('', this._st.secondMailfieldValidator),
            'type': new FormControl(''),
            'position': new FormControl(''),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });
        this._commentForm = this._fb.group({
            'comment': new FormControl('')
        });

        if (this.aclLevel >= 10) {
            this._contextMenu.push({label: this._transService.instant('base.details'), icon: 'fa fa-info-circle', command: (event) => this.detailsContact()});
        }
        if (this.aclLevel >= 20) {
            this._contextMenu.push({label: this._transService.instant('base.edit'), icon: 'fa fa-pencil-square-o', command: (event) => this.editContact()});
        }
        if (this.aclLevel >= 40) {
            this._contextMenu.push({label: this._transService.instant('base.delete'), icon: 'fa fa-minus-square-o', command: (event) => this.deleteContact()});
        }
    }

    loadContacts() {
        this._apiService.setActionUrl('addressbook/contacts');
        this._apiService.setFilter({ field: 'companyId', operator: 'eq', value: this.selectedCompany.id });
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    this._contacts = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        )
    }

    selectContact(event) {
        this.selectedContact = this._st.cloneRecord(event.data, new Contact());
    }

    cancelContact() {
        this.selectedContact = null;
        this._phantomContact = null;
        this._showContactDialog = null;
        this._showContactDetails = null;
    }

    createContact() {
        this.selectedContact = new Contact();
        this._newContact = true;
        this._showContactDialog = true;
        this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('addressbook.contact'));
        this._contactForm.reset();
        //this._contactForm.setValue({'companyId': this.selectedCompany.id});
        this._commentForm.reset();
        this._commentTime = this._dp.transform(new Date(), this._auth.getUserSettings().dateTimeFormat);
    }

    detailsContact() {
        this._showContactDetails = true;
        this._recordTitle = this.selectedContact.firstName + ' ' + this.selectedContact.lastName;
    }

    editContact() {
        this._showContactDialog = true;
        this._recordTitle = this.selectedContact.firstName + ' ' + this.selectedContact.lastName;
        this._contactForm.setValue(this.selectedContact);
        this._commentForm.reset();
        this._commentTime = this._dp.transform(new Date(), this._auth.getUserSettings().dateTimeFormat);
    }

    deleteContact() {

    }

    saveContact() {
        this.selectedContact = this._contactForm.getRawValue();
        let newComment:string = this._commentForm.get('comment').value || '';
        if (newComment.length > 0) {
            let oldComment = this.selectedContact.comment;
            if (isNull(oldComment)) {
                this.selectedContact.comment = this._auth.getUser().fullName + ' ' + this._commentTime + "\n" + newComment + "\n";
            } else {
                this.selectedContact.comment = this._auth.getUser().fullName + ' ' + this._commentTime + "\n" + newComment + "\n" + oldComment;
            }
        }
        this._apiService.setActionUrl('addressbook/contacts');
        if (this._newContact) {
            this.selectedContact.companyId = this.selectedCompany.id;
            this._apiService.createRecord(JSON.stringify(this.selectedContact)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            )
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedContact)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            )
        }
    }

    private _afterSave(response) {
        if (response.success) {
            this._newContact = false;
            this.loadContacts();
            this.selectedContact = null;
            this._showContactDialog = false;
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    onRowDblclick($event, contact) {
        this.selectedContact = contact;
        this.editContact();
    }
}
