<p-table #dt [loading]="_loading" [value]="_currencies" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5" [styleClass]="'table'"
         [(contextMenuSelection)]="selectedCurrency" [contextMenu]="cm" [scrollable]="true" [scrollHeight]="'flex'"
         [sortField]="'type'">
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'type'">{{ 'finance.currencyType' | translate }}
                <p-sortIcon [field]="'type'"></p-sortIcon>
            </th>
            <th>{{ 'finance.notation' | translate }}</th>
        </tr>
        <tr>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'type', 'contains')"></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-currency>
        <tr [pContextMenuRow]="currency" (dblclick)="onRowDblclick($event, currency)">
            <td>{{ currency.type }}</td>
            <td>{{ currency.notation }}</td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="_contextMenu"></p-contextMenu>
<div *ngIf="_showDetails">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDetails" [modal]="true" [responsive]="true"
              [styleClass]="'pDialog ui-lg-6 ui-md-8 ui-g-12'" (visibleChange)="cancel()" appendTo="body">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-g-4"><b>{{ 'finance.currencyType' | translate }}</b></div>
                    <div class="ui-g-8">{{ selectedCurrency.type }}</div>
                </div>
                <div class="ui-g">
                    <div class="ui-g-4"><b>{{ 'finance.notation' | translate }}</b></div>
                    <div class="ui-g-8">{{ selectedCurrency.notation }}</div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{ 'finance.rates' | translate}}">
                <currencyRates [selectedCurrency]="selectedCurrency"></currencyRates>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showDialog">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDialog" [modal]="true" [responsive]="true"
              [styleClass]="'pDialog ui-lg-6 ui-md-8 ui-g-12'" (visibleChange)="cancel()" appendTo="body">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_currencyForm" novalidate (ngSubmit)="saveCurrency()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="type">{{ 'finance.currencyType' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="type" formControlName="type"></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="notation">{{ 'finance.notation' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="notation" formControlName="notation"></div>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveCurrency()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_currencyForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
