import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {User, UserSettings} from "./users/users.models";

export class DjcFilter {
    field: string;
    value: any;
    operator: string;
    whereClause?: string = 'AND';
}

@Injectable()
export class ApiService {

    private _url: string;
    private _headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'});
    private _filter: any = [];
    private _sort: any = [];
    private _listFields: string[] = [];
    private _extraParams: any = [];

    constructor(private _http: HttpClient, private _authService: AuthService, private _router: Router) {

    }

    public setActionUrl(url: string) {
        this._url = '/api/' + url;
        if (this._url.substr(this._url.length - 1, 1) !== '/') {
            this._url += '/';
        }
        this._headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: 'Bearer ' + this._authService.getToken()
        });
        this.resetFilter();
        this.resetSort();
        this.resetFields();
        this.resetExtraParams();
    }

    /**
     * filter consist of
     * @param filter
     */
    public setFilter(filter: DjcFilter) {
        this._filter.push(filter);
    }

    public resetFilter() {
        this._filter = [];
    }

    public setSort(sort: sort) {
        this._sort.push(sort);
    }

    public resetSort() {
        this._sort = [];
    }

    /**
     * Load only necesarry fields because of big table (from 100+ records)
     * "id" field is always included in this array
     *
     * @param {string[]} fields
     */
    public setListFields(fields: string[]) {
        fields.forEach(function(field) {
            this._listFields.push(field);
        }, this);
    }

    public resetFields() {
        this._listFields = ['id'];
    }

    public setExtraParams(field: string, value: any) {
        this._extraParams.push({field: field, value: value});
    }

    public resetExtraParams() {
        this._extraParams = [];
    }

    public getStore(extraParams?: string) {
        let params = new HttpParams();
        if (this._filter.length > 0) {
            params = params.append('filters', JSON.stringify(this._filter));
        }
        if (this._sort.length > 0) {
            params = params.append('sortOrder', JSON.stringify(this._sort));
        }
        if (typeof (extraParams) !== 'undefined') {
            params = params.append('extraParams', extraParams);
        }
        // Load only if beside "id" also other fields are selected
        if (this._listFields.length > 1) {
            params = params.append('listFields', JSON.stringify(this._listFields));
        }
        if (this._extraParams.length > 0) {
            this._extraParams.forEach(function(extraParam) {
                params = params.append(extraParam.field, extraParam.value);
            });
        }
        return this._http.get<responseModel>(this._url + 'store', {observe: 'body', headers: this._headers, params: params});
    }

    public getDropDown(labelFields: string, valueField?: string, useSelectValue: string = "Y") {
        let params = new HttpParams();
        if (this._filter.length > 0) {
            params = params.append('filters', JSON.stringify(this._filter));
        }
        if (this._sort.length > 0) {
            params = params.append('sortOrder', JSON.stringify(this._sort));
        }
        if (typeof (valueField) !== 'undefined') {
            params = params.append('valueField', valueField);
        }
        params = params.append('labelFields', labelFields);
        params = params.append('useSelectValue', useSelectValue);
        if (this._extraParams.length > 0) {
            this._extraParams.forEach(function(extraParam) {
                params = params.append(extraParam.field, extraParam.value);
            });
        }
        return this._http.get<responseModel>(this._url + 'dropdown', {observe: 'body', headers: this._headers, params: params});
    }

    public createRecord(body: string) {
        return this._http.post<responseModel>(this._url + 'create', body, {headers: this._headers});
    }

    public updateRecord(body: string) {
        return this._http.put<responseModel>(this._url + 'update', body,{headers: this._headers});
    }

    public deleteRecord(id: string) {
        return this._http.delete<responseModel>(this._url + 'delete?id=' + id, {headers: this._headers});
    }

    public createSetting(body: string) {
        return this._http.post<responseModel>(this._url + 'createsetting', body, {headers: this._headers});
    }

    public updateSetting(body: string) {
        return this._http.put<responseModel>(this._url + 'updatesetting', body, {headers: this._headers});
    }

    public getNewAclItemId(aclType: string, aclField: string) {
        let params = new HttpParams();
        params = params.append('aclType', aclType);
        params = params.append('aclField', aclField);
        return this._http.get<responseModel>(this._url + 'newacl', {observe: 'body', headers: this._headers, params: params});
    }

    public specialPostAction(body: string, action: string) {
        return this._http.post<responseModel>(this._url + action, body, {headers: this._headers});
    }

    public getHighestMod() {
        return this._http.get<responseModel>(this._url + 'highmod', {observe: 'body', headers: this._headers});
    }

    public checkHighestMod(highestMods: any, module: string) {
        Object.keys(highestMods).forEach(function(mod) {
            this.setActionUrl(highestMods[mod].url);
            console.log(mod);
            console.log(highestMods[mod]);
        }, this);
    }

}

export class responseModel {
    sendParams: any;
    success: boolean;
    data: responseData = new responseData();
    errorMsg: string;
    readTranslations: boolean = false;
    user: User = new User();
    userSettings: UserSettings = new UserSettings();
    authToken: string;
    newId: string = '';
    newAclId: string = '';
}

export class responseData {
    recordCount: number;
    records: any;
    fileName: string;
    highModSeq: number;
}

export class sort {
    field: string;
    direction: string;
}
