<!--div *ngIf="_showCompanyTable"-->
    <p-table #dt [loading]="loading" [value]="_companies" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
             [scrollable]="true" [scrollHeight]="'flex'" [styleClass]="'table'"
             [sortField]="'name'" [(contextMenuSelection)]="selectedCompany" [contextMenu]="cm">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'name'">{{ 'base.strName' | translate }}
                    <p-sortIcon [field]="'name'"></p-sortIcon>
                </th>
                <th>{{ 'addressbook.city' | translate }}</th>
                <th [pSortableColumn]="'country.country'">{{ 'base.country' | translate }}
                    <p-sortIcon [field]="'country.country'"></p-sortIcon>
                </th>
                <th>{{ 'addressbook.type' | translate }}</th>
                <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                    <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                    <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'name', 'contains')"></th>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'city', 'contains')"></th>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'country.country', 'contains')"></th>
                <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'typeDescription', 'contains')"></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-company>
            <tr [pContextMenuRow]="company" (dblclick)="onRowDblclick($event, company)">
                <td>{{ company.name }}</td>
                <td>{{ company.city }}</td>
                <td>{{ company.country.country }}</td>
                <td>{{ company.typeDescription }}</td>
                <td>{{ company.modifier.fullName }}</td>
                <td>
                    <djc-date [timeInput]="company.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                </td>
            </tr>
        </ng-template>
    </p-table>
<!--/div-->
<p-contextMenu #cm [model]="_contextMenu"></p-contextMenu>
    <div *ngIf="_showCompanyDetails" style="display: flex">
    <p-dialog header="{{ _recordTitle }}" [(visible)]="_showCompanyDetails" [modal]="true" [responsive]="true" [appendTo]="'body'"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancelCompany()" [closeIcon]="'fa fa-times'">
        <p-tabView effect="fade" (onChange)="changedTabView($event)">
            <p-tabPanel header="{{ 'sales.orderType_N' | translate }}" *ngIf="_auth.modules.sales && _auth.modules.sales.authLevel > 0">
                <sales orderType="N" [selectedCompany]="selectedCompany" [displayCustomer]="false" [(reload)]="_reloadN" [createRights]="selectedAddressBook.aclLevel > 30"></sales>
            </p-tabPanel>
            <p-tabPanel header="{{ 'sales.orderType_Q' | translate }}" *ngIf="_auth.modules.sales && _auth.modules.sales.authLevel > 0">
                <sales orderType="Q" [selectedCompany]="selectedCompany" [displayCustomer]="false" [(reload)]="_reloadQ" [createRights]="false"></sales>
            </p-tabPanel>
            <p-tabPanel header="{{ 'sales.orderType_P' | translate }}" *ngIf="_auth.modules.sales && _auth.modules.sales.authLevel > 0">
                <sales orderType="P" [selectedCompany]="selectedCompany" [displayCustomer]="false" [(reload)]="_reloadP" [createRights]="false"></sales>
            </p-tabPanel>
            <p-tabPanel header="{{ 'sales.orderType_O' | translate }}" *ngIf="_auth.modules.sales && _auth.modules.sales.authLevel > 0">
                <sales orderType="O" [selectedCompany]="selectedCompany" [displayCustomer]="false" [(reload)]="_reloadO" [createRights]="false"></sales>
            </p-tabPanel>
            <p-tabPanel header="{{ 'sales.orderType_L' | translate }}" *ngIf="_auth.modules.sales && _auth.modules.sales.authLevel > 0">
                <sales orderType="L" [selectedCompany]="selectedCompany" [displayCustomer]="false" [(reload)]="_reloadL" [createRights]="false"></sales>
            </p-tabPanel>
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.strName' | translate}}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.name }}</div>
                        <div class="ui-g-4"><b>{{ 'addressbook.street' | translate}}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.street }}</div>
                        <div class="ui-g-4"><b>{{ 'addressbook.zipCode' | translate}}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.zipCode }}</div>
                        <div class="ui-g-4"><b>{{ 'addressbook.city' | translate}}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.city }}</div>
                        <div class="ui-g-4"><b>{{ 'base.country' | translate}}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.country.country }}</div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'addressbook.type' | translate}}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.typeDescription }}</div>
                        <div class="ui-g-4"><b>{{ 'finance.paymentmod' | translate}}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.prefPaymentMod.description }}</div>
                        <div class="ui-g-4"><b>{{ 'addressbook.creditInsured' | translate}}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.creditInsured }}</div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.creator.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedCompany.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedCompany.modifier.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedCompany.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{ 'addressbook.contacts' | translate }}">
                <contacts [selectedCompany]="selectedCompany" [aclLevel]="selectedAddressBook.aclLevel"></contacts>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showCompanyDialog">
    <p-dialog [header]="_recordTitle" [visible]="_showCompanyDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancelCompany()" appendTo="body">
        <p-tabView [styleClass]="'pTabView'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_companyForm" novalidate (ngSubmit)="saveCompany()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="addressbook">{{ 'addressbook.addressbook' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="addressbook" [options]="_addressBooksDropDown"
                                        formControlName="addressBookId"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="name">{{ 'base.strName' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="name" formControlName="name"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="street">{{ 'addressbook.street' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="street" formControlName="street"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="zipCode">{{ 'addressbook.zipCode' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="zipCode" formControlName="zipCode"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="city">{{ 'addressbook.city' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="city" formControlName="city"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="country">{{ 'base.country' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="country" [options]="_countriesDropDown" [filter]="true" [filterBy]="'label'"
                                        [scrollHeight]="'150px'" formControlName="countryId"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="type">{{ 'addressbook.type' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="type" [options]="_typeDropDown" [scrollHeight]="'100px'"
                                        formControlName="type"></p-dropdown>
                        </div>
                    </div>
                </form>
            </p-tabPanel>
            <p-tabPanel header="{{ 'finance.finance' | translate }}">
                <form [formGroup]="_financeForm" novalidate (ngSubmit)="saveCompany()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="creditInsured">{{ 'addressbook.creditInsured' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-inputSwitch id="creditInsured" formControlName="creditInsured"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="paymentModality">{{ 'finance.paymentmod' | translate }}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="paymentModality" [options]="_paymentModDropDown"
                                        [filter]="true" [filterBy]="'label'"
                                        formControlName="prefPaymentModalityId"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="debtorCode">{{ 'addressbook.debtorCode' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="debtorCode" formControlName="debtorCode"/></div>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveCompany()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_companyForm.valid && _financeForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancelCompany()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
<div *ngIf="_showProductHistory">
    <p-dialog [header]="_historyTitle" [visible]="_showProductHistory" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-8 ui-md-10 ui-g-12 pDialog'" (visibleChange)="cancelHistory()" appendTo="body">
        <p-table #hdt [value]="_productHistory" [rows]="15" [paginator]="true" [pageLinks]="5"
                 [loading]="_historyLoading" [sortMode]="'multiple'" [multiSortMeta]="_multiSortMeta">
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'product.code'">{{ 'products.code' | translate }}
                        <p-sortIcon [field]="'product.code'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'order.champrixRef'">{{ 'sales.champrixRef' | translate }}
                        <p-sortIcon [field]="'order.champrixRef'"></p-sortIcon>
                    </th>
                    <th>{{ 'base.strDescription' | translate }}</th>
                    <th>{{ 'sales.quantity' | translate }}</th>
                    <th>{{ 'sales.orderStatus' | translate }}</th>
                    <th [pSortableColumn]="'order.offerDate'">{{ 'sales.offerDate' | translate }}
                        <p-sortIcon [field]="'order.offerDate'"></p-sortIcon>
                    </th>
                    <th>{{ 'sales.salesPrice' | translate }}</th>
                </tr>
                <tr>
                    <th><input pInputText type="text" (input)="hdt.filter($event.target.value, 'product.code', 'contains')"></th>
                    <th><input pInputText type="text" (input)="hdt.filter($event.target.value, 'order.champrixRef', 'contains')"></th>
                    <th><input pInputText type="text" (input)="hdt.filter($event.target.value, 'extraString', 'contains')"></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-history>
                <tr>
                    <td>{{ history.product.code }}</td>
                    <td>{{ history.order.champrixRef }}</td>
                    <td>{{ history.extraString }}</td>
                    <td class="right">{{ history.quantity | number:'1.3-3':'nl' }}</td>
                    <td>{{ history.order.statusDescription }}</td>
                    <td class="right"><djc-date [timeInput]="history.order.offerDate" [format]="_auth.getUserSettings().dateFormat"></djc-date></td>
                    <td class="price">{{ history.salesPrice | currency:history.order.rate.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                </tr>
            </ng-template>
        </p-table>
    </p-dialog>
</div>
