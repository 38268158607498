import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MenuItem, SelectItem} from "primeng/api";
import {SupportTools} from "../support.tools";
import {DatePipe} from "@angular/common";
import {ApiService, responseModel} from "../api.service";
import {AuthService} from "../auth.service";
import {TranslateService} from "@ngx-translate/core";
import {Freight} from "./logistics.models";

@Component({
    selector: 'logisticsFreights',
    templateUrl: './freights.component.html',
    styleUrls: ['./logistics.component.css']
})
export class FreightsComponent implements OnInit {

    private _reload: boolean;
    private _showActive: boolean;

    @Input() set reload(value: boolean) {
        this._reload = value;
        if (this._reload) {
            this.loadFreights();
        }
    }
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get reload(): boolean {
        return this._reload;
    }

    @Input() set newItem(value: boolean) {
        this._newFreight = value;
        if (this._newFreight) {
            this.selectedFreight = new Freight();
            this._freightForm.reset();
            this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('logistics.freight'));
            this._showDialog = true;
        }
    }
    @Output() newItemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get newItem(): boolean {
        return this._newFreight;
    }

    @Input() set showActive(value: boolean) {
        this._showActive = value;
        if (this._contextMenu.length > 1) {
            this._transService.get(['base.activate', 'base.deactivate']).subscribe(
                response => {
                    if (this._auth.modules['logistics'].authLevel >= 40 && this._showActive) {
                        this._contextMenu.pop();
                        this._contextMenu.push({label: response['base.deactivate'], icon: 'fa fa-minus-square-o', command: (event) => this.deactivateFreight()});
                    }
                    if (this._auth.modules['logistics'].authLevel >= 40 && !this._showActive) {
                        this._contextMenu.pop();
                        this._contextMenu.push({label: response['base.activate'], icon: 'fa fa-plus-square-o', command: (event) => this.activateFreight()});
                    }
                }
            );
        }

        this.loadFreights();
    }

    get showActive(): boolean {
        return this._showActive;
    }


    public _loading: boolean = false;
    public _contextMenu: MenuItem[] = [];
    public _showDetails: boolean = false;
    public _showDialog: boolean = false;
    private _recordTitle: string;
    private _newFreight: boolean = false;
    private _countries: SelectItem[] = [];
    private _forwarders: SelectItem[] = [];
    private _shippingLines: SelectItem[] = [];
    private _containers: SelectItem[] = [];
    private _types: SelectItem[] = [];

    private _freightForm: FormGroup;

    public _freights: Freight[];
    public selectedFreight: Freight;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {
    }

    ngOnInit() {
        this._transService.get(['base.edit', 'base.delete', 'base.details', 'base.deactivate']).subscribe(
            response => {
                this._contextMenu.push({label: response['base.details'], icon: 'fa fa-info-circle', command: (event) => this.detailsFreight()});
                if (this._auth.modules['logistics'].authLevel >= 20) {
                    this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editFreight()});
                }
                if (this._auth.modules['logistics'].authLevel >= 40) {
                    this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteFreight()});
                    this._contextMenu.push({label: response['base.deactivate'], icon: 'fa fa-minus-square-o', command: (event) => this.deactivateFreight()});
                }
            }
        );

        this._freightForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'countryId': new FormControl('', Validators.required),
            'country': new FormControl({value: '', disabled: true}),
            'type': new FormControl('', Validators.required),
            'typeDescription': new FormControl({value: '', disabled: true}),
            'forwarderId': new FormControl(''),
            'forwarder': new FormControl({value: '', disabled: true}),
            'shippingLineId': new FormControl(''),
            'shippingLine': new FormControl({value: '', disabled: true}),
            'departure': new FormControl('', Validators.required),
            'destination': new FormControl('', Validators.required),
            'containerSizeId': new FormControl('', Validators.required),
            'containerSize': new FormControl({value: '', disabled: true}),
            'validUntil': new FormControl({value: '', disabled: true}),
            'price': new FormControl({value: '', disabled: true}),
            'activeUntil': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });

        this._transService.get(['base.useSelectValue', 'logistics.destination', 'logistics.harbour', 'logistics.local']).subscribe(
            response => {
                this._types.push({label: response['base.useSelectValue'], value: ''});
                this._types.push({label: response['logistics.destination'], value: 'D'});
                this._types.push({label: response['logistics.harbour'], value: 'H'});
                this._types.push({label: response['logistics.local'], value: 'L'});
            }
        );

        this.loadFreights();
        this.loadDropDowns();
    }

    loadFreights() {
        this._loading = true;
        this._apiService.setActionUrl('logistics/freights');
        if (this._showActive) {
            this._apiService.setFilter({field: 'activeUntil', operator: 'gt', value: this._st.getDateValue()});
        } else {
            this._apiService.setFilter({field: 'activeUntil', operator: 'le', value: this._st.getDateValue()});
        }
        this._apiService.getStore().subscribe(
            response => {
                this._loading = false;
                this._reload = false;
                this.reloadChange.emit(this._reload);
                if (response.success) {
                    this._freights = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        )
    }

    loadDropDowns() {
        this._apiService.setActionUrl('base/countries');
        this._apiService.setSort({field: 'country', direction: 'ASC'});
        this._apiService.getDropDown(JSON.stringify(['country'])).subscribe(
            response => {
                if (response.success) {
                    this._countries = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
        this._apiService.setActionUrl('logistics/forwarders');
        this._apiService.setSort({field: 'name', direction: 'ASC'});
        this._apiService.getDropDown(JSON.stringify(['name'])).subscribe(
            response => {
                if (response.success) {
                    this._forwarders = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
        this._apiService.setActionUrl('logistics/shippinglines');
        this._apiService.setSort({field: 'name', direction: 'ASC'});
        this._apiService.getDropDown(JSON.stringify(['name'])).subscribe(
            response => {
                if (response.success) {
                    this._shippingLines = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
        this._apiService.setActionUrl('logistics/containers');
        this._apiService.setSort({field: 'name', direction: 'ASC'});
        this._apiService.getDropDown(JSON.stringify(['name'])).subscribe(
            response => {
                if (response.success) {
                    this._containers = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    cancel() {
        this.selectedFreight = null;
        this._showDetails = false;
        this._showDialog = false;
        if (this._newFreight) {
            this._newFreight = false;
            this.newItemChange.emit(this._newFreight);
        }
        this._freightForm.reset();
    }

    detailsFreight() {
        this._showDetails = true;
        this._recordTitle = this.selectedFreight.departure + ' - ' + this.selectedFreight.destination;
    }

    editFreight() {
        this._showDialog = true;
        this._freightForm.setValue(this.selectedFreight as any);
        this._recordTitle =this.selectedFreight.departure + ' - ' + this.selectedFreight.destination;
    }

    createFreight() {
        this.selectedFreight = new Freight();
        this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('logistics.freight'));
        this._newFreight = true;
        this._showDialog = true;
    }

    deleteFreight() {
        this._apiService.setActionUrl('logistics/freights');
        this._apiService.deleteRecord(this.selectedFreight.id).subscribe(
            response => {
                if (response.success) {
                    this.loadFreights();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    saveFreight() {
        this._apiService.setActionUrl('logistics/freights');
        this.selectedFreight = this._freightForm.getRawValue();
        if (this._newFreight) {
            this._apiService.createRecord(JSON.stringify(this.selectedFreight)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedFreight)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    deactivateFreight() {
        this.selectedFreight.activeUntil = this._st.getDateValue();
        this._apiService.setActionUrl('logistics/freights');
        this._apiService.updateRecord(JSON.stringify(this.selectedFreight)).subscribe(
            response => {
                if (response.success) {
                    this.loadFreights();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    activateFreight() {
        let newDate = new Date();
        newDate.setFullYear(2099,12,31);
        newDate.setHours(0,0,0, 0);
        this.selectedFreight.activeUntil = newDate.getTime() / 1000;
        this._apiService.setActionUrl('logistics/freights');
        this._apiService.updateRecord(JSON.stringify(this.selectedFreight)).subscribe(
            response => {
                if (response.success) {
                    this.loadFreights();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    private _afterSave(response: responseModel) {
        if (response.success) {
            this.cancel();
            this.selectedFreight = response.data.records[0];
            this.detailsFreight();
            this.loadFreights();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    onRowDblclick(event, freight) {
        this.selectedFreight = freight;
        this.detailsFreight();
    }
}
