<div id="moduleMenu">
    <span *ngIf="_selectedAddressbook" class="itemName floatLeft">{{ 'addressbook.addressbook' | translate }}: {{ _selectedAddressbook.name }}</span>
    <span id="moduleMenuTitle" class="floatRight">{{ 'addressbook.name' | translate }}</span>
</div>
<div id="moduleContent">
    <div class="moduleItemMenu">
        <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadCompanies()" label="{{ 'base.refresh' | translate }}"></button>
    </div>
    <div class="ui-g module" style="">
        <company [selectedAddressBook]="_selectedAddressbook" [(reload)]="_loadCompanies"></company>
    </div>
</div>
