import {Component} from "@angular/core";
import {Addressbook} from "../addressbook/addressbook.model";
import {ApiService} from "../api.service";
import {AuthService} from "../auth.service";

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.css']
})
export class BaseComponent {
    public _selectedAddressbook: Addressbook;
    public _loadCompanies: boolean = false;

    constructor(private _apiService: ApiService, private _auth: AuthService) {
        if (this._auth.getUserSettings().defaultAddressbook !== null) {
            this._apiService.setActionUrl('addressbook/addressbooks');
            this._apiService.setFilter({field: 'id', operator: 'eq', value: this._auth.getUserSettings().defaultAddressbook});
            this._apiService.getStore().subscribe(
                response => {
                    if (response.success) {
                        this._selectedAddressbook = response.data.records[0];
                        this._loadCompanies = true;
                    } else {
                        this._auth.addError(response.errorMsg);
                    }
                }, error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    loadCompanies() {
        this._loadCompanies = true;
    }

}
