import {Component, OnInit} from "@angular/core";
import {MenuItem, SelectItem} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../auth.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SupportTools} from "../support.tools";
import {DatePipe} from "@angular/common";
import {ApiService, responseModel} from "../api.service";
import {Currency} from "./finance.model";

@Component({
    selector: 'finance',
    templateUrl: './finance.component.html',
    styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {

    public _showListBox: boolean = false;
    public _showCurrencies: boolean = false;
    public _showPaymentModalities: boolean = false;

    private _loadCurrencies: boolean = false;
    private _loadPaymentModalities: boolean = false;

    private _newCurrency: boolean = false;
    private _newPaymentModality: boolean = false;

    private _options: SelectItem[] = [];
    private _selectedOption: string;
    public selectedSubItem: string;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {
    }


    ngOnInit() {
        this._transService.get(['finance.currencyType', 'finance.paymentMod']).subscribe(
            response => {
                this._options.push({label: response['finance.currencyType'], value: 'CU'});
                this._options.push({label: response['finance.paymentMod'], value: 'PM'});
                this._showListBox = true;
            }
        );
    }

    selectItem(event) {
        this._showCurrencies = false;
        this._showPaymentModalities = false;
        this._selectedOption = event.value;
        switch (this._selectedOption) {
            case 'CU':
                this.selectedSubItem = this._transService.instant('finance.currencyType');
                this._showCurrencies = true;
                break;
            case 'PM':
                this.selectedSubItem = this._transService.instant('finance.paymentMod');
                this._showPaymentModalities = true;
                break;
        }
        //this.loadItems();
    }

    loadItems() {
        switch (this._selectedOption) {
            case 'CU':
                this._loadCurrencies = true;
                break;
            case 'PM':
                this._loadPaymentModalities = true;
                break;
        }
    }

    createItem() {
        switch (this._selectedOption) {
            case 'CU':
                this._newCurrency = true;
                break;
            case 'PM':
                this._newPaymentModality = true;
                break;
        }
    }

}
