import {NgModule} from "@angular/core";
import {LogisticsComponent} from "./logistics.component";
import {SharedModule} from "../shared.module";
import {CostsComponent} from "./costs.component";
import {FreightsComponent} from "./freights.component";
import {ItemsComponent} from "./items.component";

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        LogisticsComponent,
        FreightsComponent,
        ItemsComponent,
        CostsComponent
    ],
    exports: [
        FreightsComponent,
        CostsComponent,
        ItemsComponent
    ]
})
export class LogisticsModule {}