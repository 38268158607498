<div class="moduleItemMenu">
    <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadCosts()" label="{{ 'base.refresh' | translate }}"></button>
    <button *ngIf="_auth.modules['logistics'].authLevel >= 30"
            class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createCost()" label="{{ 'base.create' | translate }}"></button>
</div>
<p-table #dt [loading]="_loading" [value]="_costs" [rows]="10" [paginator]="true" [pageLinks]="5"
         [sortField]="'validFrom'" [sortOrder]="-1"
         [(contextMenuSelection)]="selectedCost" [contextMenu]="cmCosts">
    <ng-template pTemplate="header">
        <tr>
            <th>{{ 'logistics.time' | translate }}</th>
            <th [pSortableColumn]="'sea'">{{ 'logistics.sea' | translate }}
                <p-sortIcon [field]="'sea'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'land'">{{ 'logistics.land' | translate }}
                <p-sortIcon [field]="'land'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'CAF'">CAF
                <p-sortIcon [field]="'CAF'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'BAF'">BAF
                <p-sortIcon [field]="'BAF'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'LSS'">LSS
                <p-sortIcon [field]="'LSS'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'customs'">{{ 'logistics.customs' | translate }}
                <p-sortIcon [field]="'customs'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'THC'">THC
                <p-sortIcon [field]="'THC'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'ISPS'">ISPS
                <p-sortIcon [field]="'ISPS'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'IMO'">IMO
                <p-sortIcon [field]="'IMO'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'validFrom'">{{ 'base.validFrom' | translate }}
                <p-sortIcon [field]="'validFrom'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'validUntil'">{{ 'base.validUntil' | translate }}
                <p-sortIcon [field]="'validUntil'"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-cost>
        <tr [pContextMenuRow]="cost" (dblclick)="onRowDblclick($event, cost)">
            <td>{{ cost.time }}</td>
            <td class="price">{{ cost.sea | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td class="price">{{ cost.land | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td class="price">{{ cost.CAF | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td class="price">{{ cost.BAF | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td class="price">{{ cost.LSS | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td class="price">{{ cost.customs | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td class="price">{{ cost.THC | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td class="price">{{ cost.ISPS | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td class="price">{{ cost.IMO | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
            <td>
                <djc-date [timeInput]="cost.validFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date>
            </td>
            <td>
                <djc-date [timeInput]="cost.validUntil" [format]="_auth.getUserSettings().dateFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cmCosts [model]="_contextMenu" appendTo="body"></p-contextMenu>
<div *ngIf="_showDetails">
    <p-dialog [header]="_recordTitle" [visible]="_showDetails" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()" appendTo="body">
        <p-tabView effect="fade" [styleClass]="'pTabView'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'logistics.time' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedCost.time }}</div>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'logistics.sea' | translate }}</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedCost.sea | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>CAF</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedCost.CAF | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>LSS</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedCost.LSS | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>ISPS</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedCost.ISPS | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'logistics.customs' | translate }}</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedCost.customs | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'logistics.land' | translate }}</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedCost.land | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>BAF</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedCost.BAF | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>THC</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedCost.THC | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>IMO</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedCost.IMO | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.validFrom' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedCost.validFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.validUntil' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedCost.validUntil" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedCost.creator.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedCost.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedCost.modifier.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedCost.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showDialog">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()" appendTo="body">
        <p-tabView effect="fade" [styleClass]="'pTabView'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_costsForm" novalidate (ngSubmit)="saveCost()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="time">{{ 'logistics.time' | translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="time" formControlName="time" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="1"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="sea">{{ 'logistics.sea' | translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="sea" formControlName="sea" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="land">{{ 'logistics.land' | translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="land" formControlName="land" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="CAF">CAF</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="CAF" formControlName="CAF" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="BAF">BAF</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="BAF" formControlName="BAF" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="LSS">LSS</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="LSS" formControlName="LSS" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="customs">{{ 'logistics.customs' | translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="customs" formControlName="customs" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="THC">THC</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="THC" formControlName="THC" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="ISPS">ISPS</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="ISPS" formControlName="ISPS" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="IMO">IMO</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="IMO" formControlName="IMO" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="validFrom">{{ 'base.validFrom' | translate}}</label></div>
                        <div class="ui-g-8">
                            <p-calendar [ngClass]="'pCalendar'" id="validFrom" formControlName="validFromDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'" [maxDate]="_costsForm.get('validUntilDate').value"></p-calendar>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="validUntil">{{ 'base.validUntil' | translate}}</label></div>
                        <div class="ui-g-8">
                            <p-calendar [ngClass]="'pCalendar'" id="validUntil" formControlName="validUntilDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'" [minDate]="_costsForm.get('validFromDate').value"></p-calendar>
                        </div>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveCost()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_costsForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
