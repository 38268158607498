import {Injectable} from "@angular/core";
import {Order, OrderProduct} from "./sales.models";

@Injectable()
export class SalesService {
    public selectedOrder: Order;

    setOrder(order: Order) {
        this.selectedOrder = order;
    }

    getLCCost(totalRecord): number {
        return this.selectedOrder.paymentMod.LCCost / totalRecord.quantity;
    }

    getFreightCost(totalRecord): number {
        let freightCost = this.selectedOrder.freightCost;
        let costPriceContainer = freightCost.land + freightCost.sea + freightCost.BAF + freightCost.CAF + freightCost.LSS + freightCost.customs + freightCost.THC + freightCost.ISPS +
            freightCost.IMO;
        let costPrice = costPriceContainer * Math.ceil(totalRecord.quantity / this.selectedOrder.calcContainerVolume);
        return costPrice / totalRecord.quantity;
    }

    getPrice(orderLine: OrderProduct, totalRecord): number {
        let price = orderLine.price.price + orderLine.packaging.price + orderLine.additionalCost + this.getFreightCost(totalRecord);
        return price;
    }


    getCommission(orderLine: OrderProduct, totalRecord): number {
        let commission = 0;
        if (this.selectedOrder.commissionPercentage > 0) {
            //commission = this.getPrice(orderLine, totalRecord) * (this.selectedOrder.commissionPercentage / 100);
            commission = (orderLine.salesPrice * (this.selectedOrder.commissionPercentage / 100)) / orderLine.order.rate.rate;
        } else {
            commission = this.selectedOrder.commissionSolid / totalRecord.quantity;
        }
        return commission;
    }

    getInsurrance(orderLine: OrderProduct, totalRecord): number {
        let insurrance = this.getPrice(orderLine, totalRecord) * (this.selectedOrder.paymentMod.freightInsurrance / 100);
        return insurrance;
    }

    getCredit(orderLine: OrderProduct): number {
        let paymentMod = this.selectedOrder.paymentMod;
        let salesPrice = orderLine.salesPrice / this.selectedOrder.rate.rate;
        let creditCostOne = (salesPrice * paymentMod.percentageOne / 100) * ((paymentMod.interestRate / 100) * (paymentMod.DoCOne / 365));
        let creditCostTwo = (salesPrice * paymentMod.percentageTwo / 100) * ((paymentMod.interestRate / 100) * (paymentMod.DoCTwo / 365));
        let creditCostThree = (salesPrice * paymentMod.percentageThree / 100) * ((paymentMod.interestRate / 100) * (paymentMod.DoCThree / 365));
        let creditCost = creditCostOne + creditCostTwo + creditCostThree;
        return creditCost;
    }

    getCostPrice(orderLine: OrderProduct, totalRecord): number {
        let costPrice = this.getPrice(orderLine, totalRecord) + this.getCommission(orderLine, totalRecord) + this.getInsurrance(orderLine, totalRecord) + this.getLCCost(totalRecord) + this.getCredit(orderLine);
        return costPrice * this.selectedOrder.rate.rate;
    }

    getMargin(orderLine: OrderProduct, totalRecord) {
        return orderLine.salesPrice - this.getCostPrice(orderLine, totalRecord);
    }

    getMarginPercentage(orderLine: OrderProduct, totalRecord) {
        let margin = this.getMargin(orderLine, totalRecord);
        return margin / orderLine.salesPrice;
    }

    convertOrderDates(order: Order) {
        order.offerDateDate = new Date(order.offerDate * 1000);
        order.validToDate = new Date(order.validTo * 1000);
        order.closeDateDate = new Date(order.closeDate * 1000);
        return order;
    }
}

