<p-table #dt [loading]="_loading" [value]="_paymentMods" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5" [styleClass]="'table'"
         [(contextMenuSelection)]="selectedPaymentMod" [contextMenu]="cm" [scrollable]="true" [scrollHeight]="'flex'"
         [sortField]="'type'">
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'description'" rowspan="2">{{ 'base.strDescription' | translate }}
                <p-sortIcon [field]="'description'"></p-sortIcon>
            </th>
            <th colspan="2">{{ 'finance.term' | translate }} 1</th>
            <th colspan="2">{{ 'finance.term' | translate }} 2</th>
            <th colspan="2">{{ 'finance.term' | translate }} 3</th>
            <th [pSortableColumn]="'modifier.fullName'" rowspan="2">{{ 'base.modifier' | translate }}
                <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifiedAt'" rowspan="2">{{ 'base.modified' | translate }}
                <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th>{{ 'finance.percentage' | translate }}</th>
            <th>{{ 'base.days' | translate }}</th>
            <th>{{ 'finance.percentage' | translate }}</th>
            <th>{{ 'base.days' | translate }}</th>
            <th>{{ 'finance.percentage' | translate }}</th>
            <th>{{ 'base.days' | translate }}</th>
        </tr>
        <tr>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'type', 'contains')"></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-mod>
        <tr [pContextMenuRow]="mod" (dblclick)="onRowDblclick($event, mod)">
            <td>{{ mod.description }}</td>
            <td>{{ mod.percentageOne | number:'1.2-2':'nl' }}</td>
            <td>{{ mod.DoCOne}}</td>
            <td>{{ mod.percentageTwo | number:'1.2-2':'nl' }}</td>
            <td>{{ mod.DoCTwo}}</td>
            <td>{{ mod.percentageThree | number:'1.2-2':'nl' }}</td>
            <td>{{ mod.DoCThree}}</td>
            <td>{{ mod.modifier.fullName }}</td>
            <td>
                <djc-date [timeInput]="mod.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="_contextMenu"></p-contextMenu>
<div *ngIf="_showDetails">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDetails" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-lg-12 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-2"><b>{{ 'base.strDescription' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedPaymentMod.description }}</div>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'finance.term' | translate }} 1</b></div>
                            <div class="ui-g-8">{{ selectedPaymentMod.percentageOne | number:'1.2-2':'nl' }} % in {{ selectedPaymentMod.DoCOne }} {{ 'base.days' | translate }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'finance.term' | translate }} 2</b></div>
                            <div class="ui-g-8">{{ selectedPaymentMod.percentageTwo | number:'1.2-2':'nl' }} % in {{ selectedPaymentMod.DoCTwo}} {{ 'base.days' | translate }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'finance.term' | translate }} 3</b></div>
                            <div class="ui-g-8">{{ selectedPaymentMod.percentageThree | number:'1.2-2':'nl' }} % in {{ selectedPaymentMod.DoCThree }} {{ 'base.days' | translate }}</div>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'finance.interestRate' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedPaymentMod.interestRate | number:'1.3-3':'nl' }} %</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'finance.freightInsurance' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedPaymentMod.freightInsurrance | number:'1.3-3':'nl' }} %</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'finance.LCCost' | translate }}</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedPaymentMod.LCCost | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'finance.administrationCost' | translate }}</b></div>
                            <div class="ui-g-8 priceDetail">{{ selectedPaymentMod.administrationCost | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedPaymentMod.creator.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedPaymentMod.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedPaymentMod.modifier.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedPaymentMod.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showDialog">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_paymentModForm" novalidate (ngSubmit)="savePaymentMod()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="description">{{ 'base.strDescription' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="description" formControlName="description"></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="percentageOne">{{ 'finance.term' | translate }} 1</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="percentageOne" formControlName="percentageOne" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="1" max="100" style="width: 50%"></djc-spinner> in
                            <djc-spinner formControlName="DoCOne" min="0" [step]="1" style="width: 50%"></djc-spinner> {{ 'base.days' | translate }}
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="percentageTwo">{{ 'finance.term' | translate }} 1</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="percentageTwo" formControlName="percentageTwo" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="1" max="100" style="width: 50%"></djc-spinner> in
                            <djc-spinner formControlName="DoCTwo" min="0" [step]="1" style="width: 50%"></djc-spinner> {{ 'base.days' | translate }}
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="percentageThree">{{ 'finance.term' | translate }} 1</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="percentageThree" formControlName="percentageThree" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="1" max="100" style="width: 50%"></djc-spinner> in
                            <djc-spinner formControlName="DoCThree" min="0" [step]="1" style="width: 50%"></djc-spinner> {{ 'base.days' | translate }}
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="interestRate">{{ 'finance.interestRate' | translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="interestRate" formControlName="interestRate" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]=".001" max="100"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="freightInsurrance">{{ 'finance.freightInsurance' | translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="freightInsurrance" formControlName="freightInsurrance" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]=".001" max="100"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="LCCost">{{ 'finance.LCCost' | translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="LCCost" formControlName="LCCost" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]=".01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="administrationCost">{{ 'finance.administrationCost' | translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="administrationCost" formControlName="administrationCost" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]=".01"></djc-spinner>
                        </div>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="savePaymentMod()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_paymentModForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
