import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Product} from "../products.models";
import {AuthService} from "../../auth.service";
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "../../api.service";
import {SupportTools} from "../../support.tools";
import {MenuItem, SelectItem, SortMeta} from "primeng/api";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {isNull} from "util";
import {DatePipe} from "@angular/common";
import {OrderProductHistory} from "../../sales/sales.models";

@Component({
    selector: 'productsProducts',
    templateUrl: './products.component.html',
    styleUrls: ['../products.component.css']
})
export class ProductsComponent implements OnInit {
    public products: Product[];
    private _productHistory: OrderProductHistory[] = [];
    private _historyLoading: boolean = false;
    private _historyTitle: string;
    public _showCustomerHistory: boolean = false;
    private _multiSortMeta: SortMeta[];

    public loading: boolean;

    private _reload: boolean;
    private _showActive: boolean;

    @Input() set reload(value: boolean) {
        this._reload = value;
        if (typeof this._showActive == 'undefined') {
            return;
        }
        if (this._reload) {
            this.loadProducts();
        }
    }
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get reload(): boolean {
        return this._reload;
    }

    @Input() set newItem(value: boolean) {
        this._newProduct = value;
        if (this._newProduct) {
            this.selectedProduct = new Product();
            this._productForm.reset();
            this._commentForm.reset();
            this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('products.product'));
            this._showDialog = true;
        }
    }
    @Output() newItemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get newItem(): boolean {
        return this._newProduct;
    }


    @Input() set showActive(value: boolean) {
        this._showActive = value;
        if (this._contextMenu.length > 1) {
            this._transService.get(['base.activate', 'base.deactivate']).subscribe(
                response => {
                    if (this._auth.modules['products'].authLevel >= 40 && this._showActive) {
                        this._contextMenu.pop();
                        this._contextMenu.push({label: response['base.deactivate'], icon: 'fa fa-minus-square-o', command: (event) => this.deactivateProduct()});
                    }
                    if (this._auth.modules['products'].authLevel >= 40 && !this._showActive) {
                        this._contextMenu.pop();
                        this._contextMenu.push({label: response['base.activate'], icon: 'fa fa-plus-square-o', command: (event) => this.activateProduct()});
                    }
                }
            );
        }

        this.loadProducts();
    }

    get showActive(): boolean {
        return this._showActive;
    }


    public selectedProduct: Product;
    public _contextMenu: MenuItem[] = [];
    public _showDetails: boolean = false;
    public _showDialog: boolean = false;
    private _recordTitle: string;
    private _commentTime: string;
    private _newProduct: boolean = false;
    private _packages: SelectItem[] = [];

    private _productForm: FormGroup;
    private _commentForm: FormGroup;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {
    }

    ngOnInit() {
        if (this._auth.modules['products'].authLevel >= 10) {
            this._contextMenu.push({label: this._transService.instant('base.details'), icon: 'fa fa-info-circle', command: (event) => this.detailsProduct()});
            this._contextMenu.push({label: this._transService.instant('base.history'), icon: 'fa fa-history', command: (event) => this.showCustomerHistory()});
        }
        if (this._auth.modules['products'].authLevel >= 20) {
            this._contextMenu.push({label: this._transService.instant('base.edit'), icon: 'fa fa-pencil-square-o', command: (event) => this.editProduct()});
        }
        if (this._auth.modules['products'].authLevel >= 40) {
            this._contextMenu.push({label: this._transService.instant('base.delete'), icon: 'fa fa-minus-square-o', command: (event) => this.deleteProduct()});
            this._contextMenu.push({label: this._transService.instant('base.deactivate'), icon: 'fa fa-minus-square-o', command: (event) => this.deactivateProduct()});
        }
        this._productForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'prefPackagingId': new FormControl('', Validators.required),
            'prefPackaging': new FormControl({value: '', disabled: true}),
            'code': new FormControl('', Validators.required),
            'comment': new FormControl(''),
            'description': new FormControl('', Validators.required),
            'IMO': new FormControl(''),
            'EHF': new FormControl(''),
            'minimumQuantity': new FormControl({value: '', disabled: true}),
            'activeUntil': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });
        this._commentForm = this._fb.group({
            'comment': new FormControl('')
        });
        this.loadPackages();
    }

    loadPackages() {
        this._apiService.setActionUrl('products/packages');
        this._apiService.setSort({field: 'description', direction: 'ASC'});
        this._apiService.setFilter({field: 'activeUntil', operator: 'gt', value: this._st.getDateValue()});
        this._apiService.getDropDown(JSON.stringify(['description'])).subscribe(
            response => {
                if (response.success) {
                    this._packages = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    cancel() {
        this._showDialog = false;
        this._showDetails = false;
        this._recordTitle = '';
        this.selectedProduct = null;
        if (this._newProduct) {
            this._newProduct = false;
            this.newItemChange.emit(this._newProduct);
        }
        this._productForm.reset();
        this._commentForm.reset();
    }

    detailsProduct() {
        this._recordTitle = this.selectedProduct.code + ' - ' + this.selectedProduct.description;
        this._showDetails = true;
    }

    editProduct() {
        this._recordTitle = this.selectedProduct.code + ' - ' + this.selectedProduct.description;
        this._productForm.setValue(this.selectedProduct as any);
        this._commentForm.reset();
        this._showDialog = true;
        this._commentForm.reset();
        this._commentTime = this._dp.transform(new Date(), this._auth.getUserSettings().dateFormat);

    }

    createProduct() {
        this.selectedProduct = new Product();
        this._newProduct = true;
        this._productForm.reset();
        this._commentForm.reset();
        this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('products.product'));
        this._commentTime = this._dp.transform(new Date(), this._auth.getUserSettings().dateFormat);
        this._showDialog = true;
    }

    deleteProduct() {
        this._apiService.setActionUrl('products/products');
        this._apiService.deleteRecord(this.selectedProduct.id).subscribe(
            response => {
                if (response.success) {
                    this.selectedProduct = null;
                    this.loadProducts();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    deactivateProduct() {
        this.selectedProduct.activeUntil = this._st.getDateValue();
        this._apiService.setActionUrl('products/products');
        this._apiService.updateRecord(JSON.stringify(this.selectedProduct)).subscribe(
            response => {
                if (response.success) {
                    this.loadProducts();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    activateProduct() {
        let newDate = new Date();
        newDate.setFullYear(2099,12,31);
        newDate.setHours(0,0,0, 0);
        this.selectedProduct.activeUntil = newDate.getTime() / 1000;
        this._apiService.setActionUrl('products/products');
        this._apiService.updateRecord(JSON.stringify(this.selectedProduct)).subscribe(
            response => {
                if (response.success) {
                    this.loadProducts();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    loadProducts() {
        this.loading = true;
        this._apiService.setActionUrl('products/products');
        this._apiService.setSort({field: 'code', direction: 'ASC'});
        if (this._showActive) {
            this._apiService.setFilter({field: 'activeUntil', operator: 'gt', value: this._st.getDateValue()});
        } else {
            this._apiService.setFilter({field: 'activeUntil', operator: 'le', value: this._st.getDateValue()});
        }
        this._apiService.getStore().subscribe(
            response => {
                this._reload = false;
                this.reloadChange.emit(this._reload);
                if (response.success) {
                    this.products = response.data.records;
                    this.loading = false;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    loadDetails() {
        this._apiService.setActionUrl('products/products');
        this._apiService.setFilter({field: 'id', value: this.selectedProduct.id, operator: 'eq'});
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    this.selectedProduct = response.data.records[0];
                }
            }
        );
    }

    saveProduct() {
        this.selectedProduct = this._productForm.getRawValue();
        let newComment:string = this._commentForm.get('comment').value || '';
        if (newComment.length > 0) {
            let oldComment = this.selectedProduct.comment;
            if (isNull(oldComment)) {
                this.selectedProduct.comment = this._auth.getUser().userName + ' ' + this._commentTime + "\n" + newComment + "\n";
            } else {
                this.selectedProduct.comment = this._auth.getUser().userName + ' ' + this._commentTime + "\n" + newComment + "\n" + oldComment;
            }
        }

        this._apiService.setActionUrl('products/products');
        if (this._newProduct) {
            this._apiService.createRecord(JSON.stringify(this.selectedProduct)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedProduct)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    private _afterSave(response) {
        if (response.success) {
            this.cancel();
            this.selectedProduct = response.data.records[0];
            this.detailsProduct();
            this.loadProducts();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    onRowDblclick(event, product) {
        this.selectedProduct = product;
        this.loadDetails();
        this.detailsProduct();
    }

    showCustomerHistory() {
        this._apiService.setActionUrl('products/prices');
        this._historyTitle = this._transService.instant('base.history') + ' ' + this.selectedProduct.description;
        this._historyLoading = true;
        this._showCustomerHistory = true;
        this._apiService.setFilter({field: 'productId', operator: 'eq', value: this.selectedProduct.id});
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    let priceIds = [];
                    response.data.records.forEach(record => {
                        priceIds.push(record.id);
                    })
                    this._apiService.setActionUrl('sales/orderproducts');
                    this._apiService.setFilter({field: 'priceId', operator: 'IN', value: priceIds});
                    this._apiService.getStore().subscribe(
                        response => {
                            this._historyLoading = false;
                            this._productHistory = response.data.records;
                            this._productHistory.forEach(function(product) {
                                if (product.supplier) {
                                    product.extraString = product.product.description + ' - ' + product.supplier.name;
                                } else {
                                    product.extraString = product.product.description + ' - Geen leverancier';
                                }
                            });
                        }
                    )
                }
            }
        )

        this._multiSortMeta = [];
        this._multiSortMeta.push({
            field: 'order.offerDate',
            order: -1
        });
        this._multiSortMeta.push({
            field: 'order.champrixRef',
            order: 1
        });

    }

    cancelHistory() {
        this._showCustomerHistory = false;
        this._productHistory = [];
    }

}
