import {NgModule} from "@angular/core";
import {SharedModule} from "../shared.module";
import {AddressbookComponent} from "./addressbook.component";
import {ContactsComponent} from "./contacts.component";
import {CompanyComponent} from "./company.component";

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        AddressbookComponent,
        CompanyComponent,
        ContactsComponent
    ],
    exports: [
        CompanyComponent,
        ContactsComponent
    ]
})
export class AddressbookModule {}
