import {Component, Input, OnInit} from "@angular/core";
import {Freight, FreightCost} from "./logistics.models";
import {MenuItem} from "primeng/api";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {DatePipe} from "@angular/common";
import {ApiService} from "../api.service";

@Component({
    selector: 'freightCosts',
    templateUrl: './costs.component.html',
    styleUrls: ['./logistics.component.css']
})
export class CostsComponent implements OnInit {

    @Input() selectedFreight: Freight;

    public _loading: boolean = false;
    public _contextMenu: MenuItem[] = [];
    public _showDetails: boolean = false;
    public _showDialog: boolean = false;
    private _recordTitle: string;
    private _newCost: boolean = false;

    private _costsForm: FormGroup;

    public _costs: FreightCost[] = [];
    public selectedCost: FreightCost;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {}

    ngOnInit() {
        this._costsForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'freightId': new FormControl({value: '', disabled: true}),
            'freight': new FormControl({value: '', disabled: true}),
            'time': new FormControl('', Validators.required).setValue(0),
            'sea': new FormControl('', Validators.required).setValue(0),
            'land': new FormControl('', Validators.required).setValue(0),
            'CAF': new FormControl('', Validators.required).setValue(0),
            'BAF': new FormControl('', Validators.required).setValue(0),
            'LSS': new FormControl('', Validators.required).setValue(0),
            'customs': new FormControl('', Validators.required).setValue(0),
            'THC': new FormControl('', Validators.required).setValue(0),
            'ISPS': new FormControl('', Validators.required).setValue(0),
            'IMO': new FormControl('', Validators.required).setValue(0),
            'validFrom': new FormControl({value: '', disabled: true}),
            'validFromDate': new FormControl('', Validators.required).setValue(new Date()),
            'validUntil': new FormControl({value: '', disabled: true}),
            'validUntilDate': new FormControl('', Validators.required),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        })
        this._transService.get(['base.edit', 'base.delete', 'base.details']).subscribe(
            response => {
                this._contextMenu.push({label: response['base.details'], icon: 'fa fa-info-circle', command: (event) => this.detailsCost()});
                if (this._auth.modules['logistics'].authLevel >= 20) {
                    this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editCost()});
                }
                if (this._auth.modules['logistics'].authLevel >= 40) {
                    //this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteCost()});
                }
            }
        );

        this.loadCosts();
    }

    loadCosts() {
        this._apiService.setActionUrl('logistics/costs');
        this._apiService.setSort({field: 'validFrom', direction: 'DESC'});
        this._apiService.setFilter({field: 'freightId', operator: 'eq', value: this.selectedFreight.id});
        this._apiService.getStore().subscribe(
            response => {
                this._loading = false;
                if (response.success) {
                    this._costs = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    detailsCost() {
        this._showDetails = true;
        this._recordTitle = this.selectedFreight.departure + ' - ' + this.selectedFreight.destination;
    }

    editCost() {
        this._showDialog = true;
        this._recordTitle = this.selectedFreight.departure + ' - ' + this.selectedFreight.destination;
        this.selectedCost.validFromDate = new Date(this.selectedCost.validFrom * 1000);
        this.selectedCost.validUntilDate = new Date(this.selectedCost.validUntil * 1000);
        this._costsForm.setValue(this.selectedCost as any);
    }

    createCost() {
        this._newCost = true;
        this._costsForm.reset();
        this._costsForm.controls['time'].setValue(0);
        this._costsForm.controls['sea'].setValue(0);
        this._costsForm.controls['land'].setValue(0);
        this._costsForm.controls['CAF'].setValue(0);
        this._costsForm.controls['BAF'].setValue(0);
        this._costsForm.controls['LSS'].setValue(0);
        this._costsForm.controls['customs'].setValue(0);
        this._costsForm.controls['THC'].setValue(0);
        this._costsForm.controls['ISPS'].setValue(0);
        this._costsForm.controls['IMO'].setValue(0);
        this._costsForm.controls['validFromDate'].setValue(new Date());
        this.selectedCost = new FreightCost();
        this.selectedCost.freight = this.selectedFreight;
        this.selectedCost.validFromDate = new Date();
        this.selectedCost.validUntilDate = new Date();
        this._showDialog = true;
    }

    saveCost() {
        this.selectedCost = this._costsForm.getRawValue();
        this.selectedCost.validFromDate.setHours(0,0,0,0);
        this.selectedCost.validUntilDate.setHours(23,59,59,999);
        this.selectedCost.validFrom = Math.floor(this.selectedCost.validFromDate.getTime() / 1000);
        this.selectedCost.validUntil = Math.floor(this.selectedCost.validUntilDate.getTime() / 1000);
        this._apiService.setActionUrl('logistics/costs');
        if (this._newCost) {
            this.selectedCost.freightId = this.selectedFreight.id;
            this._apiService.createRecord(JSON.stringify(this.selectedCost)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedCost)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    private _afterSave(response) {
        if (response.success) {
            this.cancel();
            this.selectedCost = response.data.records[0];
            this.detailsCost();
            this.loadCosts();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    deleteCost() {
        this._apiService.setActionUrl('logistics/costs');
        this._apiService.deleteRecord(this.selectedCost.id).subscribe(
            response => {
                if (response.success) {
                    this.loadCosts();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            }
        );
    }

    cancel() {
        this._showDialog = false;
        this._showDetails = false;
        this._newCost = false;
        this.selectedCost = null;
        this._costsForm.reset();
    }

    onRowDblclick(event, cost) {
        this.selectedCost = cost;
        this.detailsCost();
    }
}
