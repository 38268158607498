import {Country} from "../base/base.model";
import {PaymentModality} from "../finance/finance.model";

export class Addressbook {
    id: string;
    name: string;
    aclItemId: string;
    aclLevel: number;
}

export class Company {
    id: string;
    countryId: string;
    country: Country;
    prefPaymentModalityId: string;
    prefPaymentMod: PaymentModality;
    addressBookId: string;
    addressbook: Addressbook;
    name: string;
    creditInsured: boolean;
    type: string;
    typeDescription: string;
    street: string;
    zipCode: string;
    city: string;
    debtorCode: string;
    modifiedAt: number;
    modifier: { fullName: string };
    createdAt: number;
    creator: { fullName: string };
    softDeleted: boolean;
}

export class Contact {
    id: string;
    companyId: string;
    company: Company;
    firstName: string;
    lastName: string;
    comment: string;
    telephone: string;
    telephoneSecond: string;
    mobilePhone: string;
    email: string;
    emailSecond: string;
    position: string;
    type: string;
    modifiedAt: number;
    modifier: { fullName: string };
    createdAt: number;
    creator: { fullName: string };
    softDeleted: boolean;
}