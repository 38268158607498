import {Component, Input, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../auth.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SupportTools} from "../support.tools";
import {DatePipe} from "@angular/common";
import {ApiService, responseModel} from "../api.service";
import {Currency, CurrencyRate} from "./finance.model";
import {MenuItem} from "primeng/api";

@Component({
    selector: 'currencyRates',
    templateUrl: './rates.component.html',
    styleUrls: ['./finance.component.css']
})
export class RatesComponent implements OnInit {

    @Input() selectedCurrency: Currency;

    public _loading: boolean;

    public _contextMenu: MenuItem[] = [];
    public _showDetails: boolean = false;
    public _showDialog: boolean = false;
    private _recordTitle: string;
    private _newRate: boolean = false;

    private _rateForm: FormGroup;

    public _rates: CurrencyRate[] = [];
    public selectedRate: CurrencyRate;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe) {
    }

    ngOnInit() {
        this._transService.get(['base.edit', 'base.delete', 'base.details']).subscribe(
            response => {
                this._contextMenu.push({label: response['base.details'], icon: 'fa fa-info-circle', command: (event) => this.detailsRate()});
                if (this._auth.modules['finance'].authLevel >= 20) {
                    this._contextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editRate()});
                }
                if (this._auth.modules['finance'].authLevel >= 40) {
                    //this._contextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteRate()});
                }
            }
        );
        this._rateForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'currencyId': new FormControl({value: '', disabled: true}),
            'currency': new FormControl({value: '', disabled: true}),
            'rate': new FormControl('', Validators.required),
            'validFrom': new FormControl({value: '', disabled: true}),
            'validFromDate': new FormControl('', Validators.required),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });
        this.loadRates();
    }

    loadRates() {
        this._loading = true;
        this._apiService.setActionUrl('finance/currencyrates');
        this._apiService.setSort({field: 'validFrom', direction: 'DESC'});
        this._apiService.setFilter({field: 'currencyId', operator: 'eq', value: this.selectedCurrency.id});
        this._apiService.getStore().subscribe(
            response => {
                this._loading = false;
                if (response.success) {
                    this._rates = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    detailsRate() {
        this._showDetails = true;
        this._recordTitle = this.selectedCurrency.type;
    }

    editRate() {
        this.selectedRate.validFromDate = new Date(this.selectedRate.validFrom * 1000);
        this._rateForm.setValue(this.selectedRate as any);
        this._recordTitle = this.selectedCurrency.type;
        this._showDialog = true;
    }

    createRate() {
        this.selectedRate = new CurrencyRate();
        this._rateForm.reset();
        this._newRate = true;
        this._showDialog = true;
    }

    saveRate() {
        this._apiService.setActionUrl('finance/currencyrates');
        this.selectedRate = this._rateForm.getRawValue();
        this.selectedRate.validFromDate.setHours(0,0,0,0);
        this.selectedRate.validFrom = Math.floor(this.selectedRate.validFromDate.getTime() / 1000);
        if (this._newRate) {
            this.selectedRate.currencyId = this.selectedCurrency.id;
            this._apiService.createRecord(JSON.stringify(this.selectedRate)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedRate)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    cancel() {
        this._showDialog = false;
        this._showDetails = false;
        this._newRate = false;
        this.selectedRate = null;
    }

    private _afterSave(response: responseModel) {
        if (response.success) {
            this.cancel();
            this.loadRates();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    onRowDblclick(event, rate) {
        this.selectedRate = rate;
        this.detailsRate();
    }
}
