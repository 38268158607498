import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Order, OrderProduct} from "./sales.models";
import {ApiService, responseModel} from "../api.service";
import {AuthService} from "../auth.service";
import {SupportTools} from "../support.tools";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {SalesService} from "./sales.service";
// @ts-ignore
import pdfMake from "pdfmake/build/pdfmake";
// @ts-ignore
import pdfFonts from "pdfmake/build/vfs_fonts";
import {MenuItem, SelectItem} from "primeng/api";
import {ProductPrice} from "../products/products.models";
import {Company} from "../addressbook/addressbook.model";
import {Currency} from "../finance/finance.model";

@Component({
    selector: 'order',
    templateUrl: 'order.component.html',
    styleUrls: ['sales.component.css']
})
export class OrderComponent implements OnInit {

    public _allLoaded: boolean = false;
    private _canvasImage: string;
    public _commentTime: string;
    public _contextMenuOl: MenuItem[] = [];
    public _currentFC: number = 0;
    public _dirtyOrder: boolean = false;
    private _loadingDestinations: boolean = false;
    private _loadingOl: boolean = false;
    private _loadingProducts: boolean = false;
    private _loadingRates: boolean = false;
    public _destinationDropDown: SelectItem[] = [];
    public _dirtyOder: boolean = false;
    private _dirtyOrderLines: any = [];
    public _incoTermsDropDown: SelectItem[] = [];
    public _loading: boolean = false;
    public _newComment: string = '';
    public _newOrder: boolean = false;
    public _newOrderLine: boolean;
    private _nuSanaImage: string;
    public _orderForm: FormGroup;
    public _orderLineForm: FormGroup;
    public _orderLines: OrderProduct[] = [];
    public _packagesDropDown: SelectItem[] = [];
    public _paymentModDropDown: SelectItem[] = [];
    public _productsDropDown: SelectItem[] = [];
    public _ratesDropDown: SelectItem[] = [];
    public _rows: number;
    public _usersDropDown: SelectItem[] = [];
    public _recordTitle: string;
    public _recordTitleOl: string;
    public _showDetails: boolean = false;
    public _showDialog: boolean = false;
    public _showDetailsOl: boolean = false;
    public _showDialogOl: boolean = false;
    public _showISC: boolean = false;
    public _totalRecord: any = {additionalCost: 0, costPrice: 0, quantity: 0, salesPrice: 0, maxLineNumber: 0};
    public _warningShowed: boolean = false;
    public selectedOrderLine: OrderProduct;
    public selectedPrice: ProductPrice;

    @Input() orderType: string;

    @Input() displayCustomer: boolean = false;

    @Input() selectedCompany: Company;

    @Input() selectedOrder: Order;

    @Input() set newOrder(value: boolean) {
        this._newOrder = value;
    }

    @Input() set type(value: string) {
        switch (value) {
            case 'orderDetails':
                this._showDetails = true;
                this.loadOrderLines();
                break;
            case 'orderDialog':
                this._showDialog = true;
                break;
            case 'orderLineDetails':
                this._showDetailsOl = true;
                break;
            case 'orderLineDialog':
                this._showDialogOl = true;
                break;
            case 'ISC':
                this._showISC = true;
                break;
        }
    }

    @Input() _visible: boolean;

    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() orderChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private _apiService: ApiService, public _auth: AuthService, public _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe, public _salesService: SalesService, private _cp: CurrencyPipe, private _decPipe: DecimalPipe) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    ngOnInit() {
        // Build IncoTerms dropdown
        this._incoTermsDropDown.push({label: 'Cost, Insurance, Freight', value: 'CIF'});
        this._incoTermsDropDown.push({label: 'Free on Board', value: 'FOB'});
        this._incoTermsDropDown.push({label: 'Cost Freight', value: 'CFR'});
        this._incoTermsDropDown.push({label: 'Delivery at Place', value: 'DAP'});
        this._incoTermsDropDown.push({label: 'Ex Works', value: 'EXW'});
        this._incoTermsDropDown.push({label: 'Delivery at Terminal', value: 'DAT'});
        this._incoTermsDropDown.push({label: 'Free Carrier', value: 'FCA'});

        // Build contextmenu Orderlines
        this._contextMenuOl.push({label: this._transService.instant('base.details'), icon: 'fa fa-info-circle', command: () => this.detailsOrderLine()});
        if (this.orderType === 'N') {
            if (this._auth.modules['sales'].authLevel >= 30) {
                this._contextMenuOl.push({label: this._transService.instant('base.edit'), icon: 'fa fa-pencil-square-o', command: () => this.editOrderLine()});
            }
            if (this._auth.modules['sales'].authLevel >= 40) {
                this._contextMenuOl.push({label: this._transService.instant('base.delete'), icon: 'fa fa-minus-square-o', command: () => this.deleteOrderLine()});
            }
        }

        if (this.displayCustomer) {
            this._rows = this._auth.defaultRows;
        } else {
            this._rows = 20;
        }

        this._orderForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'companyId': new FormControl({value: '', disabled: true}),
            'company': new FormControl({value: '', disabled: true}),
            'country': new FormControl({value: '', disabled: true}),
            'containerSize': new FormControl({value: '', disabled: true}),
            'currencyRateId': new FormControl('', Validators.required),
            'rate': new FormControl({value: '', disabled: true}),
            'currency': new FormControl({value: '', disabled: true}),
            'freightCostId': new FormControl('', Validators.required),
            'freightCost': new FormControl({value: '', disabled: true}),
            'freight': new FormControl({value: '', disabled: true}),
            'parentOrderId': new FormControl({value: '', disabled: true}),
            'orderVersion': new FormControl({value: '', disabled: true}),
            'paymentModalityId': new FormControl('', Validators.required),
            'paymentMod': new FormControl({value: '', disabled: true}),
            'userId': new FormControl('', Validators.required),
            'user': new FormControl({value: '', disabled: true}),
            'salesMan': new FormControl({value: '', disabled: true}),
            'offerDate': new FormControl({value: '', disabled: true}),
            'offerDateDate': new FormControl('', Validators.required),
            'closeDate': new FormControl({value: '', disabled: true}),
            'closeDateDate': new FormControl('', Validators.required),
            'validTo': new FormControl({value: '', disabled: true}),
            'validToDate': new FormControl('', Validators.required),
            'champrixRef': new FormControl('', Validators.required),
            'status': new FormControl('', Validators.required),
            'statusDescription': new FormControl({value: '', disabled: true}),
            'calcContainerVolume': new FormControl(0, Validators.required),
            'commissionSolid': new FormControl(0, Validators.required),
            'commissionPercentage': new FormControl(0, Validators.required),
            'incoTerms': new FormControl('', Validators.required),
            'comment': new FormControl(''),
            'newComment': new FormControl(''),
            'bookDeparture': new FormControl({value: '', disabled: true}),
            'purchase': new FormControl({value: '', disabled: true}),
            'reserveProductionCapacity': new FormControl({value: '', disabled: true}),
            'sendConfirmation': new FormControl({value: '', disabled: true}),
            'calculatedMargin': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });

        this._orderLineForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'orderId': new FormControl({value: '', disabled: true}),
            'order': new FormControl({value: '', disabled: true}),
            'priceId': new FormControl('', Validators.required),
            'price': new FormControl({value: '', disabled: true}),
            'product': new FormControl({value: '', disabled: true}),
            'supplier': new FormControl({value: '', disabled: true}),
            'packagingId': new FormControl('', Validators.required),
            'packaging': new FormControl({value: '', disabled: true}),
            'additionalCost': new FormControl(0, Validators.required),
            'quantity': new FormControl(0, Validators.required),
            'salesPrice': new FormControl(0, Validators.required),
            'lineNumber': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'newProduct': new FormControl(''),
            'productChange': new FormControl(''),
            'productChangeDescription': new FormControl(''),
            'packageChange': new FormControl(''),
            'packageChangeDescription': new FormControl(''),
            'labelChange': new FormControl(''),
            'labelChangeDescription': new FormControl(''),
            'registeredProduct': new FormControl(''),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });

        let image = document.getElementById('logoContent') as HTMLImageElement;
        this._st.toDataUrl(image.src, function (scope, dataURL) {
            scope._canvasImage = dataURL;
        }, this);
        image = document.getElementById('nuSanaContent') as HTMLImageElement;
        this._st.toDataUrl(image.src, function (scope, dataURL) {
            scope._nuSanaImage = dataURL;
        }, this);
        this.selectedOrderLine = null;
        this.getSalesMan();
        this.getPaymentMod();

        if (this._newOrder && this._showDialog) {
            this.createOrder();
        } else if(!this._newOrder && this._showDialog) {
            this.editOrder();
        } else {
            this.detailsOrder();
        }

    }

    createOrderLine() {
        this._recordTitleOl = this.selectedCompany.name + ' - ' + this.selectedOrder.champrixRef + ' - ' + this._transService.instant('base.new');
        this._showDialogOl = true;
        this.selectedOrderLine = new OrderProduct();
        this.selectedOrderLine.salesPrice = 0;
        this.selectedOrderLine.quantity = 0;
        this._newOrderLine = true;
        this._orderLineForm.reset();
        this._orderLineForm.controls['orderId'].setValue(this.selectedOrder.id);
        this._orderLineForm.controls['lineNumber'].setValue(this._totalRecord.maxLineNumber + 1);
        this._orderLineForm.controls['salesPrice'].setValue(0);
    }

    getRates() {
        this._loadingRates = true;
        this._ratesDropDown = [];
        this._apiService.setActionUrl('finance/currencyrates');
        this._apiService.setSort({field: 'currencyId', direction: 'ASC'});
        this._apiService.setSort({field: 'validFrom', direction: 'DESC'});
        this._apiService.setFilter({field: 'validFrom', operator: 'le', value: this.selectedOrder.offerDate});
        this._apiService.getDropDown('currency.type').subscribe(
            response => {
                this._loadingRates = false;
                this.checkAllLoaded();
                if (response.success) {
                    this._ratesDropDown = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    getSalesMan() {
        if (this._usersDropDown.length > 0) {
            return;
        }
        this._apiService.setActionUrl('users/users');
        this._apiService.getDropDown(JSON.stringify(['fullName'])).subscribe(
            response => {
                if (response.success) {
                    this._usersDropDown = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    getPaymentMod() {
        if (this._paymentModDropDown.length > 0) {
            return;
        }
        this._apiService.setActionUrl('finance/paymentmodalities');
        this._apiService.getDropDown(JSON.stringify(['description'])).subscribe(
            response => {
                if (response.success) {
                    this._paymentModDropDown = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    getDestinations() {
        this._loadingDestinations = true;
        this._destinationDropDown = [];
        this._apiService.setActionUrl('logistics/costs');
        this._apiService.setSort({field: 'freightId', direction: 'ASC'});
        this._apiService.setSort({field: 'validFrom', direction: 'DESC'});
        this._apiService.setFilter({field: 'validFrom', operator: 'le', value: this.selectedOrder.offerDate});
        this._apiService.setFilter({field: 'validUntil', operator: 'ge', value: this.selectedOrder.offerDate});
        this._apiService.setFilter({field: 'validUntil', operator: 'ge', value: this.selectedOrder.offerDate});
        this._apiService.setExtraParams('offerDate', this.selectedOrder.offerDate);
        // Removed countryId from filter because of freedom to choose comparable route by sales when route is not available
        //this._apiService.setFilter({field: 'freight.countryId', operator: 'eq', value: this.selectedCompany.countryId});
        this._apiService.getDropDown('').subscribe(
            response => {
                this._loadingDestinations = false;
                this.checkAllLoaded();
                if (response.success) {
                    this._destinationDropDown = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    _preSaveOrder() {
        this.selectedOrder = this._orderForm.getRawValue();
        this._newComment = this._orderForm.get('newComment').value || '';
        if (this._newOrder) {
            this.selectedOrder.status = 'N';
            this.selectedOrder.orderVersion = 1;
        }
        this.saveOrder(true);
    }

    saveOrder(hide: boolean = true, recalcMargin: boolean = true) {
        this._apiService.setActionUrl('sales/orders');
        if (this._newComment.length > 0) {
            let oldComment = this.selectedOrder.comment;
            if (oldComment == null) {
                this.selectedOrder.comment = this._auth.getUser().userName + ' ' + this._commentTime + "\n" + this._newComment + "\n";
            } else {
                this.selectedOrder.comment = this._auth.getUser().userName + ' ' + this._commentTime + "\n" + this._newComment + "\n" + oldComment;
            }
        }
        this._newComment = '';
        this.selectedOrder.offerDate = this.selectedOrder.offerDateDate.getTime() / 1000;
        this.selectedOrder.closeDate = this.selectedOrder.closeDateDate.getTime() / 1000;
        this.selectedOrder.validTo = this.selectedOrder.validToDate.getTime() / 1000;
        if (recalcMargin) {
            this.selectedOrder.calculatedMargin = ((this._totalRecord.salesPrice - this._totalRecord.costPrice) / this._totalRecord.salesPrice) * 100;
        }

        if (this._newOrder) {
            this._apiService.createRecord(JSON.stringify(this.selectedOrder)).subscribe(
                response => {
                    if (hide) {
                        this._afterSave(response);
                        this.orderChange.emit(true);
                    }
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedOrder)).subscribe(
                response => {
                    if (hide) {
                        this._afterSave(response);
                    } else {
                        this.selectedOrder = this._salesService.convertOrderDates(response.data.records[0]);
                        this._salesService.setOrder(this.selectedOrder);
                        this._allLoaded = false;
                        this.loadOrderLines();
                        this.orderChange.emit(true);
                        this.calculateTotalRecord();
                        this.loadProducts();
                        this.getRates();
                        this.getDestinations();
                    }
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    checkAllLoaded() {
        this._allLoaded = !this._loadingOl && !this._loadingDestinations && !this._loadingRates && !this._loadingProducts
    }

    calculateTotalRecord() {
        this._totalRecord = {additionalCost: 0, costPrice: 0, quantity: 0, salesPrice: 0, maxLineNumber: 0, price: 0, containers: 0};
        this._orderLines.forEach(function (orderLine) {
            /**
             * If there is a selectedOrderline then calculate with the new values, whether saved or not.
             */
            if (!(this.selectedOrderLine == null)) {
                if (orderLine.id !== this.selectedOrderLine.id) {
                    this._totalRecord.additionalCost = this._totalRecord.additionalCost + (orderLine.additionalCost * orderLine.quantity);
                    this._totalRecord.salesPrice = this._totalRecord.salesPrice + (orderLine.salesPrice * orderLine.quantity);
                    this._totalRecord.quantity = this._totalRecord.quantity + orderLine.quantity;
                    if (this._totalRecord.maxLineNumber < orderLine.lineNumber) {
                        this._totalRecord.maxLineNumber = orderLine.lineNumber;
                    }
                    this._totalRecord.price = this._totalRecord.price + (orderLine.price.price * orderLine.quantity);
                } else {
                    this._totalRecord.additionalCost = this._totalRecord.additionalCost + (this.selectedOrderLine.additionalCost * this.selectedOrderLine.quantity);
                    this._totalRecord.salesPrice = this._totalRecord.salesPrice + (this.selectedOrderLine.salesPrice * this.selectedOrderLine.quantity);
                    this._totalRecord.quantity = this._totalRecord.quantity + this.selectedOrderLine.quantity;
                    this._totalRecord.price = this._totalRecord.price + (this.selectedOrderLine.price.price * this.selectedOrderLine.quantity);
                    if (this._totalRecord.maxLineNumber < this.selectedOrderLine.lineNumber) {
                        this._totalRecord.maxLineNumber = this.selectedOrderLine.lineNumber;
                    }
                }
            } else {
                this._totalRecord.additionalCost = this._totalRecord.additionalCost + (orderLine.additionalCost * orderLine.quantity);
                this._totalRecord.salesPrice = this._totalRecord.salesPrice + (orderLine.salesPrice * orderLine.quantity);
                this._totalRecord.quantity = this._totalRecord.quantity + orderLine.quantity;
                if (this._totalRecord.maxLineNumber < orderLine.lineNumber) {
                    this._totalRecord.maxLineNumber = orderLine.lineNumber;
                }
                this._totalRecord.price = this._totalRecord.price + (orderLine.price.price * orderLine.quantity);
            }
        }, this);
        // Costprice can only be calculated after calculating other totals
        this._orderLines.forEach(function (orderLine) {
            if (!(this.selectedOrderLine == null)) {
                if (orderLine.id !== this.selectedOrderLine.id) {
                    this._totalRecord.costPrice = this._totalRecord.costPrice + (this._salesService.getCostPrice(orderLine, this._totalRecord) * orderLine.quantity);
                } else {
                    this._totalRecord.costPrice = this._totalRecord.costPrice + (this._salesService.getCostPrice(this.selectedOrderLine, this._totalRecord) * this.selectedOrderLine.quantity);
                }
            } else {
                this._totalRecord.costPrice = this._totalRecord.costPrice + (this._salesService.getCostPrice(orderLine, this._totalRecord) * orderLine.quantity);
            }
        }, this);
        this._totalRecord.containers = Math.ceil(this._totalRecord.quantity / this.selectedOrder.calcContainerVolume);
    }

    cancel() {
        this._showDialog = false;
        this._showDetails = false;
        this._showDetailsOl = false;
        this._showDialogOl = false;
        this._showISC = false;
        this._visible = false;
        this.visibleChange.emit(false);
    }

    loadISC() {
        this._showISC = true;
    }

    loadOrderLines() {
        this._loadingOl = true;
        this._dirtyOrderLines = [];
        this._apiService.setActionUrl('sales/orderproducts');
        this._apiService.setFilter({field: 'orderId', operator: 'eq', value: this.selectedOrder.id});
        this._apiService.setSort({field: 'lineNumber', direction: 'ASC'});
        this._apiService.getStore().subscribe(
            response => {
                this._loadingOl = false;
                this.checkAllLoaded();
                if (response.success) {
                    this._orderLines = response.data.records;
                    this.calculateTotalRecord();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    loadProducts() {
        this._loadingProducts = true;
        this._apiService.setActionUrl('products/prices');
        this._apiService.setSort({field: 'productId', direction: 'ASC'});
        this._apiService.setSort({field: 'validFrom', direction: 'DESC'});
        this._apiService.setSort({field: 'createdAt', direction: 'DESC'});
        this._apiService.setFilter({field: 'validFrom', operator: 'le', value: this.selectedOrder.offerDate});
        this._apiService.setFilter({field: 'validUntil', operator: 'ge', value: this.selectedOrder.offerDate});
        this._apiService.setExtraParams('offerDate', this.selectedOrder.offerDate);
        this._apiService.getDropDown('').subscribe(
            response => {
                this._loadingProducts = false;
                this.checkAllLoaded();
                if (response.success) {
                    this._productsDropDown = response.data.records;
                    this._auth.highestModObject['sales_products'] = {modSeq: response.data.highModSeq, function: 'loadProducts', url: 'products/products', functionScope: this};
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    getBgColor(margin) {
        let color = 'orange';
        if (margin < 0.08) {
            color = 'red';
        } else if (margin > 0.10) {
            color = 'green';
        }
        return color;
    }

    getBgColorProduct(productPrice: ProductPrice) {
        if (productPrice.validUntil < this.selectedOrder.offerDate) {
            return 'red';
        }
        return '';
    }

    ISCHidden() {
        this._showISC = false;
    }

    printQuotation(totals: boolean, company: string, signature: boolean = false) {
        let scope = this;
        let companyName = company == 'C' ? 'Champrix B.V.' : 'NuSana Int. B.V.';
        let dataURL = company == 'C' ? this._canvasImage : this._nuSanaImage;
        let logoWidth = company == 'C' ? 170 : 100;
        let logoMargin = company == 'C' ? [200, 30, 0, 0] : [235, 30, 0, 0];
        let contact = company == 'C' ? 'Champrix contact' : 'NuSana contact';
        let footerStack = company == 'C' ? [
            'ABN AMRO bank - EURO: IBAN NL28 ABNA 0467 0102 42 or DOLLAR: IBAN NL62 ABNA 0467 0221 43 - BIC: ABNANL2A - BTW/VAT: NL8522.15.605.B01 -',
            'KVK: 56613539',
            'On all business transactions our General Conditions for Sale, deposited at the Chamber of Commerce, apply.'
        ] : [
            'ABN AMRO bank – EURO: IBAN NL57 ABNA 0100 4897 88 or DOLLAR: IBAN NL29 ABNA 0100 8100 04 – BIC: ABNANL2A -  BTW/VAT: NL8621.77.443.B01 – KVK: 81674368',
            'On all business transactions our General Conditions for Sale, deposited at the Chamber of Commerce, apply.'
        ];
        let headerStack = company == 'C' ? [
            {text: companyName, bold: true},
            {text: 'Lombardje 14\n5211 HM \'s-Hertogenbosch\nThe Netherlands\nTel. +31 (0)85 064 05 00\ninfo@champrix.com'}
        ] : [
            {text: companyName, bold: true},
            {text: 'Molenweg 13\n5821 EA Vierlingsbeek\nThe Netherlands\nTel. +31 (0)478 – 76 02 30\ninfo@nusana.nl'}
        ];
        let proposalText = company == 'C' ? 'Champrix product proposal' : 'NuSana product proposal';
        let bodyData = [];
        let bodyWidths;
        if (totals) {
            bodyWidths = [50, 35, 160, 130, 70, 70]
            bodyData.push([
                {text: 'Quantity', bold: true, fillColor: '#eeeeee'},
                {text: 'Code', bold: true, fillColor: '#eeeeee'},
                {text: 'Description', bold: true, fillColor: '#eeeeee'},
                {text: 'Packaging', bold: true, fillColor: '#eeeeee', alignment: 'left'},
                {text: 'Price per MT', bold: true, fillColor: '#eeeeee', alignment: 'right'},
                {text: 'Totals', bold: true, fillColor: '#eeeeee', alignment: 'right'}
            ]);
        } else {
            bodyWidths = [35, 200, 200, 80]
            bodyData.push([
                {text: 'Code', bold: true, fillColor: '#eeeeee'},
                {text: 'Description', bold: true, fillColor: '#eeeeee'},
                {text: 'Packaging', bold: true, fillColor: '#eeeeee', alignment: 'left'},
                {text: 'Price per MT', bold: true, fillColor: '#eeeeee', alignment: 'right'},
            ]);
        }
        this._orderLines.forEach(function (orderLine) {
            let price = this._cp.transform(orderLine.salesPrice, this.selectedOrder.currency.notation, true, '1.2-2', 'nl');
            if (totals) {
                let totalPrice = this._cp.transform(orderLine.quantity * orderLine.salesPrice, this.selectedOrder.currency.notation, true, '1.2-2', 'nl');
                bodyData.push([
                    this._decPipe.transform(orderLine.quantity, '1.3-3', 'nl'),
                    orderLine.product.code,
                    orderLine.product.description,
                    {text: orderLine.packaging.description, alignment: 'left'},
                    {text: this._st.priceFormat(price), alignment: 'right'},
                    {text: this._st.priceFormat(totalPrice), alignment: 'right'}
                ]);
            } else {
                bodyData.push([
                    orderLine.product.code,
                    orderLine.product.description,
                    {text: orderLine.packaging.description, alignment: 'left'},
                    {text: this._st.priceFormat(price), alignment: 'right'}
                ]);
            }
        }, scope)
        if (totals) {
            // push totalrecord
            let price = this._cp.transform(this._totalRecord.salesPrice, this.selectedOrder.currency.notation, true, '1.2-2', 'nl');
            bodyData.push([
                {text: 'Total order value', bold: true, colSpan: 5, fillColor: '#eeeeee'},
                {},
                {},
                {},
                {},
                {text: this._st.priceFormat(price), alignment: 'right', bold: true, fillColor: '#eeeeee'}
            ]);
        }
        let footer = {
            stack: footerStack,
            alignment: 'center',
            fontSize: 7,
            margin: [20, 0, 20, 30]
        };

        let header = {
            columns: [
                {
                    image: dataURL,
                    width: logoWidth,
                    margin: logoMargin
                }, {
                    stack: headerStack,
                    margin: [250, 30, 0, 0],
                    fontSize: 9
                }
            ]
        };

        let body = [];
        body.push(['Customer', {text: this.selectedOrder.company.name, bold: true}]);
        body.push(['Country', this.selectedOrder.country.country]);
        if (this.selectedOrder.company.city.length > 0) {
            body.push(['City', this.selectedOrder.company.city]);
        }
        if (this.selectedOrder.company.zipCode.length > 0) {
            body.push(['Postal Code', this.selectedOrder.company.zipCode]);
        }
        if (this.selectedOrder.company.street.length > 0) {
            body.push(['Street', this.selectedOrder.company.street]);
        }
        body.push(['', '']);
        body.push(['', '']);
        body.push(['Offered date', this._dp.transform(this.selectedOrder.offerDate * 1000, this._auth.getUserSettings().dateFormat)]);
        body.push(['Valid until', this._dp.transform(this.selectedOrder.validTo * 1000, this._auth.getUserSettings().dateFormat)]);
        body.push(['', '']);
        body.push(['', '']);
        body.push([contact, this.selectedOrder.user.fullName]);

        let conditionBody = [];
        let fontSizeConditions = 7;
        let signatureBody = [];
        if (signature) {
            conditionBody.push(['-', {text: 'Costs for Independent Controlling Authority, Laboratory analysis and legalization costs are not included and are for buyers account', fontSize: fontSizeConditions } ]);
            conditionBody.push(['-', {text: 'Prices do not include extra “war risks surcharge”', fontSize: fontSizeConditions } ]);
            conditionBody.push(['-', {text: 'In case of payment term ‘prepayment’ the payment must be received within 5 days of the offered date. After this period price changes may apply', fontSize: fontSizeConditions } ]);
            conditionBody.push(['-', {text: 'Any mandatory conditions which can affect the start of the order process (e.g. import permits) may affect prices, depending on when the relevant conditions are met', fontSize: fontSizeConditions } ]);
            conditionBody.push(['-', {text: 'After agreement to our terms we will start our internal procedure and compliance checks. After this procedure and checks we will provide the final order confirmation', fontSize: fontSizeConditions } ]);

            signatureBody.push([{text: 'Signed by Buyer', bold: true}, {text: 'Signed by Seller', bold: true}]);
            signatureBody.push(['(authorized signatory)', '(authorized signatory)']);
            signatureBody.push(['', '']);
            signatureBody.push(['', '']);
            signatureBody.push([{text: this.selectedOrder.company.name, bold: true}, {text: companyName, bold: true}]);
        } else {
            conditionBody.push([]);
            signatureBody.push([]);
        }

        let content = [
            {
                stack: [
                    {text: proposalText, bold: true, fontSize: 18},
                ],
                margin: [0, 15, 0, 0],
            }, {
                table: {
                    widths: [150, 'auto'],
                    body: body
                },
                layout: 'noBorders',
                fontSize: 9,
                margin: [0, 15, 0, 0]
            }, {
                table: {
                    headerRows: 1,
                    widths: bodyWidths,
                    body: bodyData
                },
                layout: 'noBorders',
                fontSize: 10
            }, {
                table: {
                    widths: [150, 'auto'],
                    body: [
                        ['Payment term', this.selectedOrder.paymentMod.description],
                        ['Incoterms', this.selectedOrder.incoTerms + ' ' + this.selectedOrder.freight.destination],
                        ['Container size', this.selectedOrder.containerSize.name]
                    ]
                },
                layout: 'noBorders',
                fontSize: 9,
                margin: [0, 30, 0, 0]
            }, {
                table: {
                    widths: [10, 'auto'],
                    body: conditionBody
                },
                margin: [0, 10, 0, 0],
                layout: 'noBorders',
                fontSize: fontSizeConditions
            }, {
                table: {
                    widths: ['50%', '50%'],
                    body: signatureBody,
                    heights: 10
                },
                margin: [0, 20, 0, 0],
                layout: 'noBorders',
                fontSize: 10
            }
        ];

        let dd: any = {
            header: header,
            footer: footer,
            pageMargins: [20, 100, 20, 40],
            pageSize: 'A4',
            content: content
        }
        pdfMake.createPdf(dd).download();
    }

    onRowDblclickOrderLine($event, orderLine) {
        this.selectedOrderLine = orderLine;
        this.editOrderLine();
    }

    updateOrderLine(event, orderLine) {
        this.selectedOrderLine = orderLine;
        this._dirtyOrderLines.push(orderLine.id);
        this.calculateTotalRecord();
    }
    _saveOrderDetail() {
        this._dirtyOrderLines.forEach(function (dirtyOrderLineId) {
            this._orderLines.forEach(function (orderLine) {
                if (orderLine.id === dirtyOrderLineId) {
                    this.selectedOrderLine = orderLine;
                    this.saveOrderLine(false);
                }
            }, this);
        }, this);
        this.saveOrder(false);
        this.orderChange.emit(true);
        this._dirtyOrderLines = [];
        this._dirtyOrder = false;
    }

    editOrder() {
        this._recordTitle = this._transService.instant('sales.orderType_' + this.orderType) + ': ' + this.selectedCompany.name + ' - ' + this.selectedOrder.champrixRef;
        this.selectedOrder = this._salesService.convertOrderDates(this.selectedOrder);
        this.selectedOrder.newComment = '';
        this._orderForm.setValue(this.selectedOrder as any);
        this.getRates();
        this.getDestinations();
        this.loadPackages();
        this.loadProducts();
        this._showDialog = true;
        this._commentTime = this._dp.transform(new Date(), this._auth.getUserSettings().dateFormat);
    }

    detailsOrder() {
        this.selectedOrder = this._salesService.convertOrderDates(this.selectedOrder);
        this._recordTitle = this._transService.instant('sales.orderType_' + this.orderType) + ': ' + this.selectedCompany.name + ' - ' + this.selectedOrder.champrixRef;
        this._showDetails = true;
        this._showDialog = false;
        this._salesService.setOrder(this.selectedOrder);
        this._commentTime = this._dp.transform(new Date(), this._auth.getUserSettings().dateFormat);
        this.getRates();
        this.getDestinations();
        this.loadPackages();
        this.loadProducts();
        this.checkAllLoaded();
    }

    createOrder() {
        this.selectedOrder = new Order();
        this.selectedOrder.currency = new Currency();
        this.selectedOrder.offerDate = Math.floor(new Date().getTime() / 1000);
        this.getRates();
        this.getDestinations();
        this.loadPackages();
        this.loadProducts();
        this._orderForm.reset();
        this._orderForm.controls['offerDateDate'].setValue(new Date());
        this._orderForm.controls['paymentModalityId'].setValue(this.selectedCompany.prefPaymentModalityId);
        this._orderForm.controls['companyId'].setValue(this.selectedCompany.id);
        this._orderForm.controls['commissionSolid'].setValue(0);
        this._orderForm.controls['commissionPercentage'].setValue(0);
        this._orderForm.controls['status'].setValue('N');
        this._orderForm.controls['calcContainerVolume'].setValue(1);
        this._orderForm.controls['userId'].setValue((this._auth.getUser().id));
        this._orderForm.controls['validToDate'].setValue(new Date());
        this._orderForm.controls['closeDateDate'].setValue(new Date());
        this._orderForm.controls['incoTerms'].setValue('CIF');
        this._newOrder = true;
        this._recordTitle = this._transService.instant('sales.orderType_N') + ': ' + this.selectedCompany.name + ' - ' + this._transService.instant('base.new');
        this._showDialog = true;
        this._commentTime = this._dp.transform(new Date(), this._auth.getUserSettings().dateFormat);
    }

    detailsOrderLine() {
        this._recordTitleOl = this.selectedCompany.name + ' - ' + this.selectedOrder.champrixRef + ' - ' + this.selectedOrderLine.product.code;
        this._showDetailsOl = true;
    }

    editOrderLine() {
        this._recordTitleOl = this.selectedCompany.name + ' - ' + this.selectedOrder.champrixRef + ' - ' + this.selectedOrderLine.product.code;
        this._showDialogOl = true;
        this.selectedPrice = this.selectedOrderLine.price;
        this._orderLineForm.setValue(this.selectedOrderLine as any);
        this._currentFC = this._salesService.getFreightCost(this._totalRecord);
    }

    deleteOrderLine() {
        this._apiService.setActionUrl('sales/orderproducts');
        this._apiService.deleteRecord(this.selectedOrderLine.id).subscribe(
            response => {
                if (response.success) {
                    this.cancelOl();
                    this.loadOrderLines();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    cancelOl() {
        this._warningShowed = false;
        this._showDialogOl = false;
        this._showDetailsOl = false;
        this._newOrderLine = false;
        this.selectedOrderLine = null;
    }


    loadPackages() {
        this._apiService.setActionUrl('products/packages');
        this._apiService.setSort({field: 'description', direction: 'ASC'});
        this._apiService.setFilter({field: 'activeUntil', operator: 'gt', value: this.selectedOrder.offerDate});
        this._apiService.getDropDown(JSON.stringify(['description'])).subscribe(
            response => {
                if (response.success) {
                    this._packagesDropDown = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    private _afterSave(response: responseModel) {
        if (response.success) {
            this.selectedOrder = response.data.records[0];
            this.selectedOrder.newComment = '';
            this._salesService.setOrder(this.selectedOrder);
            this.detailsOrder();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    updateQuantity() {
        let quantity = this._orderLineForm.get('quantity').value;
        if (this._totalRecord.quantity === 0) {
            this.selectedOrderLine.quantity = 0;
        }
        this._totalRecord.quantity = this._totalRecord.quantity - this.selectedOrderLine.quantity + quantity;
        this._currentFC = this._salesService.getFreightCost(this._totalRecord);
        this.selectedOrderLine.quantity = quantity;
        if (this.selectedOrderLine.quantity < this.selectedPrice.minimumQuantity) {
            this.popUpWarning();
        }
    }

    updateAddCost() {
        this.selectedOrderLine.additionalCost = this._orderLineForm.get('additionalCost').value;
    }

    changedProduct(event) {
        let newPriceId = event.value;
        this._apiService.setActionUrl('products/prices');
        this._apiService.setFilter({field: 'id', operator: 'eq', value: newPriceId});
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    this.selectedPrice = response.data.records[0];
                    this.selectedOrderLine.price = response.data.records[0];
                    this._apiService.setActionUrl(('products/products'));
                    this._apiService.setFilter({field: 'id', operator: 'eq', value: this.selectedOrderLine.price.productId});
                    this._apiService.getStore().subscribe(
                        prodResponse => {
                            if (prodResponse.success) {
                                this.selectedOrderLine.product = prodResponse.data.records[0];
                                this._orderLineForm.controls['packagingId'].setValue(this.selectedOrderLine.product.prefPackagingId);
                                this.changedPackage({value: this.selectedOrderLine.product.prefPackagingId});
                            } else {
                                this._auth.addError(prodResponse.errorMsg);
                            }
                        },
                        prodError => {
                            this._auth.addError(prodError.error.text);
                        }
                    );
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    changedPackage(event) {
        this.selectedOrderLine.packagingId = event.value;
        this._apiService.setActionUrl('products/packages');
        this._apiService.setFilter({field: 'id', operator: 'eq', value: event.value});
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    this.selectedOrderLine.packaging = response.data.records[0];
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    updateSalesPrice() {
        this.selectedOrderLine.salesPrice = this._orderLineForm.get('salesPrice').value;
    }

    public _preSaveOrderLine() {
        this.selectedOrderLine = this._orderLineForm.getRawValue();
        if (this.selectedOrderLine.quantity < this.selectedPrice.minimumQuantity) {
            this.popUpWarning();
        }
        this.saveOrderLine();
    }

    popUpWarning() {
        if (!this._warningShowed) {
            let warnString = 'De ingevoerde hoeveelheid ({0}) is lager dan de minimum bestelhoeveelheid ({1})';
            alert(this._st.stringFormat(warnString, [this.selectedOrderLine.quantity, this.selectedOrderLine.price.minimumQuantity]));
            this._warningShowed = true;
        }
    }

    saveOrderLine(afterActions: boolean = true) {
        // this.selectedOrderLine = this._orderLineForm.getRawValue();
        this._apiService.setActionUrl('sales/orderproducts');
        if (this._newOrderLine) {
            this._totalRecord.maxLineNumber = this.selectedOrderLine.lineNumber;
            this._apiService.createRecord(JSON.stringify(this.selectedOrderLine)).subscribe(
                response => {
                    if (afterActions) {
                        this._afterSaveOrderLine(response);
                    }
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedOrderLine)).subscribe(
                response => {
                    if (afterActions) {
                        this._afterSaveOrderLine(response);
                    }
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    private _afterSaveOrderLine(response) {
        if (response.success) {
            this._warningShowed = false;
            this.cancelOl();
            this.selectedOrderLine = response.data.records[0];
            this.loadOrderLines();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

}
