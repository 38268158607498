import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslateLoader} from '@ngx-translate/core';
import {contentHeaders} from "./support.tools";

export class CustomTransLoader implements TranslateLoader {
    constructor(private _http: HttpClient) {
    }

    getTranslation(lang: string): Observable<any> {
        return this._http.get('api/base/translations/store?language=' + lang, {headers: contentHeaders});
    }
}