import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApiService} from "../../api.service";
import {AuthService} from "../../auth.service";
import {MenuItem} from "primeng/api";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SupportTools} from "../../support.tools";
import {TranslateService} from "@ngx-translate/core";
import {Package} from "../products.models";

@Component({
    selector: 'productPackages',
    templateUrl: './packages.component.html',
    styleUrls: ['../products.component.css']
})
export class PackagesComponents implements OnInit {

    public _packages: Package[] = [];

    public loading: boolean = false;
    public _contextMenu: MenuItem[] = [];
    public _showDetails: boolean = false;
    public _showDialog: boolean = false;
    private _recordTitle: string;
    private _newPackage: boolean = false;

    private _reload: boolean;
    private _showActive: boolean;

    private _packageForm: FormGroup;

    public selectedPackage: Package;

    @Input() set reload(value: boolean) {
        this._reload = value;
        if (typeof this._showActive == 'undefined') {
            return;
        }
        if (this._reload) {
            this.loadPackages();
        }
    }
    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get reload(): boolean {
        return this._reload;
    }

    @Input() set newItem(value: boolean) {
        this._newPackage = value;
        if (this._newPackage) {
            this.selectedPackage = new Package();
            this._packageForm.reset();
            this._recordTitle = this._st.ucFirst(this._transService.instant('base.new') + ' ' + this._transService.instant('products.package'));
            this._showDialog = true;

        }
    }
    @Output() newItemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get newItem(): boolean {
        return this._newPackage;
    }

    @Input() set showActive(value: boolean) {
        this._showActive = value;
        if (this._contextMenu.length > 1) {
            this._transService.get(['base.activate', 'base.deactivate']).subscribe(
                response => {
                    if (this._auth.modules['logistics'].authLevel >= 40 && this._showActive) {
                        this._contextMenu.pop();
                        this._contextMenu.push({label: response['base.deactivate'], icon: 'fa fa-minus-square-o', command: (event) => this.deactivatePackage()});
                    }
                    if (this._auth.modules['logistics'].authLevel >= 40 && !this._showActive) {
                        this._contextMenu.pop();
                        this._contextMenu.push({label: response['base.activate'], icon: 'fa fa-plus-square-o', command: (event) => this.activatePackage()});
                    }
                }
            );
        }

        this.loadPackages();
    }

    get showActive(): boolean {
        return this._showActive;
    }

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder) {
    }

    ngOnInit() {
        this._packageForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'price': new FormControl('', Validators.required),
            'description': new FormControl('', Validators.required),
            'activeUntil': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });
        this._contextMenu.push({label: this._transService.instant('base.details'), icon: 'fa fa-info-circle', command: (event) => this.detailsPackage()});
        if (this._auth.modules['products'].authLevel >= 30) {
            this._contextMenu.push({label: this._transService.instant('base.edit'), icon: 'fa fa-pencil-square-o', command: (event) => this.editPackage()});
        }
        if (this._auth.modules['products'].authLevel >= 40) {
            this._contextMenu.push({label: this._transService.instant('base.delete'), icon: 'fa fa-minus-square-o', command: (event) => this.deletePackage()});
            this._contextMenu.push({label: this._transService.instant('base.deactivate'), icon: 'fa fa-minus-square-o', command: (event) => this.deactivatePackage()});
        }
    }

    loadPackages() {
        this.loading = true;
        this._apiService.setActionUrl('products/packages');
        this._apiService.setSort({field: 'description', direction: 'ASC'});
        if (this._showActive) {
            this._apiService.setFilter({field: 'activeUntil', operator: 'gt', value: this._st.getDateValue()});
        } else {
            this._apiService.setFilter({field: 'activeUntil', operator: 'le', value: this._st.getDateValue()});
        }
        this._apiService.getStore().subscribe(
            response => {
                this._reload = false;
                this.reloadChange.emit(this._reload);
                this.loading = false;
                if (response.success) {
                    this._packages = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    detailsPackage() {
        this._showDetails = true;
        this._recordTitle = this.selectedPackage.description;
    }

    editPackage() {
        this._recordTitle = this.selectedPackage.description;
        this._packageForm.setValue(this.selectedPackage);
        this._showDialog = true;
    }

    cancel() {
        this._recordTitle = '';
        this._showDialog = false;
        this._showDetails = false;
        this.selectedPackage = null;
        if (this._newPackage) {
            this._newPackage = false;
            this.newItemChange.emit(this._newPackage);
        }
        this._packageForm.reset();
    }

    deletePackage() {
        this._apiService.setActionUrl('products/packages');
        this._apiService.deleteRecord(this.selectedPackage.id).subscribe(
            response => {
                if (response.success) {
                    this.selectedPackage = null;
                    this.loadPackages();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    deactivatePackage() {
        this.selectedPackage.activeUntil = this._st.getDateValue();
        this._apiService.setActionUrl('products/packages');
        this._apiService.updateRecord(JSON.stringify(this.selectedPackage)).subscribe(
            response => {
                if (response.success) {
                    this.loadPackages();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    activatePackage() {
        let newDate = new Date();
        newDate.setFullYear(2099,12,31);
        newDate.setHours(0,0,0, 0);
        this.selectedPackage.activeUntil = newDate.getTime() / 1000;
        this._apiService.setActionUrl('products/packages');
        this._apiService.updateRecord(JSON.stringify(this.selectedPackage)).subscribe(
            response => {
                if (response.success) {
                    this.loadPackages();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    savePackage() {
        this.selectedPackage = this._packageForm.getRawValue();
        this._apiService.setActionUrl('products/packages');
        if (this._newPackage) {
            this._apiService.createRecord(JSON.stringify(this.selectedPackage)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedPackage)).subscribe(
                response => {
                    this._afterSave(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    private _afterSave(response) {
        if (response.success) {
            this.cancel();
            this.selectedPackage = response.data.records[0];
            this.detailsPackage();
            this.loadPackages();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    onRowDblclick(event, pack) {
        this.selectedPackage = pack;
        this.detailsPackage();
    }

}
