<p-table #dt [loading]="_loading" [value]="_items" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
         [scrollable]="true" [scrollHeight]="'flex'" [styleClass]="'table'"
         [sortField]="'name'" [(contextMenuSelection)]="selectedItem" [contextMenu]="cm">
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'name'">{{ 'base.strName' | translate }}
                <p-sortIcon [field]="'name'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'name', 'contains')"></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr [pContextMenuRow]="item" (dblclick)="onRowDblclick($event, item)">
            <td>{{ item.name }}</td>
            <td>{{ item.modifier.fullName }}</td>
            <td>
                <djc-date [timeInput]="item.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="_contextMenu"></p-contextMenu>
<div *ngIf="_showDetails">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDetails" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.strName' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedItem.name }}</div>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedItem.creator.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedItem.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedItem.modifier.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedItem.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showDialog">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_itemForm" novalidate (ngSubmit)="saveItem()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="name">{{ 'base.strName' | translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="name" formControlName="name"></div>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveItem()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_itemForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
