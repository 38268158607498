<div *ngIf="_showDetails">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDetails" [modal]="true" [responsive]="true" appendTo="body"
              [styleClass]="'ui-lg-10 ui-md-12 ui-g-12 pDialog pDialogLarge'" (visibleChange)="cancel()" [contentStyle]="{'height':'90% !important'}">
        <div class="ui-g">
            <div class="padDisplay ui-g-3" id="orderDetailsOne">
                <p-card title="{{ 'base.general' | translate }}">
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.offerDate' | translate }}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">
                            <djc-date [timeInput]="selectedOrder.offerDate" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status === 'N'">
                            <p-calendar [ngClass]="'pCalendar'" [(ngModel)]="selectedOrder.offerDateDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'" (onSelect)="_dirtyOrder = true"></p-calendar>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.validTo' | translate }}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">
                            <djc-date [timeInput]="selectedOrder.validTo" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status === 'N'">
                            <p-calendar [ngClass]="'pCalendar'" [(ngModel)]="selectedOrder.validToDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'" (onSelect)="_dirtyOrder = true"></p-calendar>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.closeDate' | translate }}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">
                            <djc-date [timeInput]="selectedOrder.closeDate" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status === 'N'">
                            <p-calendar [ngClass]="'pCalendar'" [(ngModel)]="selectedOrder.closeDateDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'" (onSelect)="_dirtyOrder = true"></p-calendar>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'finance.rate' | translate }}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">{{ selectedOrder.rate.rate | number:'1.2-2':'nl' }}</div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status === 'N'">
                            <p-dropdown [options]="_ratesDropDown" [(ngModel)]="selectedOrder.currencyRateId" filter="true"
                                        (onChange)="_dirtyOrder = true"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'finance.currencyType' | translate }}</b></div>
                        <div class="padDisplay ui-g-8">{{ selectedOrder.currency.type }}</div>
                    </div>
                </p-card>
                <p-card title="Champrix">
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.champrixRef' | translate}}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">{{ selectedOrder.champrixRef }}</div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status === 'N'"><input pInputText [(ngModel)]="selectedOrder.champrixRef" (onChange)="_dirtyOrder = true"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.salesman' | translate }}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">{{ selectedOrder.user.fullName }}</div>
                        <div class="ui-g-8" *ngIf="selectedOrder.status === 'N'">
                            <p-dropdown [options]="_usersDropDown" [(ngModel)]="selectedOrder.userId" filter="true"
                                        (onChange)="_dirtyOrder = true"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g" *ngIf="selectedOrder.status !== 'N'">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.commission' | translate}}</b></div>
                        <div class="padDisplay ui-g-8 priceDetail">{{ selectedOrder.commissionSolid | currency:'EUR':'symbol':'1.2-2':'nl' }} / {{
                            selectedOrder.commissionPercentage |
                                number:'1.2-2'}}%
                        </div>
                    </div>
                    <div class="ui-g" *ngIf="selectedOrder.status == 'N'">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.commission' | translate}} in €</b></div>
                        <div class="ui-g-8">
                            <djc-spinner [(ngModel)]="selectedOrder.commissionSolid" thousandSeparator="." decimalSeparator=","
                                         [step]="1" [disabled]="selectedOrder.commissionPercentage > 0" (onChange)="_dirtyOrder = true"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g" *ngIf="selectedOrder.status == 'N'">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.commission' | translate}} in %</b></div>
                        <div class="ui-g-8">
                            <djc-spinner [(ngModel)]="selectedOrder.commissionPercentage" thousandSeparator="." decimalSeparator=","
                                         [step]="0.1" [disabled]="selectedOrder.commissionSolid > 0" (onChange)="_dirtyOrder = true"></djc-spinner>
                        </div>

                    </div>
                </p-card>
                <p-card title="{{ 'sales.commissionAndFreight' | translate }}">
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'finance.paymentMod' | translate }}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">{{ selectedOrder.paymentMod.description }}</div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status === 'N'">
                            <p-dropdown [options]="_paymentModDropDown" [(ngModel)]="selectedOrder.paymentModalityId" filter="true"
                                        (onChange)="_dirtyOrder = true"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.incoTerms' | translate }}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">{{ selectedOrder.incoTerms }}</div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status === 'N'">
                            <p-dropdown [options]="_incoTermsDropDown" [(ngModel)]="selectedOrder.incoTerms" filter="true"
                                        (onChange)="_dirtyOrder = true"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'finance.freightInsurance' | translate }}</b></div>
                        <div class="padDisplay ui-g-8">{{ selectedOrder.paymentMod.freightInsurrance | number:'1.2-2':'nl' }}%</div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'logistics.destination' | translate }}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">{{ selectedOrder.freight.destination }}</div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status === 'N'">
                            <p-dropdown [options]="_destinationDropDown" [(ngModel)]="selectedOrder.freightCostId" filter="true"
                                        (onChange)="_dirtyOrder = true"></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.calcContainerVolume' | translate }}</b></div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status === 'N'">
                            <djc-spinner [(ngModel)]="selectedOrder.calcContainerVolume" thousandSeparator="." decimalSeparator=","
                                         min="1" max="28" [step]="0.1" (onChange)="_dirtyOrder = true"></djc-spinner>
                        </div>
                        <div class="padDisplay ui-g-8" *ngIf="selectedOrder.status !== 'N'">
                            {{ selectedOrder.calcContainerVolume | number:'1.1-1':'nl' }}
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'sales.numberOfContainers' | translate }}</b></div>
                        <div class="padDisplay ui-g-8">{{ _totalRecord.containers }}</div>
                    </div>
                </p-card>
                <p-card title="{{ 'sales.orderType_P' | translate }}" *ngIf="(selectedOrder.status === 'P' || selectedOrder.status === 'N')">
                    <div class="ui-g">
                        <div class="padDisplay ui-g-10"><b>{{ 'sales.bookDeparture' | translate }}</b></div>
                        <div class="padDisplay ui-g-2"><p-checkbox [(ngModel)]="selectedOrder.bookDeparture" binary="true"></p-checkbox></div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-10"><b>{{ 'sales.purchase' | translate }}</b></div>
                        <div class="padDisplay ui-g-2"><p-checkbox [(ngModel)]="selectedOrder.purchase" binary="true"></p-checkbox></div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-10"><b>{{ 'sales.reserveProductionCapacity' | translate }}</b></div>
                        <div class="padDisplay ui-g-2"><p-checkbox [(ngModel)]="selectedOrder.reserveProductionCapacity" binary="true"></p-checkbox></div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-10"><b>{{ 'sales.sendConfirmation' | translate }}</b></div>
                        <div class="padDisplay ui-g-2"><p-checkbox [(ngModel)]="selectedOrder.sendConfirmation" binary="true"></p-checkbox></div>
                    </div>
                </p-card>
                <p-card title="{{ 'sales.orderType_P' | translate }}" *ngIf="!(selectedOrder.status === 'P' || selectedOrder.status === 'N')">
                    <div class="ui-g">
                        <div class="padDisplay ui-g-10"><b>{{ 'sales.bookDeparture' | translate }}</b></div>
                        <div class="padDisplay ui-g-2"><p-checkbox disabled="true" [(ngModel)]="selectedOrder.bookDeparture" binary="true"></p-checkbox></div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-10"><b>{{ 'sales.purchase' | translate }}</b></div>
                        <div class="padDisplay ui-g-2"><p-checkbox disabled="true" [(ngModel)]="selectedOrder.purchase" binary="true"></p-checkbox></div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-10"><b>{{ 'sales.reserveProductionCapacity' | translate }}</b></div>
                        <div class="padDisplay ui-g-2"><p-checkbox disabled="true" [(ngModel)]="selectedOrder.reserveProductionCapacity" binary="true"></p-checkbox></div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-10"><b>{{ 'sales.sendConfirmation' | translate }}</b></div>
                        <div class="padDisplay ui-g-2"><p-checkbox disabled="true" [(ngModel)]="selectedOrder.sendConfirmation" binary="true"></p-checkbox></div>
                    </div>
                </p-card>
                <p-card>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'base.comment' | translate }}</b></div>
                        <div class="padDisplay ui-g-8"><textarea pInputTextarea [(ngModel)]="_newComment" cols="40" rows="5"></textarea></div>
                    </div>
                    <div class="ui-g">
                        <div class="padDisplay ui-g-4"><b>{{ 'base.comment' | translate }}</b></div>
                        <div class="padDisplay ui-g-8"><textarea pInputTextarea readonly [ngModel]="selectedOrder.comment" cols="40" rows="5"></textarea></div>
                    </div>
                </p-card>
            </div>
            <div class="padDisplay ui-g-9" id="orderLines">
                <div *ngIf="_allLoaded" class="moduleItemMenu">
                    <button class="pButton" type="button" pButton icon="fa fa-print" (click)="printQuotation(false, 'C')" label="{{ 'sales.printQuotation' | translate }} Champrix"></button>
                    <button class="pButton" type="button" pButton icon="fa fa-print" (click)="printQuotation(true, 'C')" label="{{ 'sales.printQuotationTotals' | translate }} Champrix"></button>
                    <button class="pButton" type="button" pButton icon="fa fa-print" (click)="printQuotation(true, 'C', true)" label="{{ 'sales.printQuotationSigned' | translate }} Champrix"></button>
                </div>
                <div *ngIf="_allLoaded" class="moduleItemMenu">
                    <button class="pButton" type="button" pButton icon="fa fa-print" (click)="printQuotation(false, 'N')" label="{{ 'sales.printQuotation' | translate }} NuSana Int"></button>
                    <button class="pButton" type="button" pButton icon="fa fa-print" (click)="printQuotation(true, 'N')" label="{{ 'sales.printQuotationTotals' | translate }} NuSana Int"></button>
                    <button class="pButton" type="button" pButton icon="fa fa-print" (click)="printQuotation(true, 'N', true)" label="{{ 'sales.printQuotationSigned' | translate }} NuSana Int"></button>
                </div>
                <div class="moduleItemMenu">
                    <button *ngIf="_allLoaded" class="pButton" type="button" pButton icon="fa fa-file-text-o" (click)="loadISC()" label="ISC"></button>
                    <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadOrderLines()" label="{{ 'base.refresh' | translate }}"></button>
                    <button class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createOrderLine()" label="{{ 'base.create' | translate }}"></button>
                </div>
                <p-table #olt [value]="_orderLines" [rows]="_rows" [paginator]="true" [pageLinks]="5"
                         [resizableColumns]="true"
                         [scrollable]="true" [scrollHeight]="'flex'" [styleClass]="'table-ol'"
                         [(contextMenuSelection)]="selectedOrderLine" [contextMenu]="olcm" [loading]="_loading"
                         selectionMode="single" [(selection)]="selectedOrderLine">
                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col [style.width]="'3%'">
                            <col [style.width]="'7%'">
                            <col [style.width]="'2%'">
                            <col [style.width]="'25%'">
                            <col [style.width]="'15%'">
                            <col [style.width]="'8%'">
                            <col [style.width]="'8%'">
                            <col [style.width]="'8%'">
                            <col [style.width]="'8%'">
                            <col [style.width]="'8%'">
                            <col [style.width]="'8%'">
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn>#</th>
                            <th pResizableColumn>{{ 'products.code' | translate }}</th>
                            <th pResizableColumn></th>
                            <th pResizableColumn>{{ 'base.strDescription' | translate }}</th>
                            <th pResizableColumn>{{ 'products.package' | translate }}</th>
                            <th pResizableColumn>{{ 'sales.additionalCost' | translate }}</th>
                            <th pResizableColumn>{{ 'sales.quantity' | translate }}</th>
                            <th pResizableColumn>{{ 'sales.costPrice' | translate}}</th>
                            <th pResizableColumn>{{ 'sales.salesPrice' | translate }}</th>
                            <th pResizableColumn>{{ 'sales.margin' | translate }}%</th>
                            <th pResizableColumn>{{ 'sales.margin' | translate }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-orderLine>
                        <tr [pContextMenuRow]="orderLine" (dblclick)="onRowDblclickOrderLine($event, orderLine)">
                            <td class="ui-resizable-column">{{ orderLine.lineNumber }}</td>
                            <td class="ui-resizable-column" [ngStyle]="{'background-color': getBgColorProduct(orderLine.price) }">{{ orderLine.product.code }}</td>
                            <td class="ui-resizable-column"><p-checkbox disabled="true" binary="true" [(ngModel)]="
                                orderLine.newProduct ||
                                orderLine.productChange ||
                                orderLine.packageChange ||
                                orderLine.labelChange ||
                                orderLine.registeredProduct
                            " class="cbDisabled"></p-checkbox></td>
                            <td class="ui-resizable-column">{{ orderLine.product.description }} - {{ orderLine.supplier ? orderLine.supplier.name : 'Geen leverancier' }}</td>
                            <td class="ui-resizable-column">{{ orderLine.packaging.description }}</td>
                            <td class="ui-resizable-column price">{{ orderLine.additionalCost * selectedOrder.rate.rate | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                            <td class="ui-resizable-column">{{ orderLine.quantity | number:'1.3-3':'nl' }}</td>
                            <td class="ui-resizable-column price">{{ _salesService.getCostPrice(orderLine, _totalRecord) | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                            <td class="ui-resizable-column price" *ngIf="selectedOrder.status !== 'N'">
                                {{ orderLine.salesPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}
                            </td>
                            <td class="ui-resizable-column price" pEditableColumn *ngIf="selectedOrder.status === 'N'">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <djc-spinner [(ngModel)]="orderLine.salesPrice" thousandSeparator="." decimalSeparator=","
                                                     min="0" [step]="0.01" (onChange)="updateOrderLine($event, orderLine)"></djc-spinner>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ orderLine.salesPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td class="ui-resizable-column" [ngStyle]="{'background-color': getBgColor(_salesService.getMarginPercentage(orderLine, _totalRecord))}">{{
                                _salesService.getMarginPercentage(orderLine, _totalRecord) * 100 | number:'1.2-2':'nl' }}
                            </td>
                            <td class="ui-resizable-column price">{{ _salesService.getMargin(orderLine, _totalRecord) |
                                currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="3"></td>
                            <td><b>{{ 'sales.totals' | translate }}</b></td>
                            <td></td>
                            <td class="price"><b>{{ _totalRecord.additionalCost * selectedOrder.rate.rate | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</b></td>
                            <td><b>{{ _totalRecord.quantity | number:'1.3-3':'nl' }}</b></td>
                            <td class="price"><b>{{ _totalRecord.costPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</b></td>
                            <td class="price"><b>{{ _totalRecord.salesPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</b></td>
                            <td [ngStyle]="{'background-color': getBgColor(((_totalRecord.salesPrice - _totalRecord.costPrice) / _totalRecord.salesPrice)) }"><b>{{
                                ((_totalRecord.salesPrice - _totalRecord.costPrice) / _totalRecord.salesPrice) * 100 | number:'1.2-2':'nl' }}</b></td>
                            <td class="price"><b>{{ _totalRecord.salesPrice - _totalRecord.costPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</b></td>
                        </tr>
                        <tr>
                            <td colspan="6"></td>
                            <td><b>{{ _totalRecord.containers }} containers </b></td>
                            <td colspan="4"></td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-contextMenu #olcm [model]="_contextMenuOl" appendTo="body"></p-contextMenu>
            </div>
        </div>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="_saveOrderDetail()"
                    label="{{ 'base.save' | translate }}" *ngIf="selectedOrder.status === 'N' || selectedOrder.status === 'P'"></button>
            <!--button class="pButton" type="button" pButton icon="fa fa-close" (click)="_cancelOrderDetail()"
                    label="{{ 'base.cancel' | translate }}"></button-->
        </p-footer>
    </p-dialog>
</div>
<div *ngIf="_showDialog">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDialog" [modal]="true" [responsive]="true" appendTo="body"
              [styleClass]="'ui-lg-8 ui-md-10 ui-g-12 pDialog'" (visibleChange)="cancel()" [contentStyle]="{'height':'70% !important'}">
        <form [formGroup]="_orderForm" novalidate (ngSubmit)="saveOrder(true)">
            <div class="ui-g">
                <div class="ui-g-4">
                    <p-card title="{{ 'base.general' | translate }}">
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="offerDate">{{ 'sales.offerDate' | translate }}</label></div>
                            <div class="ui-g-8">
                                <p-calendar [ngClass]="'pCalendar'" id="offerDate" formControlName="offerDateDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'"></p-calendar>
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="validTo">{{ 'sales.validTo' | translate }}</label></div>
                            <div class="ui-g-8">
                                <p-calendar [ngClass]="'pCalendar'" id="validTo" formControlName="validToDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'"></p-calendar>
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="closeDate">{{ 'sales.closeDate' | translate }}</label></div>
                            <div class="ui-g-8">
                                <p-calendar [ngClass]="'pCalendar'" id="closeDate" formControlName="closeDateDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'"></p-calendar>
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="rateId">{{ 'finance.rate' | translate }}</label></div>
                            <div class="ui-g-8">
                                <p-dropdown id="rateId" [options]="_ratesDropDown" formControlName="currencyRateId"
                                            filter="true"></p-dropdown>
                            </div>
                        </div>
                    </p-card>
                    <p-card title="Champrix">
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="champrixRef">{{ 'sales.champrixRef' | translate}}</label></div>
                            <div class="ui-g-8"><input pInputText id="champrixRef" formControlName="champrixRef"/></div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="userId">{{ 'sales.salesman' | translate }}</label></div>
                            <div class="ui-g-8">
                                <p-dropdown id="userId" [options]="_usersDropDown" formControlName="userId"
                                            filter="true"></p-dropdown>
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="commission">{{ 'sales.commission' | translate }} in €</label></div>
                            <div class="ui-g-8">
                                <djc-spinner id="commission" formControlName="commissionSolid" thousandSeparator="." decimalSeparator=","
                                             [step]="1" [disabled]="_orderForm.get('commissionPercentage').value > 0"></djc-spinner>
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="commission">{{ 'sales.commission' | translate }} in %</label></div>
                            <div class="ui-g-8">
                                <djc-spinner formControlName="commissionPercentage" thousandSeparator="." decimalSeparator=","
                                             [step]="0.1" [disabled]="_orderForm.get('commissionSolid').value > 0"></djc-spinner>
                            </div>
                        </div>
                    </p-card>
                </div>
                <div class="ui-g-4">
                    <p-card title="{{ 'sales.commissionAndFreight' | translate }}">
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="paymentModId">{{ 'finance.paymentMod' | translate }}</label></div>
                            <div class="ui-g-8">
                                <p-dropdown id="paymentModId" [options]="_paymentModDropDown" formControlName="paymentModalityId"
                                            filter="true"></p-dropdown>
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="incoTerms">{{ 'sales.incoTerms' | translate }}</label></div>
                            <div class="ui-g-8">
                                <p-dropdown id="incoTerms" [options]="_incoTermsDropDown" formControlName="incoTerms"
                                            filter="true"></p-dropdown>
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="freightCostId">{{ 'logistics.destination' | translate }}</label></div>
                            <div class="ui-g-8">
                                <p-dropdown id="freightCostId" [options]="_destinationDropDown" formControlName="freightCostId"
                                            filter="true"></p-dropdown>
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><label for="calcContainerVolume">{{ 'sales.calcContainerVolume' | translate }}</label></div>
                            <div class="ui-g-8">
                                <djc-spinner id="calcContainerVolume" formControlName="calcContainerVolume" thousandSeparator="." decimalSeparator=","
                                             min="1" max="28" [step]="0.1" (onChange)="_dirtyOrder = true"></djc-spinner>
                            </div>
                        </div>
                    </p-card>
                </div>
                <div class="ui-g-4">
                    <p-card>
                        <div class="ui-g">
                            {{ _auth.getUser().fullName }} {{ _commentTime }}
                        </div>
                        <div class="ui-g">
                            <textarea pInputTextarea rows="10" cols="80" id="comment" formControlName="newComment"></textarea>
                        </div>
                        <div class="ui-g">
                            <textarea readonly pInputTextarea id="oldComment" rows="10" cols="80" [ngModel]="selectedOrder.comment" [ngModelOptions]="{standalone: true}"></textarea>
                        </div>
                    </p-card>
                </div>
            </div>
        </form>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="_preSaveOrder()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_orderForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
<div *ngIf="_showDetailsOl">
    <p-dialog header="{{ _recordTitleOl}}" [visible]="_showDetailsOl" [modal]="true" [responsive]="true" appendTo="body"
              [styleClass]="'ui-lg-4 ui-md-6 ui-g-8 pDialog'" (visibleChange)="cancelOl()" [contentStyle]="{'height':'70% !important'}">
        <div class="ui-g">
            <p-card title="{{ 'products.product' | translate }}" class="ui-g-12">
                <div class="ui-g">
                    <div class="ui-g-8">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.strDescription' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedOrderLine.product.description }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'products.code' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedOrderLine.product.code }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'products.package' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedOrderLine.packaging.description }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'sales.quantity' | translate }}</b></div>
                            <div class="ui-g-8">{{ selectedOrderLine.quantity | number:'1.3-3':'nl' }}</div>
                        </div>
                    </div>
                    <div class="ui-g-4">

                    </div>
                </div>
            </p-card>
            <p-card title="{{ 'sales.pricing' | translate }}" class="ui-g-12">
                <div class="ui-g">
                    <div class="ui-g-8">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'base.price' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ selectedOrderLine.price.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'products.package' | translate }} {{ 'base.price' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ selectedOrderLine.packaging.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'sales.additionalCost' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ selectedOrderLine.additionalCost | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'sales.freightCost' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ _salesService.getFreightCost(_totalRecord) | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'sales.commission' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ _salesService.getCommission(selectedOrderLine, _totalRecord) | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'finance.freightInsurance' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ _salesService.getInsurrance(selectedOrderLine, _totalRecord) | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'finance.LCCost' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ _salesService.getLCCost(_totalRecord) | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'sales.creditCost' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ _salesService.getCredit(selectedOrderLine) | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'sales.costPrice' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ _salesService.getCostPrice(selectedOrderLine, _totalRecord) | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"></div>
                            <div class="ui-g-8 customHr">.</div>
                        </div>
                    </div>
                    <div class="ui-g-4">
                        <div class="ui-g">
                            <div class="ui-g-4">{{ 'base.valid' | translate }}</div>
                            <div class="ui-g-8" [ngStyle]="{'color': _st.getDateColor(selectedOrderLine.price.validFrom, 14, 7)}">
                                <djc-date [timeInput]="selectedOrderLine.price.validFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date> / <djc-date [timeInput]="selectedOrderLine.price.validUntil" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-g-8">
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'sales.salesPrice' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ selectedOrderLine.salesPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'sales.margin' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ _salesService.getMargin(selectedOrderLine, _totalRecord) | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>{{ 'sales.marginPercentage' | translate }}</b></div>
                            <div class="ui-g-8" style="text-align: right">{{ _salesService.getMarginPercentage(selectedOrderLine, _totalRecord) * 100 | number:'1.2-2':'nl' }}%</div>
                        </div>
                        <div class="ui-g">

                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>Cum. {{ 'sales.margin' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ selectedOrderLine.quantity * _salesService.getMargin(selectedOrderLine, _totalRecord) |
                                currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>Cum. {{ 'sales.costPrice' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ selectedOrderLine.quantity * _salesService.getCostPrice(selectedOrderLine, _totalRecord) |
                                currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}
                            </div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-4"><b>Cum. {{ 'sales.salesPrice' | translate }}</b></div>
                            <div class="ui-g-8 price">{{ selectedOrderLine.quantity * selectedOrderLine.salesPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</div>
                        </div>
                    </div>
                    <div class="ui-g-4">
                        <div class="ui-g">
                            <div class="ui-g-4">{{ 'finance.rate' | translate }}</div>
                            <div class="ui-g-8">{{ selectedOrder.rate.rate | number:'1.3-3':'nl' }}</div>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </p-dialog>
</div>
<div *ngIf="_showDialogOl">
    <p-dialog header="{{ _recordTitleOl}}" [visible]="_showDialogOl" [modal]="true" [responsive]="true" appendTo="body"
              [styleClass]="'ui-lg-8 ui-md-10 ui-g-12 pDialog'" (visibleChange)="cancelOl()" [contentStyle]="{'height':'90% !important'}">
        <form [formGroup]="_orderLineForm" novalidate (ngSubmit)="_preSaveOrderLine()">
            <div class="ui-g">
                <div class="ui-g-6">
                    <p-card title="{{ 'products.product' | translate }}" class="ui-g-12">
                        <div class="ui-g">
                            <div class="ui-g-2"><b>{{ 'base.strDescription' | translate }}</b></div>
                            <div class="ui-g-10">
                                <p-dropdown [options]="_productsDropDown" formControlName="priceId"
                                            filter="true" (onChange)="changedProduct($event)"></p-dropdown>
                            </div>
                        </div>
                        <div *ngIf="selectedOrderLine.product" class="ui-g">
                            <div class="ui-g-2"><b>{{ 'products.code' | translate }}</b></div>
                            <div class="ui-g-10">{{ selectedOrderLine.product.code }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-2"><b>{{ 'products.package' | translate }}</b></div>
                            <div class="ui-g-10">
                                <p-dropdown [options]="_packagesDropDown" formControlName="packagingId"
                                            filter="true" (onChange)="changedPackage($event)"></p-dropdown>
                            </div>
                        </div>
                        <div *ngIf="selectedOrderLine.price" class="ui-g">
                            <div class="ui-g-2"><b>{{ 'sales.quantity' | translate }}</b></div>
                            <div class="ui-g-10">
                                <djc-spinner id="quantity" formControlName="quantity" thousandSeparator="." decimalSeparator=","
                                             [step]="0.001" (onChange)="updateQuantity()" [formatInput]="true" min="0"></djc-spinner>
                            </div>
                        </div>
                    </p-card>
                    <p-card title="{{ 'sales.pricing' | translate }}" class="ui-g-12">
                        <div class="ui-g" *ngIf="selectedOrderLine.price">
                            <div class="ui-g-2"><b>{{ 'base.price' | translate }}</b></div>
                            <div class="ui-g-4 price">{{ selectedOrderLine.price.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                            <div class="ui-g-2">{{ 'base.valid' | translate }}</div>
                            <div class="ui-g-4" [ngStyle]="{'color': _st.getDateColor(selectedOrderLine.price.validFrom, 14, 7)}">
                                <djc-date [timeInput]="selectedOrderLine.price.validFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date> / <djc-date [timeInput]="selectedOrderLine.price.validUntil" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                            </div>
                        </div>
                        <div class="ui-g" *ngIf="selectedOrderLine.packaging">
                            <div class="ui-g-2"><b>{{ 'products.package' | translate }} {{ 'base.price' | translate }}</b></div>
                            <div class="ui-g-4 price">{{ selectedOrderLine.packaging.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                            <div class="ui-g-6"></div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-2"><b>{{ 'sales.additionalCost' | translate }}</b></div>
                            <div class="ui-g-4 price">
                                <djc-spinner id="additionalCost" formControlName="additionalCost" thousandSeparator="." decimalSeparator=","
                                             [step]="0.01" (onChange)="updateAddCost()"></djc-spinner>
                            </div>
                            <div class="ui-g-2"><b>{{ 'sales.freightCost' | translate }}</b></div>
                            <div class="ui-g-4 price">{{ _currentFC | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g" *ngIf="selectedOrderLine.price && selectedOrderLine.packaging">
                            <div class="ui-g-2"><b>{{ 'sales.commission' | translate }}</b></div>
                            <div class="ui-g-4 price">{{ _salesService.getCommission(selectedOrderLine, _totalRecord) | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                            <div class="ui-g-2"><b>{{ 'finance.freightInsurance' | translate }}</b></div>
                            <div class="ui-g-4 price">{{ _salesService.getInsurrance(selectedOrderLine, _totalRecord) | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g" *ngIf="selectedOrderLine.salesPrice">
                            <div class="ui-g-2"><b>{{ 'finance.LCCost' | translate }}</b></div>
                            <div class="ui-g-4 price">{{ _salesService.getLCCost(_totalRecord) | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                            <div class="ui-g-2"><b>{{ 'sales.creditCost' | translate }}</b></div>
                            <div class="ui-g-4 price">{{ _salesService.getCredit(selectedOrderLine) | currency:'EUR':'symbol':'1.2-2':'nl' }}</div>
                        </div>
                        <div class="ui-g" *ngIf="selectedOrderLine.price && selectedOrderLine.packaging">
                            <div class="ui-g-2"><b>{{ 'sales.costPrice' | translate }}</b></div>
                            <div class="ui-g-4 price">{{ _salesService.getCostPrice(selectedOrderLine, _totalRecord) | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</div>
                            <div class="ui-g-6"></div>
                        </div>
                    </p-card>
                </div>
                <div class="ui-g-6">
                    <p-card title="{{ 'sales.pricing' | translate }}" class="ui-g-12">
                        <div class="ui-g">
                            <div class="ui-g-8">
                                <div class="ui-g">
                                    <div class="ui-g-4"><b>{{ 'sales.salesPrice' | translate }}</b></div>
                                    <div class="ui-g-8 price">
                                        <djc-spinner id="salesPrice" formControlName="salesPrice" thousandSeparator="." decimalSeparator=","
                                                     min="0" [step]="0.01" (onChange)="updateSalesPrice()"></djc-spinner>
                                    </div>
                                </div>
                                <div class="ui-g" *ngIf="selectedOrderLine.price && selectedOrderLine.packaging">
                                    <div class="ui-g-4"><b>{{ 'sales.margin' | translate }}</b></div>
                                    <div class="ui-g-8 price">{{ _salesService.getMargin(selectedOrderLine, _totalRecord) | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</div>
                                </div>
                                <div class="ui-g" *ngIf="selectedOrderLine.price && selectedOrderLine.packaging">
                                    <div class="ui-g-4"><b>{{ 'sales.marginPercentage' | translate }}</b></div>
                                    <div class="ui-g-8" style="text-align: right">{{ _salesService.getMarginPercentage(selectedOrderLine, _totalRecord) * 100 | number:'1.2-2':'nl' }}%</div>
                                </div>
                                <div class="ui-g">

                                </div>
                                <div class="ui-g" *ngIf="selectedOrderLine.salesPrice && selectedOrderLine.price">
                                    <div class="ui-g-4"><b>Cum. {{ 'sales.margin' | translate }}</b></div>
                                    <div class="ui-g-8 price">{{ selectedOrderLine.quantity * _salesService.getMargin(selectedOrderLine, _totalRecord) |
                                        currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}
                                    </div>
                                </div>
                                <div class="ui-g" *ngIf="selectedOrderLine.price && selectedOrderLine.packaging">
                                    <div class="ui-g-4"><b>Cum. {{ 'sales.costPrice' | translate }}</b></div>
                                    <div class="ui-g-8 price">{{ selectedOrderLine.quantity * _salesService.getCostPrice(selectedOrderLine, _totalRecord) |
                                        currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}
                                    </div>
                                </div>
                                <div class="ui-g" *ngIf="selectedOrderLine.price && selectedOrderLine.quantity">
                                    <div class="ui-g-4"><b>Cum. {{ 'sales.salesPrice' | translate }}</b></div>
                                    <div class="ui-g-8 price">{{ selectedOrderLine.quantity * selectedOrderLine.salesPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</div>
                                </div>
                            </div>
                            <div class="ui-g-4">
                                <div class="ui-g">
                                    <div class="ui-g-4">{{ 'finance.rate' | translate }}</div>
                                    <div class="ui-g-8">{{ selectedOrder.rate.rate | number:'1.3-3':'nl' }}</div>
                                </div>
                            </div>
                        </div>
                    </p-card>
                    <p-card title="{{ 'sales.productInfo' | translate }}" class="ui-g-12">
                        <div class="ui-g">
                            <div class="ui-g-1"><p-checkbox formControlName="newProduct" binary="true"></p-checkbox></div>
                            <div class="ui-g-11">{{ 'sales.newProduct' | translate }}</div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-1"><p-checkbox formControlName="productChange" binary="true"></p-checkbox></div>
                            <div class="ui-g-3">{{ 'sales.productChange' | translate }}</div>
                            <div class="ui-g-8" *ngIf="_orderLineForm.get('productChange').value">
                                <textarea pInputTextarea rows="2" cols="120" formControlName="productChangeDescription" placeholder="{{ 'sales.productChangeDescription' | translate }}"></textarea>
                            </div>
                            <div class="ui-g-8" *ngIf="!(_orderLineForm.get('productChange').value)"></div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-1"><p-checkbox formControlName="packageChange" binary="true"></p-checkbox></div>
                            <div class="ui-g-3">{{ 'sales.packageChange' | translate }}</div>
                            <div class="ui-g-8" *ngIf="_orderLineForm.get('packageChange').value">
                                <textarea pInputTextarea rows="2" cols="120" formControlName="packageChangeDescription" placeholder="{{ 'sales.packageChangeDescription' | translate }}"></textarea>
                            </div>
                            <div class="ui-g-8" *ngIf="!(_orderLineForm.get('packageChange').value)"></div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-1"><p-checkbox formControlName="labelChange" binary="true"></p-checkbox></div>
                            <div class="ui-g-3">{{ 'sales.labelChange' | translate }}</div>
                            <div class="ui-g-8" *ngIf="_orderLineForm.get('labelChange').value">
                                <textarea pInputTextarea rows="2" cols="120" formControlName="labelChangeDescription" placeholder="{{ 'sales.labelChangeDescription' | translate }}"></textarea>
                            </div>
                            <div class="ui-g-8" *ngIf="!(_orderLineForm.get('labelChange').value)"></div>
                        </div>
                        <div class="ui-g">
                            <div class="ui-g-1"><p-checkbox formControlName="registeredProduct" binary="true"></p-checkbox></div>
                            <div class="ui-g-11">{{ 'sales.registeredProduct' | translate }}</div>
                        </div>
                    </p-card>
                </div>
            </div>
        </form>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="_preSaveOrderLine()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_orderLineForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancelOl()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
<div *ngIf="_showISC">
    <isc [selectedOrder]="selectedOrder" [_orderLines]="_orderLines" [_totalRecord]="_totalRecord" [_visible]="_showISC" (visibleChange)="ISCHidden()"></isc>
</div>
