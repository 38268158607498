<p-table #dt [value]="products" [rows]="_auth.defaultRows" [paginator]="true" [pageLinks]="5"
         [sortField]="'code'"
         [scrollable]="true" [scrollHeight]="'flex'" [styleClass]="'table'"
         [(contextMenuSelection)]="selectedProduct" [contextMenu]="cm" (onContextMenuSelect)="loadDetails()" [loading]="loading">
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'code'">{{ 'products.code' | translate }}
                <p-sortIcon [field]="'code'"></p-sortIcon>
            </th>
            <th>{{ 'base.strDescription' | translate }}</th>
            <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'code', 'contains')"></th>
            <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'description', 'contains')"></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
        <tr [pContextMenuRow]="product" (dblclick)="onRowDblclick($event, product)">
            <td>{{ product.code }}</td>
            <td>{{ product.description }}</td>
            <td>{{ product.modifier.fullName }}</td>
            <td>
                <djc-date [timeInput]="product.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="_contextMenu" appendTo="body"></p-contextMenu>
<div *ngIf="_showDetails">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDetails" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade" [styleClass]="'pTabview'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'products.code' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedProduct.code }}</div>
                        <div class="ui-g-4"><b>{{ 'base.strDescription' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedProduct.description }}</div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'products.package' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedProduct.prefPackaging.description }}</div>
                        <div class="ui-g-4"><b>{{ 'products.IMO' | translate }}</b></div>
                        <div class="ui-g-8">
                            <p-checkbox [ngModel]="selectedProduct.IMO" binary="true" disabled="true"></p-checkbox>
                        </div>
                        <div class="ui-g-4"><b>{{ 'products.EHF' | translate }}</b></div>
                        <div class="ui-g-8">
                            <p-checkbox [ngModel]="selectedProduct.EHF" binary="true" disabled="true"></p-checkbox>
                        </div>
                    </div>
                </div>
                <div class="ui-g">
                    <textarea pInputTextarea readonly [ngModel]="selectedProduct.comment" cols="80" rows="10"></textarea>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedProduct.creator.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedProduct.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedProduct.modifier.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedProduct.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{ 'products.prices' | translate}}" style="display: flex; height: 100%;" [selected]="true">
                <productprice [product]="selectedProduct"></productprice>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showDialog">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" (visibleChange)="cancel()">
        <p-tabView effect="fade" [styleClass]="'pTabView'">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_productForm" novalidate (ngSubmit)="saveProduct()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="code">{{ 'products.code' | translate}}</label></div>
                        <div class="ui-g-8"><input pInputText id="code" formControlName="code"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="description">{{ 'products.description'| translate }}</label></div>
                        <div class="ui-g-8"><input pInputText id="description" formControlName="description"/></div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="IMO">{{ 'products.IMO'| translate }}</label></div>
                        <div class="ui-g-8">
                            <p-inputSwitch id="IMO" formControlName="IMO"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="EHF">{{ 'products.EHF'| translate }}</label></div>
                        <div class="ui-g-8">
                            <p-inputSwitch id="EHF" formControlName="EHF"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="packageSize">{{ 'products.package' | translate}}</label></div>
                        <div class="ui-g-8">
                            <p-dropdown id="packageSize" [options]="_packages" filter="true" [scrollHeight]="'200px'"
                                        formControlName="prefPackagingId"></p-dropdown>
                        </div>
                    </div>
                </form>
            </p-tabPanel>
            <p-tabPanel header="{{ 'base.comment' | translate }}">
                <form [formGroup]="_commentForm" novalidate (ngSubmit)="saveProduct()">
                    <div class="ui-g">
                        {{ _auth.getUser().fullName }} {{ _commentTime }}
                    </div>
                    <div class="ui-g">
                        <textarea pInputTextarea rows="10" cols="80" id="comment" formControlName="comment"></textarea>
                    </div>
                    <div class="ui-g">
                        <textarea readonly pInputTextarea id="oldComment" rows="10" cols="80" [ngModel]="selectedProduct.comment" [ngModelOptions]="{standalone: true}"></textarea>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveProduct()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_productForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
<div *ngIf="_showCustomerHistory">
    <p-dialog [header]="_historyTitle" [visible]="_showCustomerHistory" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-8 ui-md-10 ui-g-12 pDialog'" (visibleChange)="cancelHistory()" appendTo="body">
        <p-table #hdt [value]="_productHistory" [rows]="15" [paginator]="true" [pageLinks]="5"
                 [sortMode]="'multiple'" [multiSortMeta]="_multiSortMeta" [loading]="_historyLoading">
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'order.company.name'">{{ 'addressbook.company' | translate }}
                        <p-sortIcon [field]="'order.company.name'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'order.champrixRef'">{{ 'sales.champrixRef' | translate }}
                        <p-sortIcon [field]="'order.champrixRef'"></p-sortIcon>
                    </th>
                    <th>{{ 'base.strDescription' | translate }}</th>
                    <th>{{ 'sales.quantity' | translate }}</th>
                    <th>{{ 'sales.orderStatus' | translate }}</th>
                    <th [pSortableColumn]="'order.offerDate'">{{ 'sales.offerDate' | translate }}
                        <p-sortIcon [field]="'order.offerDate'"></p-sortIcon>
                    </th>
                    <th>{{ 'sales.salesPrice' | translate }}</th>
                </tr>
                <tr>
                    <th><input pInputText type="text" (input)="hdt.filter($event.target.value, 'order.company.name', 'contains')"></th>
                    <th><input pInputText type="text" (input)="hdt.filter($event.target.value, 'order.champrixRef', 'contains')"></th>
                    <th><input pInputText type="text" (input)="hdt.filter($event.target.value, 'extraString', 'contains')"></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-history>
                <tr>
                    <td>{{ history.order.company.name }}</td>
                    <td>{{ history.order.champrixRef }}</td>
                    <td>{{ history.extraString }}</td>
                    <td class="right">{{ history.quantity | number:'1.3-3':'nl' }}</td>
                    <td>{{ history.order.statusDescription }}</td>
                    <td class="right"><djc-date [timeInput]="history.order.offerDate" [format]="_auth.getUserSettings().dateFormat"></djc-date></td>
                    <td class="price">{{ history.salesPrice | currency:history.order.rate.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                </tr>
            </ng-template>
        </p-table>
    </p-dialog>
</div>
