import {Component, OnInit} from "@angular/core";
import {Addressbook, Company, Contact} from "./addressbook.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService, responseModel} from "../api.service";
import {SupportTools} from "../support.tools";
import {AuthService} from "../auth.service";
import {TranslateService} from "@ngx-translate/core";
import {AclService} from "../acl.service";
import {MenuItem, SelectItem} from "primeng/api";

@Component({
    selector: 'addressbook',
    templateUrl: './addressbook.component.html',
    styleUrls: ['./addressbook.component.css']
})
export class AddressbookComponent implements OnInit {

    public _addressBooks: Addressbook[] = [];
    public _addressBooksDropDown: SelectItem[] = [];
    public _typeDropDown: SelectItem[] = [];
    private _newAddressBook: boolean = false;
    private _recordTitleAB: string;
    private _companyForm: FormGroup;
    private _financeForm: FormGroup;
    private _addressBookForm: FormGroup;
    public _abContextMenu: MenuItem[] = [];
    public _loadCompanies: boolean = false;
    public _createCompany: boolean = false;

    public _showManagementDialog: boolean = false;
    public _showAddressBookDialog: boolean = false;

    public selectedAddressBook: Addressbook = new Addressbook();
    public selectedAddressBookDialog: Addressbook;
    public selectedCompany: Company;
    public loading: boolean = false;

    constructor(private _apiService: ApiService, public _auth: AuthService, private _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _aclService: AclService) {
    }

    ngOnInit() {
        this.loadAddressBooks();
        this._typeDropDown.push({label: this._transService.instant('base.useSelectValue'), value: ''});
        this._typeDropDown.push({label: this._transService.instant('addressbook.customer'), value: 'C'});
        this._typeDropDown.push({label: this._transService.instant('addressbook.supplier'), value: 'S'});
        this._companyForm = this._fb.group( {
            'id': new FormControl({value: '', disabled: true}),
            'oldId': new FormControl({value: '', disabled: true}),
            'name': new FormControl('', Validators.required),
            'prefPaymentModalityId': new FormControl('', Validators.required),
            'prefPaymentMod': new FormControl({value: '', disabled: true}),
            'addressBookId': new FormControl('', Validators.required),
            'addressbook': new FormControl({value: '', disabled: true}),
            'creditInsured': new FormControl(''),
            'type': new FormControl('', Validators.required),
            'typeDescription': new FormControl({value: '', disabled: true}),
            'street': new FormControl(''),
            'zipCode': new FormControl(''),
            'city': new FormControl(''),
            'countryId': new FormControl('', Validators.required),
            'country': new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });
        this._financeForm = this._fb.group({
            'creditInsured': new FormControl(''),
            'prefPaymentModalityId': new FormControl('', Validators.required)
        });

        this._addressBookForm = this._fb.group({
            'id': new FormControl({value: '', disabled: true}),
            'name': new FormControl('', Validators.required),
            'aclItemId': new FormControl({value: '', disabled: true}),
            'aclLevel':  new FormControl({value: '', disabled: true}),
            'softDeleted': new FormControl({value: '', disabled: true}),
            'createdAt': new FormControl({value: '', disabled: true}),
            'creatorId': new FormControl({value: '', disabled: true}),
            'modifiedAt': new FormControl({value: '', disabled: true}),
            'modifierId': new FormControl({value: '', disabled: true}),
            'creator': new FormControl({value: '', disabled: true}),
            'modifier': new FormControl({value: '', disabled: true})
        });

        this._transService.get(['base.edit', 'base.delete']).subscribe(
            response => {
                this._abContextMenu.push({label: response['base.edit'], icon: 'fa fa-pencil-square-o', command: (event) => this.editAddressBook()});
                this._abContextMenu.push({label: response['base.delete'], icon: 'fa fa-minus-square-o', command: (event) => this.deleteAddressBook()});
            }
        )
    }

    manageAddressBooks() {
        this._showManagementDialog = true;
    }

    cancelManagement() {
        this._showManagementDialog = false;
    }

    loadAddressBooks() {
        this._apiService.setActionUrl('addressbook/addressbooks');
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    this._addressBooks = response.data.records;
                    if (this._addressBooks.length === 1) {
                        this.selectedAddressBook = this._addressBooks[0];
                        this.loadCompanies();
                    }
                    this._addressBooksDropDown.push({value: '', label: this._transService.instant('base.useSelectValue')});
                    this._addressBooks.forEach(function (addressbook) {
                        this._addressBooksDropDown.push({value: addressbook.id, label: addressbook.name});
                    }, this);
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        )
    }

    loadCompanies() {
        this._loadCompanies = true;
    }

    createCompany() {
        this._createCompany = true;
    }

    createAddressBook() {
        this._newAddressBook = true;
        this._showAddressBookDialog = true;
        this.selectedAddressBookDialog = new Addressbook();
        this._recordTitleAB = this._transService.instant('base.new') + ' ' + this._transService.instant('addressbook.addressbook');
        this._addressBookForm.reset();
    }

    editAddressBook() {
        this._addressBookForm.setValue(this.selectedAddressBookDialog);
        this._recordTitleAB = this.selectedAddressBookDialog.name;
        this._aclService.getAcl(this.selectedAddressBookDialog.aclItemId);
        this._showAddressBookDialog = true;
    }

    cancelAddressBook() {
        this._newAddressBook = false;
        this.selectedAddressBookDialog = null;
        this._showAddressBookDialog = false;
    }

    saveAddressBook() {
        this._apiService.setActionUrl('addressbook/addressbooks');
        this.selectedAddressBookDialog = this._addressBookForm.getRawValue();
        if (this._newAddressBook) {
            this._apiService.createRecord(JSON.stringify(this.selectedAddressBookDialog)).subscribe(
                response => {
                    this._afterSaveAB(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        } else {
            this._apiService.updateRecord(JSON.stringify(this.selectedAddressBookDialog)).subscribe(
                response => {
                    this._afterSaveAB(response);
                },
                error => {
                    this._auth.addError(error.error.text);
                }
            );
        }
    }

    private _afterSaveAB(response: responseModel) {
        if (response.success) {
            if (this._newAddressBook) {
                this.selectedAddressBookDialog.id = response.newId;
                this.selectedAddressBookDialog.aclItemId = response.newAclId;
                this._aclService.getAcl(this.selectedAddressBookDialog.aclItemId);
                this._newAddressBook = false;
                this.editAddressBook();
            } else {
                this.cancelAddressBook();
            }
            this.loadAddressBooks();
        } else {
            this._auth.addError(response.errorMsg);
        }
    }

    deleteAddressBook() {
        this._apiService.setActionUrl('addressbook/addressbooks');
        this._apiService.deleteRecord(this.selectedAddressBookDialog.id).subscribe(
            response => {
                if (response.success) {
                    this.loadAddressBooks();
                    this.selectedAddressBookDialog = null;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    onRowDblclickAB($event, addressbook) {
        this.selectedAddressBookDialog = addressbook;
        this.editAddressBook();
    }

}
