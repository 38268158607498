<div class="moduleItemMenu">
    <button class="pButton" type="button" pButton icon="fa fa-refresh" (click)="loadRates()" label="{{ 'base.refresh' | translate }}"></button>
    <button *ngIf="_auth.modules['finance'].authLevel >= 20"
            class="pButton" type="button" pButton icon="fa fa-plus-square-o" (click)="createRate()" label="{{ 'base.create' | translate }}"></button>
</div>
<p-table #dt [loading]="_loading" [value]="_rates" [rows]="10" [paginator]="true" [pageLinks]="5"
         [(contextMenuSelection)]="selectedRate" [contextMenu]="cm"
         [sortField]="'validFrom'" [sortOrder]="-1">
    <ng-template pTemplate="header">
        <tr>
            <th>{{ 'finance.rate' | translate }}</th>
            <th>{{ 'base.validFrom' | translate }}</th>
            <th [pSortableColumn]="'modifier.fullName'">{{ 'base.modifier' | translate }}
                <p-sortIcon [field]="'modifier.fullName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'modifiedAt'">{{ 'base.modified' | translate }}
                <p-sortIcon [field]="'modifiedAt'"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rate>
        <tr [pContextMenuRow]="rate" (dblclick)="onRowDblclick($event, rate)">
            <td class="price">{{ rate.rate | currency:'EUR':'symbol':'1.4-4':'nl' }}</td>
            <td>
                <djc-date [timeInput]="rate.validFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date>
            </td>
            <td>{{ rate.modifier.fullName }}</td>
            <td>
                <djc-date [timeInput]="rate.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-contextMenu #cm [model]="_contextMenu" appendTo="body"></p-contextMenu>
<div *ngIf="_showDetails">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDetails" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" appendTo="body" (visibleChange)="cancel()">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <div class="ui-g">
                    <div class="ui-g-4"><b>{{ 'finance.rate' | translate }}</b></div>
                    <div class="ui-g-8 priceDetail">{{ selectedRate.rate | currency:'EUR':'symbol':'1.4-4':'nl' }}</div>
                </div>
                <div class="ui-g">
                    <div class="ui-g-4"><b>{{ 'base.validFrom' | translate }}</b></div>
                    <div class="ui-g-8">
                        <djc-date [timeInput]="selectedRate.validFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.creator' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedRate.creator.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.created' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedRate.createdAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                    <div class="ui-lg-6 ui-md-12">
                        <div class="ui-g-4"><b>{{ 'base.modifier' | translate }}</b></div>
                        <div class="ui-g-8">{{ selectedRate.modifier.fullName }}</div>
                        <div class="ui-g-4"><b>{{ 'base.modified' | translate }}</b></div>
                        <div class="ui-g-8">
                            <djc-date [timeInput]="selectedRate.modifiedAt" [format]="_auth.getUserSettings().dateTimeFormat"></djc-date>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>
</div>
<div *ngIf="_showDialog">
    <p-dialog header="{{ _recordTitle }}" [visible]="_showDialog" [modal]="true" [responsive]="true"
              [styleClass]="'ui-lg-6 ui-md-8 ui-g-12 pDialog'" appendTo="body" (visibleChange)="cancel()">
        <p-tabView effect="fade">
            <p-tabPanel header="{{ 'base.data' | translate }}">
                <form [formGroup]="_rateForm" novalidate (ngSubmit)="saveRate()">
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="rate">{{ 'finance.currencyType' | translate }}</label></div>
                        <div class="ui-g-8">
                            <djc-spinner id="rate" formControlName="rate" thousandSeparator="." decimalSeparator=","
                                       min="0" [step]="0.01"></djc-spinner>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4"><label for="validFrom">{{ 'base.validFrom' | translate}}</label></div>
                        <div class="ui-g-8">
                            <p-calendar [ngClass]="'pCalendar'" id="validFrom" formControlName="validFromDate" [showIcon]="true" [dateFormat]="'dd-mm-yy'"></p-calendar>
                        </div>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
        <p-footer>
            <button class="pButton" type="button" pButton icon="fa fa-check" (click)="saveRate()"
                    label="{{ 'base.save' | translate }}" [disabled]="!(_rateForm.valid)"></button>
            <button class="pButton" type="button" pButton icon="fa fa-close" (click)="cancel()"
                    label="{{ 'base.cancel' | translate }}"></button>
        </p-footer>
    </p-dialog>
</div>
