export class User {
    id: string;
    email: string;
    fullName: string;
    userName: string;
    password: string;
    password2: string;
    lastLogin: number;
    createdAt: number;
    modifiedAt: number;
    loginEnabled: boolean;
    loginByPassword: boolean;
    forcePasswordChange: boolean;
    settings: UserSettings
}

export class UserSettings {
    dateFormat: string = 'dd-MM-yyyy';
    dateTimeFormat: string = 'dd-MM-yyyy HH:mm';
    timeFormat: string = 'HH:mm';
    language: string = 'nl';
    defaultRows: number = 25;
    expiresSeconds: number = 900;
    defaultAddressbook: string = '';
}

export class UserDropDown {
    label: string;
    value: string;
}
