<p-dialog [visible]="_visible" (visibleChange)="cancel()" header="ISC" appendTo="body" [styleClass]="'ui-g-10 pDialog pDialogLarge'"
          [contentStyle]="{'height':'70% !important'}">
    <div id="iscContent" #iscContent>
        <div class="moduleItemMenu">
            Internal sales Confirmation
        </div>
        <div class="ui-g">
            <div class="ui-g-7">
                <table>
                    <tr>
                        <th style="width: 15%"></th>
                        <th style="width: 25%">{{ 'base.general' | translate }}</th>
                        <th style="width: 15%"></th>
                        <th style="width: 45%">{{ 'addressbook.C' | translate }}</th>
                    </tr>
                    <tr>
                        <td>Order #</td>
                        <td class="iscBackground">{{ selectedOrder.champrixRef }} / {{ selectedOrder.orderVersion }}</td>
                        <td>{{ 'addressbook.C' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.company.name }} / {{ selectedOrder.company.debtorCode }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'sales.salesman' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.user.fullName }}</td>
                        <td>{{ 'base.country' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.country.country }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'sales.orderStatus' | translate }}</td>
                        <td class="iscBackground">{{ 'sales.orderType_' + selectedOrder.status | translate }}</td>
                        <td>{{ 'addressbook.city' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.company.city }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'sales.offerDate' | translate }}</td>
                        <td class="iscBackground">
                            <djc-date [timeInput]="selectedOrder.offerDate" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </td>
                        <td>{{ 'addressbook.zipCode' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.company.zipCode }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'sales.validTo' | translate }}</td>
                        <td class="iscBackground">
                            <djc-date [timeInput]="selectedOrder.validTo" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </td>
                        <td>{{ 'addressbook.street' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.company.street }}</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <th style="width: 15%"></th>
                        <th style="width: 10%">{{ 'sales.conditions' | translate }}</th>
                        <th style="width: 15%"></th>
                        <th style="width: 15%"></th>
                        <th style="width: 15%"></th>
                        <th style="width: 30%; text-align: right">{{ 'sales.totals' | translate }}</th>
                    </tr>
                    <tr>
                        <td>{{ 'base.strDescription' | translate }}</td>
                        <td colspan="3" class="iscBackground">{{ selectedOrder.paymentMod.description }}</td>
                        <td>{{ 'sales.costPrice' | translate }}</td>
                        <td class="iscTotalBackground price">{{ _totalRecord.costPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'sales.incoTerms' | translate }}</td>
                        <td colspan="3" class="iscBackground">{{ selectedOrder.incoTerms }}</td>
                        <td>{{ 'sales.salesPrice' | translate }}</td>
                        <td class="iscTotalBackground price">{{ _totalRecord.salesPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'addressbook.creditInsured' | translate }}</td>
                        <td>
                            <p-checkbox [(ngModel)]="selectedOrder.company.creditInsured" [disabled]="true" binary="true"></p-checkbox>
                        </td>
                        <td>{{ 'sales.commission' | translate }}%</td>
                        <td class="iscBackground" style="text-align: right">{{ selectedOrder.commissionPercentage | number:'1.2-2':'nl' }}%</td>
                        <td>{{ 'sales.margin' | translate }}</td>
                        <td class="iscTotalBackground price">{{ _totalRecord.salesPrice - _totalRecord.costPrice | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'finance.interestRate' | translate }}</td>
                        <td class="iscBackground" style="text-align: right">{{ selectedOrder.paymentMod.interestRate | number:'1.2-2':'nl' }}%</td>
                        <td>{{ 'sales.commission' | translate }}</td>
                        <td class="iscBackground price">{{ selectedOrder.commissionSolid | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
                        <td>{{ 'sales.margin' | translate }}%</td>
                        <td class="iscTotalBackground" style="text-align: right">{{ ((_totalRecord.salesPrice - _totalRecord.costPrice) / _totalRecord.salesPrice) * 100 | number:'1.2-2':'nl' }}%</td>
                    </tr>
                    <tr>
                        <td>{{ 'finance.freightInsurance' | translate }}</td>
                        <td class="iscBackground" style="text-align: right">{{ selectedOrder.paymentMod.freightInsurrance | number:'1.2-2':'nl' }}%</td>
                        <td>{{ 'sales.calcContainerVolume' | translate }}</td>
                        <td class="iscBackground" style="text-align: right">{{ selectedOrder.calcContainerVolume }}</td>
                        <td>{{ 'sales.quantity' | translate }}</td>
                        <td class="iscTotalBackground" style="text-align: right">{{ _totalRecord.quantity }} Mtons</td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td>{{ 'sales.numberOfContainers' | translate }}</td>
                        <td class="iscBackground" style="text-align: right">{{ _totalRecord.containers }}</td>
                        <td>{{ 'sales.purchasePrice' | translate }}</td>
                        <td class="iscTotalBackground price">{{ _totalRecord.price | currency:'EUR':'symbol':'1.2-2':'nl'}}</td>
                    </tr>
                </table>
            </div>
            <div class="ui-g-5">
                <table>
                    <tr>
                        <th style="width: 20%"></th>
                        <th style="width: 50%">{{ 'sales.shippingInformation' | translate }}</th>
                        <th style="width: 15%"></th>
                        <th style="width: 15%">{{ 'finance.currencyType' | translate }}</th>
                    </tr>
                    <tr>
                        <td>{{ 'logistics.departure' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.freight.departure }}</td>
                        <td>{{ 'base.type' | translate }}</td>
                        <td style="background: lightsteelblue">{{ selectedOrder.currency.type }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'logistics.destination' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.freight.destination }}</td>
                        <td>{{ 'finance.rate' | translate }}</td>
                        <td style="background: lightsteelblue">{{ selectedOrder.rate.rate | number:'1.3-3':'nl' }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'logistics.containerSize' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.containerSize.name }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{{ 'logistics.sea' | translate }}</td>
                        <td class="iscBackground">{{ selectedOrder.freightCost.sea }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{{ 'base.validFrom' | translate }}</td>
                        <td class="iscBackground">
                            <djc-date [timeInput]="selectedOrder.freightCost.validFrom" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th colspan="2">{{ 'base.comment' | translate }}</th>
                        <th colspan="2">{{ 'sales.instructions' | translate }}</th>
                    </tr>
                    <tr>
                        <td colspan="2" style="background-color: #dde3e6">{{ selectedOrder.comment }}</td>
                        <td colspan="2">
                            <table>
                                <tr>
                                    <td class="iscBackground" style="width: 80%">{{ selectedOrder.bookDeparture ? ('sales.bookDeparture' | translate) : '' }}</td>
                                </tr>
                                <tr>
                                    <td class="iscBackground">{{ selectedOrder.purchase ? ('sales.purchase' | translate) : '' }}</td>
                                </tr>
                                <tr>
                                    <td class="iscBackground">{{ selectedOrder.reserveProductionCapacity ? ('sales.reserveProductionCapacity' | translate) : '' }}</td>
                                </tr>
                                <tr>
                                    <td class="iscBackground">{{ selectedOrder.sendConfirmation ? ('sales.sendConfirmation' | translate) : '' }}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <p-table #ics [value]="_orderLines" [paginator]="false">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 6%">{{ 'sales.quantity' | translate }}</th>
                    <th style="width: 6%">{{ 'products.code' | translate }}</th>
                    <th style="width: 16%">{{ 'base.strDescription' | translate }}</th>
                    <th style="width: 6%">{{ 'base.purchasePrice' | translate }}</th>
                    <th style="width: 6%">{{ 'base.validUntil' | translate }}</th>
                    <th style="width: 6%">{{ 'sales.costPrice' | translate }}</th>
                    <th style="width: 6%">{{ 'sales.additionalCost' | translate }}</th>
                    <th style="width: 6%">in €</th>
                    <th style="width: 6%">{{ 'sales.salesPrice' | translate }}</th>
                    <th style="width: 6%">{{ 'sales.margin' | translate }} %</th>
                    <th style="width: 6%">{{ 'sales.margin' | translate }}</th>
                    <th style="width: 6%">Σ {{ 'sales.costPrice' | translate }}</th>
                    <th style="width: 6%">Σ {{ 'sales.salesPrice' | translate }}</th>
                    <th style="width: 6%">Σ {{ 'sales.margin' | translate }}</th>
                    <th style="width: 6%">#</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-orderLine>
                <tr>
                    <td style="background-color: cornflowerblue">{{ orderLine.quantity | number:'1.3-3':'nl' }}</td>
                    <td style="background-color: cornflowerblue">{{ orderLine.product.code }}</td>
                    <td style="background-color: cornflowerblue">{{ orderLine.product.description }}</td>
                    <td class="price">{{ orderLine.price.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
                    <td style="text-align: right">
                        <djc-date [timeInput]="orderLine.price.validUntil" [format]="_auth.getUserSettings().dateFormat"></djc-date>
                    </td>
                    <td class="price">{{ _salesService.getCostPrice(orderLine, _totalRecord) | currency:selectedOrder.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                    <td colspan="2"></td>
                    <td style="background-color: cornflowerblue" class="price">{{ orderLine.salesPrice | currency:selectedOrder.rate.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                    <td style="text-align: right">{{ _salesService.getMarginPercentage(orderLine, _totalRecord) * 100 | number:'1.2-2':'nl' }} %</td>
                    <td class="price">{{ _salesService.getMargin(orderLine, _totalRecord) | currency:selectedOrder.rate.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                    <td class="price">{{ (orderLine.quantity * _salesService.getCostPrice(orderLine, _totalRecord)) | currency:selectedOrder.rate.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                    <td class="price">{{ (orderLine.quantity * orderLine.salesPrice) | currency:selectedOrder.rate.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                    <td class="price">{{ (orderLine.quantity * _salesService.getMargin(orderLine, _totalRecord)) | currency:selectedOrder.rate.currency.notation:'symbol':'1.2-2':'nl' }}</td>
                    <td>{{ orderLine.lineNumber }}</td>
                </tr>
                <tr>
                    <td colspan="2">{{ 'base.strDescription' | translate }}</td>
                    <td colspan="2" [ngClass]="'iscBackground'">{{ orderLine.packaging.description }}</td>
                    <td colspan="2" [ngClass]="getNewProductClass((orderLine.newProduct || orderLine.productChange))"><b>{{ orderLine.newProduct ? _transService.instant('sales.newProduct') : orderLine.productChange ? _transService.instant('sales.productChange') : '' }}</b></td>
                    <td>{{ 'products.package' | translate }} {{ 'base.price' | translate }}</td>
                    <td [ngClass]="'iscBackground price'">{{ orderLine.packaging.price | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
                    <td colspan="2">{{ 'addressbook.S' | translate }}</td>
                    <td colspan="2" [ngClass]="'iscBackground'">{{ orderLine.supplier ? orderLine.supplier.name : 'Geen leverancier' }}</td>
                    <td>{{ 'base.comment' | translate }}</td>
                    <td [ngClass]="getBoolClass(orderLine.product.IMO)">IMO</td>
                    <td [ngClass]="getEHFClass(orderLine.product.EHF)">{{ 'products.EHF' | translate }}</td>
                </tr>
                <tr>
                    <td colspan="2">{{ 'products.packageRemark' | translate }}</td>
                    <td colspan="2" [ngClass]="'iscBackground'">{{ orderLine.packageChange ? orderLine.packageChangeDescription : '' }}</td>
                    <td colspan="2" [ngClass]="getNewProductClass(orderLine.productChange)">{{ orderLine.productChange ? orderLine.productChangeDescription : '' }}</td>
                    <td>{{ 'sales.additionalCost' | translate }}</td>
                    <td [ngClass]="'iscBackground price'">{{ orderLine.additionalCost | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
                    <td colspan="2">{{ 'products.supplierCode' | translate }}</td>
                    <td colspan="2" [ngClass]="'iscBackground'">{{ orderLine.price.supplierCode }}</td>
                    <td colspan="3" rowspan="2" [ngClass]="'iscBackground'">{{ orderLine.price.comment }}</td>
                </tr>
                <tr>
                    <td colspan="2">{{ 'products.labelRemark' | translate }}</td>
                    <td colspan="2" [ngClass]="'iscBackground'">{{ orderLine.labelChange ? orderLine.labelChangeDescription : '' }}</td>
                    <td colspan="2" [ngClass]="'iscBackground'">{{ orderLine.registeredProduct ? _transService.instant('sales.registeredProduct') : '' }}</td>
                    <td>{{ 'sales.freightCost' | translate }}</td>
                    <td [ngClass]="'iscBackground price'">{{ _salesService.getFreightCost(_totalRecord) | currency:'EUR':'symbol':'1.2-2':'nl' }}</td>
                    <td colspan="2">{{ 'products.minimumQuantity' | translate }}</td>
                    <td [ngClass]="'iscBackground'">{{ orderLine.price.minimumQuantity }}</td>
                    <td>Mtons</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-footer>
        <button type="button" pButton class="pButton" (click)="exportPDF()" label="Exporteer" *ngIf="true"></button>
        <button type="button" pButton class="pButton" (click)="sendPDF('cs@champrix.nl')" label="Mail Champrix Customer Support" *ngIf="true"></button>
        <button type="button" pButton class="pButton" (click)="sendPDF('cs@nusana.nl')" label="Mail NuSana Customer Support" *ngIf="true"></button>
    </p-footer>
</p-dialog>
