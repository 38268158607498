import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {SupportTools} from "../support.tools";
import {AuthService} from "../auth.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../api.service";
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {Order, OrderHistory, OrderProduct} from "./sales.models";
import {Company} from "../addressbook/addressbook.model";
import {MenuItem} from "primeng/api";
import {SalesService} from "./sales.service";
// @ts-ignore
import pdfMake from "pdfmake/build/pdfmake";
// @ts-ignore
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'rxjs/add/observable/interval';

@Component({
    selector: 'sales',
    templateUrl: './sales.component.html',
    styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {

    @Input() orderType: string;

    @Input() selectedCompany: Company;

    @Input() createRights: boolean = false;

    @Input() displayCustomer: boolean = false;

    @Input() set reload(value: boolean) {
        this._reload = value;
        if (this._reload) {
            this.loadOrders();
        }
    }

    @Output() reloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get reload(): boolean {
        return this._reload;
    }

    public _orders: Order[] = [];
    public _orderHistory: OrderHistory[] = [];

    public _loading: boolean = false;
    private _reload: boolean = false;

    public selectedOrder: Order;
    public selectedOrderLine: OrderProduct;
    public _contextMenu: MenuItem[] = [];
    public _totalRecord: any = {additionalCost: 0, costPrice: 0, quantity: 0, salesPrice: 0, maxLineNumber: 0};
    public _rows: number;
    public _showDialog: boolean = false;
    public _showDetails: boolean = false;
    public _newOrder: boolean = false;
    public _showHistory: boolean = false;
    public _showCopy: boolean = false;

    constructor(private _apiService: ApiService, public _auth: AuthService, public _st: SupportTools, private _transService: TranslateService,
                private _fb: FormBuilder, private _dp: DatePipe, public _salesService: SalesService, private _cp: CurrencyPipe, private _decPipe: DecimalPipe) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    ngOnInit() {
        // Build contextmenu
        this._contextMenu.push({label: this._transService.instant('base.details'), icon: 'fa fa-info-circle', command: () => this.detailsOrder()});
        if (this._auth.modules['sales'].authLevel >= 30) {
            switch (this.orderType) {
                case 'N':
                    this._contextMenu.push({label: this._transService.instant('base.edit'), icon: 'fa fa-pencil-square-o', command: () => this.editOrder()});
                    this._contextMenu.push({label: this._transService.instant('sales.upQuotation'), icon: 'fa fa-step-forward', command: () => this.changeType('Q')});
                    this._contextMenu.push({label: this._transService.instant('sales.upPlanned'), icon: 'fa fa-step-forward', command: () => this.changeType('P')});
                    this._contextMenu.push({label: this._transService.instant('sales.upOrder'), icon: 'fa fa-step-forward', command: () => this.changeType('O')});
                    break;
                case 'Q':
                    this._contextMenu.push({label: this._transService.instant('sales.upPlanned'), icon: 'fa fa-step-forward', command: () => this.changeType('P')});
                case 'P':
                    this._contextMenu.push({label: this._transService.instant('sales.upOrder'), icon: 'fa fa-step-forward', command: () => this.changeType('O')});
                case 'O':
                case 'L':
                    this._contextMenu.push({label: this._transService.instant('sales.copy'), icon: 'fa fa-clone', command: () => this.copyOrder()});
                    if (this._auth.modules['sales'].authLevel >= 50) {
                        this._contextMenu.push({label: this._transService.instant('sales.reOpen'), icon: 'fa fa-envelope-open-o', command: () => this.reOpenOrder()});
                    }
                    break;
            }
            this._contextMenu.push({label: this._transService.instant('sales.lost'), icon: 'fa fa-step-backward', command: () => this.lostOrder()});
        }
        this._contextMenu.push({label: this._transService.instant('sales.history'), icon: 'fa fa-history', command: () => this.showHistory()});
        if (this._auth.modules['sales'].authLevel >= 40) {
            this._contextMenu.push({label: this._transService.instant('base.delete'), icon: 'fa fa-minus-square-o', command: () => this.deleteOrder()});
        }

        if (this.displayCustomer) {
            this._rows = this._auth.defaultRows;
        } else {
            this._rows = 20;
        }

        this.loadOrders();

        // let higMod = Observable.interval(10000);
        // higMod.subscribe(() => {
        //     Object.keys(this._highMods).forEach(function(mod) {
        //         console.info(this._highMods[mod]);
        //         this._apiService.setActionUrl(this._highMods[mod].url);
        //         this._apiService.getHighestMod().subscribe(response => {
        //             console.info(response);
        //             if (this._highMods[mod].modSeq < response.data.highModSeq) {
        //                 console.info('Call Function');
        //                 let me = this._highMods[mod].functionScope;
        //                 me[this._highMods[mod].function]();
        //             }
        //         });
        //     }, this);
        // });
        // higMod.subscribe((check) => function() {
        //     console.info('Called');
        //     Object.keys(this._highMods).forEach(function(mod) {
        //         console.info(mod);
        //     }, this);
        // });
    }

    loadOrders() {
        this._loading = true;
        this._apiService.setActionUrl('sales/orders');
        this._apiService.setFilter({field: 'status', operator: 'eq', value: this.orderType});
        this._apiService.setFilter({field: 'companyId', operator: 'eq', value: this.selectedCompany.id});
        //this._apiService.setFilter({field: 'parentOrderId', operator: 'eq', value: ''});
        this._apiService.getStore().subscribe(
            response => {
                this._loading = false;
                this._reload = false;
                this.reloadChange.emit(this._reload);
                if (response.success) {
                    this._orders = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    detailsOrder() {
        this._showDetails = true;
        this._newOrder = false;
    }

    editOrder() {
        this._showDialog = true;
        this._newOrder = false;
    }

    changeType(newType: string) {
        this.selectedOrder = this._salesService.convertOrderDates(this.selectedOrder);
        this.selectedOrder.status = newType;
        this.saveOrder();
    }

    saveOrder() {
        this._apiService.setActionUrl('sales/orders');
        this.selectedOrder.offerDate = this.selectedOrder.offerDateDate.getTime() / 1000;
        this.selectedOrder.closeDate = this.selectedOrder.closeDateDate.getTime() / 1000;
        this.selectedOrder.validTo = this.selectedOrder.validToDate.getTime() / 1000;
        this._apiService.updateRecord(JSON.stringify(this.selectedOrder)).subscribe(
            response => {
                this.selectedOrder = response.data.records[0];
                this._salesService.setOrder(this.selectedOrder);
                this.loadOrders();
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    cancel() {
        this._showDialog = false;
        this._showDetails = false;
        this._newOrder = false;
        this.selectedOrder = null;
    }

    createOrder() {
        this.selectedOrder = new Order();
        this.selectedOrder.offerDate = Math.floor(new Date().getTime() / 1000);
        this._newOrder = true;
        this._showDialog = true;
    }

    copyOrder() {
        this._apiService.setActionUrl('sales/orders');
        this._apiService.specialPostAction(JSON.stringify({copyId: this.selectedOrder.id}), 'copyOrder').subscribe(
            response => {
                if (response.success) {
                    this.loadOrders();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    reOpenOrder() {
        this.selectedOrder = this._salesService.convertOrderDates(this.selectedOrder);
        this.selectedOrder.status = 'N';
        this.saveOrder();
    }

    lostOrder() {
        this.selectedOrder = this._salesService.convertOrderDates(this.selectedOrder);
        this.selectedOrder.status = 'L';
        this.saveOrder();
    }

    deleteOrder() {
        this._apiService.setActionUrl('sales/orders');
        this._apiService.deleteRecord(this.selectedOrder.id).subscribe(
            response => {
                if (response.success) {
                    this.loadOrders();
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
    }

    showHistory() {
        this._apiService.setActionUrl('sales/orderhistory');
        this._apiService.setFilter({field: 'orderId', operator: 'eq', value: this.selectedOrder.id});
        this._apiService.setSort({field: 'createdAt', direction: 'ASC'});
        this._apiService.getStore().subscribe(
            response => {
                if (response.success) {
                    this._orderHistory = response.data.records;
                } else {
                    this._auth.addError(response.errorMsg);
                }
            },
            error => {
                this._auth.addError(error.error.text);
            }
        );
        this._showHistory = true;
    }

    cancelHistory() {
        this._showHistory = false;
    }

    onRowDblclickOrder($event, order) {
        this.selectedOrder = order;
        this.detailsOrder();
    }
}
