import {environment} from "../environments/environment";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {RouterModule} from "@angular/router";
import {APP_ROUTER_PROVIDERS, ROUTES} from "../routes";
import {AuthService} from "./auth.service";
import {SharedModule} from "./shared.module";
import {CustomTransLoader} from "./terms.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient} from "@angular/common/http";
import {BaseComponent} from "./base/base.component";
import {DatePipe, registerLocaleData} from "@angular/common";
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";
import {AddressbookModule} from "./addressbook/addressbook.module";
import {LoginModule} from "./login/login.module";
import {UsersModule} from "./users/users.module";
import {ModulesModule} from "./modules/modules.module";
import {GroupsModule} from "./groups/groups.module";
import {ProductsModule} from "./products/products.module";
import {LogisticsModule} from "./logistics/logistics.module";
import {FinanceModule} from "./finance/finance.module";
import {SalesModule} from "./sales/sales.module";
import localeNL from '@angular/common/locales/nl';
import {MsalModule} from "@azure/msal-angular";
import {SearchComponent} from "./base/search.component";

registerLocaleData(localeNL);

@NgModule({
    declarations: [
        AppComponent,
        BaseComponent,
        SearchComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        APP_ROUTER_PROVIDERS,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new CustomTransLoader(http),
                deps: [HttpClient]
            }
        }),
        NgIdleKeepaliveModule.forRoot(),
        MsalModule.forRoot({
            auth: {
                clientId: environment.clientId, // This is your client ID
                authority: 'https://login.microsoftonline.com/' + environment.tenantId, // This is your tenant ID
                redirectUri: environment.redirectUrl // This is your redirect URI
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            },
        }, {
            popUp: !isIE,
            consentScopes: [
                'user.read',
                'openid',
                'profile',
            ],
            unprotectedResources: [],
            protectedResourceMap: [
                ['https://graph.microsoft.com/v1.0/me', ['user.read']]
            ],
            extraQueryParameters: {}
        }),
        AddressbookModule,
        LoginModule,
        UsersModule,
        ModulesModule,
        GroupsModule,
        ProductsModule,
        LogisticsModule,
        FinanceModule,
        SalesModule,
        SharedModule
    ],
    providers: [
        AuthService,
        DatePipe
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

}
